<template>

  <NetworkStatus v-if="isOffline" />
  <div v-else>

    <h5 class="px-4 mb-0" style="transform: translateY(-50px);position: absolute"
      v-if="classroom && classroom.grade_code && classroom.speciality_code">
      {{ classroom.grade_code }} {{ classroom.number }} /
      {{ classroom.speciality_code }}
    </h5>
    <h5 class="px-4 mb-0" style="transform: translateY(-60px);position: absolute" v-else>
      {{ classroom.grade_code }} - {{ classroom.number }}

    </h5>

    <!--========================= modal apply config =================================== -->

    <div class="modal fade" id="staticBackdrop12" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="card">
          <div class="modal-content">
            <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
              <h5 class="modal-title" id="staticBackdropLabel12"></h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                @click="close"></button>
            </div>
            <div class="modal-body text-center pt-4 pb-4">
              <div class="content" v-if="installments.length == 0">
                <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
                <h6>
                  {{ $t("classdetails.modal_noinstallment_title") }}
                </h6>
                <p class="px-7">
                  {{ $t("classdetails.modal_noinstallment_text") }}
                </p>
              </div>

              <div class="content" v-if="installments.length > 0 && show_modal_config">
                <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
                <h6>
                  {{ $t("classdetails.modal_apply_groupe_title") }}
                </h6>
                <div class="d-flex" style="width: max-content; margin: auto auto">
                  <h6 class="">{{ $t("classdetails.group") }} : {{ grade }}</h6>
                  <p class="text-center mx-4">


                    ( {{ currency_code }} {{ Number(montantTotal).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' :
                      'en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }})
                  </p>
                </div>
                <div class="liste">
                  <ul class="list-group" style="
                    width: max-content;
                    margin-left: auto;
                    margin-right: auto;
                  ">
                    <li class="row" v-for="installment in installments" :key="installment.id">
                      <h6 class="px-0">
                        {{ $t("classdetails.installment") }}
                        {{ installment.number }} :
                        <span class="mx-2 opacity-0.4" style="opacity: 0.4">{{ currency_code }}
                          {{ Number(installment.amount).toLocaleString() }}
                        </span>
                      </h6>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="content" v-if="show_modal_success">
                <i style="color: #f1c40f; font-size: 70px" class="bi bi-check2-circle"></i>
                <h6 class="text-xl ps-4 mb-5 mt-3 font-weight-bolder">
                  {{ $t("classdetails.modal_success_groupe_title") }}
                </h6>
                <p>
                  {{ $t("classdetails.modal_success_text") }}
                </p>
              </div>
            </div>
            <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
              <argon-button v-if="!show_modal_success" type="button" class="btn bg-transparent border border-info"
                data-bs-dismiss="modal" style="width: 150px; height: 50px; padding-top: 15px; ">
                {{ $t("button.cancel") }}
              </argon-button>

              <argon-button v-if="show_modal_success" type="button" class="btn bg-success" data-bs-dismiss="modal"
                style="width: 150px; height: 50px; padding-top: 11px; font-weight: bold; font-size:18px;"
                @click="close">
                OK
              </argon-button>

              <argon-button v-if="installments.length > 0 && !show_modal_success" :loading="loading"
                :active="buttonActive || showConfirmation" color="danger" variant="gradient" type="button" class="mt-1"
                style="width: 150px; height: 50px; padding-top: 15px" @click="selectConfig(this.selectedConfig.id)">
                {{ $t("button.confirm") }}
              </argon-button>

              <argon-button @click="redirectToPaymentConfig" v-if="installments.length == 0" :loading="loading"
                :active="buttonActive || showConfirmation" color="success" type="button" class="mt-1"
                style="width: 200px; height: 50px; padding-top: 15px">
                <i class="fas fa-plus me-2"></i>
                {{ $t("button.add_installment") }}
              </argon-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--====================== main ================== -->


    <div class="container-fluid  pt-0 mt-n4" :key="refreshKey">
      <div class="row ">
        <div class="col-lg-12 ">
          <div class="row " style="height: 100%">
            <div class="col-lg-8 ">
              <div class="row ">
                <!--start-->


                <div class="row information   mt-4" style="padding-right: 0; padding-left: 20px">
                  <!-- Overlay Content -->

                  <div class="overlay" v-if="configInstallments.length === 0">
                    <div class="alerte mt-9">
                      <!-- Icône d'avertissement -->


                      <!-- Information de configuration -->
                      <div class="dic" v-if="loading">
                        <Spinner></Spinner>
                      </div>
                      <div v-else>
                        <i class="bi bi-exclamation-triangle-fill text-warning"
                          style="font-size: 50px; top: -10px; cursor: pointer"></i>
                        <div class="select-group" v-if="config.length !== 0">
                          <p class="w-80" style="margin: 15px auto; transform: translateY(-15px)">
                            {{ $t("classdetails.select_groupe_text") }}
                          </p>


                          <div style="width: max-content; margin: 0 auto;" class="dropdown p-0 mt-4 "
                            v-if="config.length !== 0">
                            <button class="btn bg-gradient-success dropdown-toggle mt-4"
                              style="height: 50px; width: 250px; margin:0 auto; transform:translateY(-50px);"
                              type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                              {{ $t("button.select_group") }}
                            </button>

                            <!-- Options du menu déroulant -->
                            <ul class="dropdown-menu " id="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                              <li v-for="config in config" :key="config.id">
                                <a class="dropdown-item ps-4 text-start select-config"
                                  style="border-bottom: 1px solid #344767; " data-bs-toggle="modal"
                                  data-bs-target="#staticBackdrop12" @click="openModal(config)">
                                  <h6>
                                    <span class="fw-bold" style="font-size: 16px">
                                      {{ config.grade_code }} ({{ currency_code }}
                                      {{ Number(config.total_amount).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR'
                                        : 'en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}
                                    </span>
                                  </h6>

                                  <span v-if="config.speciality_code === !'Pas de specialité'">({{
                                    config.speciality_code
                                  }})</span>

                                  <!-- Liste des tranches -->


                                  <ul v-if="config.installment_list && config.installment_list.length > 0"
                                    class="list-inline ps-4">
                                    <li v-for="installment in config.installment_list" :key="installment.id"
                                      class="list-inline-item">
                                      <!-- Détails de la tranche -->
                                      <div class="row text-dark" style="padding-right: 30px">
                                        <div class="row">
                                          <div class="col-md-3">
                                            <p style="opacity: 0.8;font-size:15px;">
                                              {{ $t("classdetails.installment") }} {{ installment.number }}
                                            </p>
                                            <p style="transform: translateY(-12px);font-size:15px;">
                                              {{ currency_code }}

                                              {{ Number(installment.amount).toLocaleString(this.$i18n.locale === 'fr' ?
                                                'fr-FR' : 'en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                              }}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                  <ul v-else>
                                    <p>
                                      <span style="margin-left: -40px">
                                        <i class="text-warning bi bi-exclamation-triangle-fill"></i>
                                        {{ $t("classdetails.no_installment") }}
                                      </span>
                                    </p>
                                  </ul>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <!-- Message d'absence de configuration -->

                        </div>
                        <div class="no-select " v-else>

                          <h6>{{ $t("classdetails.no_group_title") }}</h6>
                          <p>{{ $t("classdetails.no_group_text") }}</p>
                          <div class="button ">
                            <button class="btn btn-success "
                              style="height: 50px; width: 200px; left: 0; margin:0 auto;">
                              <router-link to="/payment-config">
                                <i class="fa fa-plus"></i>
                                Nouveau groupe
                              </router-link>
                            </button>
                          </div>

                        </div>


                      </div>
                    </div>

                    <!-- Menu déroulant de configuration -->
                    <div class="row" id="dropdown">
                      <!-- Bouton pour ajouter une nouvelle configuration -->
                      <!-- Champ caché pour l'ID de la classe -->
                      <div class="col-md-4" style="display: none">
                        <div class="mb-3">
                          <label>
                            classroom_id
                            <em style="color: #f60000">*</em>
                          </label>
                          <argon-input :placeholder="$t('account.installment_amount')" is-required
                            v-model="classroom_id"></argon-input>
                        </div>
                      </div>

                      <!-- Indicateur de chargement -->
                    </div>
                  </div>

                  <div class="col-xl-6 mb-xl-0 mb-4 p-0">
                    <div class="card bg-transparent shadow-xl p-0" style="height: 100%">
                      <div class="overflow-hidden position-relative border-radius-xl" :style="{
                        backgroundImage:
                          'url(' +
                          require('../../assets/img/card-visa.jpg') +
                          ')',
                      }">
                        <span class="mask bg-gradient-dark"></span>
                        <router-link :to="{
                          name: 'settled-students',
                          params: { classId: classroom_id },
                        }">
                          <div class="card-body position-relative z-index-1 px-0" style="height: 242px"
                            title="Cliquez pour voir la liste des élèves">
                            <h2 class="text-white mt-4 mb-5"></h2>
                            <div class="d-flex">
                              <div class="d-flex">
                                <div :class="this.$store.state.isRTL ? 'ms-4' : 'me-4'
                                  ">
                                  <div class="d-flex p-0" style="
                                    margin-left: 20px;
                                    transform: translateY(-40px);
                                  ">
                                    <h6 class="text-white mb-0" style="transform: translateX(-10px)"
                                      v-if="classroom && classroom.grade_code">
                                      {{ classroom.grade_code }}
                                      {{ classroom.number }}
                                    </h6>
                                    <p class="text-white text-sm opacity-8 pt-1" v-if="
                                      classroom && classroom.speciality_code
                                    ">
                                      / {{ classroom.speciality_code }}
                                    </p>
                                  </div>
                                  <div class="settled" style="margin-top: -40px">
                                    <h6 class="text-xl px-2 fw-bold text-white">
                                      {{ $t("classdetails.payment_no_approved") }}
                                    </h6>

                                    <div class="d-flex mt-4" v-if="classroomReport">
                                      <h6 class="nbrset bg-y-gradient text-center fs-3">
                                        {{
                                          classroomReport.no_receipt_confirmation
                                            .count
                                        }}
                                      </h6>

                                      <h5 style="
                                        margin-left: 40px;
                                        margin-top: 16px;
                                        font-weight: bold;
                                      ">
                                        {{ currency_code }}

                                        {{
                                          Number(classroomReport.no_receipt_confirmation.amount).toLocaleString(this.$i18n.locale
                                            === 'fr' ? 'fr-FR' : 'en-US', {
                                              minimumFractionDigits: 0, maximumFractionDigits:
                                                0
                                          }) }}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <p class="text-white text-sm opacity-8 mb-0">
                                    {{ cardExpirationText }}
                                  </p>
                                </div>
                              </div>
                              <div class="w-20 d-flex align-items-end justify-content-end" :class="this.$store.state.isRTL ? 'me-auto' : 'ms-auto'
                                "></div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 py-0 ">
                    <div class="row ">
                      <router-link class="col-md-6" style="padding-right: 3px" :to="{
                        name: 'pending-students',
                        params: { classId: classroom_id },

                      }">
                        <div style="padding-right: 0; height: 100%" :title="$t('classdetails.Pending.title')">
                          <default-info-card style="height: 242px" :classIcon="PendingPayment.classIcon"
                            :desc="PendingPayment.desc" :price="PendingPayment.price"
                            :title="$t('classdetails.PendingPayment.title')" />
                        </div>
                      </router-link>
                      <div class="col-md-6 " :title="$t('classdetails.overdue.title')"
                        style="padding-right: 0; transform:translateX(-10px)">
                        <default-info-card style="height: 242px" :card_bg="'bg-gradient-danger'"
                          :classIcon="PaymentOverdue.classIcon" :desc="PaymentOverdue.desc"
                          :price="PaymentOverdue.price" :title="$t('classdetails.PaymentOverdue.title')" />
                      </div>

                    </div>
                  </div>

                  <div class="col-md-12 px-2 mt-3  ">
                    <div class="row">
                      <div class="col-md-6" style="padding-left: 7px; padding-right: 0px">
                        <div class="card" style="height: 283px">
                          <div class="card-header py-0">
                            <div class="row">
                              <div class="col-md-6 pt-3 px-0">
                                <h6>{{ $t("classdetails.school") }}</h6>
                              </div>
                              <div class="col-md-6 text-end px-0 pt-2">

                              </div>
                            </div>
                          </div>

                          <div class="card-body px-0" style="margin-top: -30px">
                            <div class="content text-center">
                              <spinner class="text-center" v-if="loading" style="height: 100px">
                                <div>
                                  <span class="mt-4">
                                    <Spinner></Spinner>
                                  </span>
                                </div>
                              </spinner>
                              <div v-else-if="installmentsData">
                                <ul v-for="config in paginatedinstallment" :key="config.classroom_id"
                                  class="list-group list-installment list-group-horizontal col-12 px-0"
                                  style="color: rgba(255, 255, 255, 0.6)">
                                  <li class="col-4 text-start px-2" style="
                                    font-size: 0.8rem;
                                    padding-top: 9px;
                                    padding-bottom: 9px;
                                  ">
                                    {{ $t("classdetails.installment") }}
                                    {{ config.installment[0].number }}
                                  </li>

                                  <li class="col-4 text-start px-2" style="
                                    font-size: 0.8rem;
                                    padding-top: 9px;
                                    padding-bottom: 9px;
                                  ">

                                    {{ config.installment[0].due_date }}
                                  </li>
                                  <li class="col-4 text-end px-3" style="
                                    font-size: 0.8rem;
                                    padding-top: 9px;
                                    padding-bottom: 9px;
                                  ">
                                    {{ currency_code }}

                                    {{ Number(config.installment[0].amount).toLocaleString(this.$i18n.locale === 'fr' ?
                                      'fr-FR' : 'en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}

                                  </li>
                                </ul>
                                <!---
                              <ul
                                class="list-group list-installment list-group-horizontal col-12 px-0"
                              >
                                <li
                                  class="col-6 text-start px-2 "
                                  style="border-none"
                                >
                                  Total
                                </li>
                                <li
                                v-if="configInstallments"
                                  class="col-6 text-end px-3"
                                  style="border-none"
                                >
                                  {{currency_code }}
                                  {{
                                    Number(
                                      getTotalInstallmentsAmount()
                                    ).toLocaleString()
                                  }}
                                </li>
                              </ul>
                              -->
                              </div>

                              <nav aria-label="..." class="nav-page" v-if="currentPage > 1">
                                <ul class="pagination" style="transform:translateY(0px);">
                                  <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                    <button class="page-link" @click="prevPage" style="background: none; color: white"
                                      :disabled="currentPage === 1">
                                      <i class="bi bi-chevron-left"></i>
                                    </button>
                                  </li>
                                  <li v-for="page in totalPage" :key="page" class="page-item"
                                    :class="{ active: currentPage === page }">
                                    <button class="page-link fw-bold" style="background: none"
                                      @click="setCurrentPage(page)">
                                      {{ page }}
                                    </button>
                                  </li>
                                  <li class="page-item" :class="{
                                    disabled: currentPage === totalPages,
                                  }">
                                    <button class="page-link" @click="nextPage" style="background: none; color: white"
                                      :disabled="currentPage === totalPages">
                                      <i class="bi bi-chevron-right"></i>
                                    </button>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="card" style="height: 283px">
                          <div class="card-header pt-2">
                            <div class="row">
                              <div class="col-md-6 px-0 pt-2">
                                <h6>{{ $t("classdetails.other_payment") }}</h6>
                              </div>
                              <div class="col-md-6 px-0 text-end"></div>
                            </div>
                          </div>
                          <div class="card-body p-0 text-center">
                            <div class="content other" v-if="otherPayments">
                              <spinner class="text-center" v-if="loading" style="height: 100px">
                                <div>
                                  <span class="mt-4">
                                    <Spinner></Spinner>
                                  </span>
                                </div>
                              </spinner>
                              <table class="w-100 p-0" style="margin-top: -28px" v-else-if="otherPayments.length > 0">
                                <thead></thead>

                                <tbody class="other-payment">
                                  <tr v-for="(list, index) in paginatedotherPayments" :key="index"
                                    style="color: rgba(255, 255, 255, 0.6)">
                                    <td scope="row" class="text-start px-1" style="
                                      font-size: 0.8rem;
                                      padding-top: 9px;
                                      padding-bottom: 9px;
                                    ">
                                      {{ list.description }}
                                    </td>

                                    <td class="text-center" style="
                                      font-size: 0.8rem;
                                      padding-top: 9px;
                                      padding-bottom: 9px;
                                    ">
                                      <span v-if="list.amount !== 0">Unique </span>
                                      <span v-if="list.amount == 0"> Récurent </span>
                                    </td>

                                    <td class="text-end px-3 position-relative" style="
                                      font-size: 0.8rem;
                                      padding-top: 9px;
                                      padding-bottom: 9px;
                                    ">
                                      <span class="amount1" v-if="list.amount !== 0">
                                        {{ currency_code }}
                                        {{ Number(list.amount).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' :
                                          'en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) }}
                                      </span>
                                      <span class="amount1" v-if="list.amount == 0">
                                        ---------
                                      </span>

                                    </td>
                                  </tr>
                                </tbody>




                              </table>
                              <div v-else class="content">
                                <img style="width: 150px" class="image" src="@/assets/img/paiementlist.png" alt="" />
                                <p>{{ $t("classdetails.no_other_payment") }}</p>
                              </div>


                              <nav class="nav-page " style=" transform:translateY(0px);" v-if="currentPage1 > 1">
                                <ul class="pagination " style="transform:translateY(0px);">
                                  <li class="page-item" :class="{ disabled: currentPage1 === 1 }">
                                    <button class="page-link" @click="prevPage1" style="background: none; color: white"
                                      :disabled="currentPage1 === 1">
                                      <i class="bi bi-chevron-left"></i>
                                    </button>
                                  </li>
                                  <li v-for="page in totalPage" :key="page" class="page-item"
                                    :class="{ active: currentPage1 === page }">
                                    <button class="page-link fw-bold" style="background: none"
                                      @click="setCurrentPage1(page)">
                                      {{ page }}
                                    </button>
                                  </li>
                                  <li class="page-item" :class="{
                                    disabled: currentPage1 === totalPages,
                                  }">
                                    <button class="page-link" @click="nextPage1" style="background: none; color: white"
                                      :disabled="currentPage === totalPage">
                                      <i style="font-weight: 900" class="text-light bi bi-chevron-right"></i>
                                    </button>
                                  </li>
                                </ul>
                              </nav>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--End-->
              </div>
            </div>
            <div class="col-lg-4 ">
              <receipt-card class="mt-4" style="height: 541px" :classroom_id="classroom_id"></receipt-card>
            </div>
          </div>
        </div>
      </div>
      <div class="row  pt-3">


        <div class="col-md-12 pb-10" id="classlist">

          <div id="ho" v-if="configInstallments.length === 0">
            <div class="container text-center pt-7 ">

              <i class="bi bi-exclamation-triangle-fill text-warning"
                style="font-size: 50px; top: -10px; cursor: pointer"></i>

              <h6 class="mt-3"> {{ $t("classdetails.class_alert_title") }} </h6>

              <p class=" text-justify text-center " style="margin: 40px auto; width:max-content">
                {{ $t("classdetails.class_alert_text") }}
              </p>
            </div>
          </div>


          <students-table :Students="studentList" :otherPayments="otherPayments" :configInstallments="installmentsData"
            @child-data="handleDataFromChild" />
        </div>
      </div>
    </div>

  </div>


</template>

<script>
import StudentsTable from "@/views/Student/StudentsTable";
import DefaultInfoCard from "@/widget/Cards/DefaultInfoCard";
import ReceiptCard from "@/views/Classroom/ReceiptCard";
import ArgonButton from "@/components/ArgonButton";
import Spinner from "../components/Spinner.vue";
import NetworkStatus from "@/views/components/NetworkStatus.vue";

import {
  getStudentClassrooms,
  getcreateconfig,
  applyPaymentConfig,
  getInstallmentsForClassroom,
  getclassroomreport,
  getotherconfig

} from "@/utils/services";
import { ref, onMounted } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import api_config from "@/config";


export default {
  name: "class-details",
  data() {
    return {
      configApplied: false,
      showModal1: false,
      numberOfSettledStudents: null,
      isApplyingConfig: false,
      classroomReport: null,
      show_moadl_alerte: true,
      show_modal_select: false,
      show_modal_config: true,
      show_modal_success: false,
      showMenu: false,
      payment_config_id: "",
      isOffline: false,
      apiError: false,
      other: [

      ],
      studentList: [],
      currentPage: 1,
      itemsPerPage: 4,
      currentPage1: 1,
      itemsPerPage1: 4,
      configInstallments: [],
      receip_table: [],
      showOverlay: true,
      
      classroomData: null,
      installmentsData: [],
      currency_code: api_config.CURRENCY_CODE,
      refreshKey: 0,
      grade: "",
      montantTotal: 0,
      installments: [],
      classroom: "",
      selectedConfig: null,
      classroom_id: this.$route.params.classId,
      school_year: new Date().getFullYear(),
      PendingPayment: {
        classIcon: "bi bi-wallet-fill",
        title: "Pending payment",
        desc: "",
        price: "",
      },
      PaymentOverdue: {
        classIcon: "text-white bi bi-wallet-fill",
        title: "Overdue Payment",
        desc: "",
        price: "",
      },
    };
  },

  computed: {
    filtergroupe() {
      return this.config.filter(
        (config) =>
          config.installment_list && config.installment_list.length > 0
      );
    },

    paginatedotherPayments() {
      const startIndex = (this.currentPage1 - 1) * this.itemsPerPage1;
      const endIndex = startIndex + this.itemsPerPage1;
      return this.otherPayments.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.otherPayments.length / this.itemsPerPage1);
    },

    paginatedinstallment() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.installmentsData.slice(startIndex, endIndex);
    },
    totalPage() {
      return Math.ceil(this.installmentsData.length / this.itemsPerPage);
    },
  },
  async beforeMount() {
    try {
      this.loading = true;

      let params = {
        classroom_id: this.classroom_id,

      };

      let res = await getStudentClassrooms(params);
      this.studentList = res.data["students"];
      this.classroom = res.data["classroom"];

    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  },

  components: {
    ReceiptCard,
    DefaultInfoCard,
    StudentsTable,
    ArgonButton,
    Spinner,
    NetworkStatus

  },

  setup() {
    const config = ref([]);
    const loading = ref(false);
    const otherPayments = ref([]); // Ajoutez cette ligne

    const fetchotherpayment = async () => {
      try {
        loading.value = true;
        const result = await getotherconfig();

        if (result.status === 200) {
          otherPayments.value = result.data.map((item) => ({
            ...item,


            showCustomDiv: false,
          }));
        } else {
          console.error(
            "Erreur lors de la récupération des membres :",
            result.error
          );
        }
      } catch (error) {
        console.error(
          "Erreur inattendue lors de la récupération des membres :",
          error
        );
      } finally {
        loading.value = false;
      }
    };

    const fetchconfig = async () => {
      try {
        loading.value = true;
        const result = await getcreateconfig();

        if (result.status === 200) {
          config.value = result.data;
        } else {
          console.error(
            "Erreur lors de la récupération des membres :",
            result.error
          );
        }
      } catch (error) {
        console.error(
          "Erreur inattendue lors de la récupération des membres :",
          error
        );
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchconfig();
      fetchotherpayment();
    });

    return {
      config,
      loading,
      otherPayments
    };
  },


  async mounted() {

    await this.updateOnlineStatus();
    this.fetchClassroomReport(this.classroom_id);
    this.fetchInstallments();
    const modal = document.getElementById("staticBackdrop12");
    modal.addEventListener("shown.bs.modal", () => {
      // Lorsque la modale s'ouvre, appelez this.selectConfig avec l'ID de la configuration
      this.configApplied = false;
    });

    // Ajouter les event listeners pour les changements de réseau
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);



    // Charger les données initiales
    if (!this.isOffline) {

      await this.fetchClassroomReport(this.classroom_id);
    }
  },

  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

  watch: {
  '$i18n.locale': {
    handler() {
      // Si les données sont déjà chargées, reformatez-les
      if (this.classroomReport) {
        this.updateFormattedValues();
      }
    },
    immediate: true
  }
},


  methods: {

    updateFormattedValues() {
  if (!this.classroomReport) return;
  
  // Total paid
  this.receip_table = this.classroomReport.student_receipt;

this.PaymentOverdue.price = this.classroomReport.payment_overdue &&
  this.classroomReport.payment_overdue.amount
  ? Number(this.classroomReport.payment_overdue.amount).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  : "0";

this.PaymentOverdue.desc = this.classroomReport.payment_overdue &&
  this.classroomReport.payment_overdue.count
  ? Number(this.classroomReport.payment_overdue.count).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  : "0";
this.PendingPayment.price = this.classroomReport.pending_payment &&
  this.classroomReport.pending_payment.amount
  ? Number(this.classroomReport.pending_payment.amount).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  : "0";

this.PendingPayment.desc = `${this.classroomReport.pending_payment &&
  this.classroomReport.pending_payment.count
  ? Number(
    this.classroomReport.pending_payment.count
  ).toLocaleString()
  : "0"
  }`;
},

    async updateOnlineStatus() {
      // Vérifier si le navigateur est en ligne
      if (navigator.onLine) {
        try {
          // Vérifier si les API sont accessibles
          await this.checkConnection();
          this.isOffline = false; // Tout fonctionne, on est en ligne
        } catch (error) {
          console.error("Error checking connection:", error);
          this.isOffline = true; // Les API ne répondent pas, on est hors ligne
        }
      } else {
        // Pas de connexion internet détectée
        this.isOffline = true;
      }
    },

    async checkConnection(classroom_id) {
      try {
        // Essayer les deux appels API
        await Promise.all([
          getclassroomreport(classroom_id)

        ]);
        this.apiError = false; // Les API répondent correctement
      } catch (error) {
        console.error("API Error:", error);
        this.apiError = true; // Les API ne répondent pas
      }
    },



    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },

    nextPage1() {
      if (this.currentPage1 < this.totalPage) {
        this.currentPage1++;
      }
    },
    prevPage1() {
      if (this.currentPage1 > 1) {
        this.currentPage1--;
      }
    },
    setCurrentPage1(page) {
      this.currentPage1 = page;
    },

    close() {
      this.show_modal_config = true;
      this.show_modal_success = false;
      setTimeout(() => {
        this.refreshComponentData();
        this.showConfirmation = false;
      },);

    },

    getTotalInstallmentsAmount() {
      let totalAmount = 0;
      for (const config of this.configInstallments) {
        for (const installment of config.installment) {
          totalAmount += parseFloat(installment.amount);
        }
      }

      return totalAmount;
    },

    redirectToPaymentConfig() {
      this.$router.push("/payment-config");

      document.body.classList.remove("modal-open");
      const modalBackdrops = document.getElementsByClassName("modal-backdrop");
      for (const backdrop of modalBackdrops) {
        backdrop.parentNode.removeChild(backdrop);
      }

      // true pour forcer le rechargement depuis le serveur
    },
    modifier() {
      this.show_moadl_alerte = false;
      this.show_modal_select = true;   // Réinitialisez les autres valeurs si nécessaire
    },

    annuler() {
      this.show_moadl_alerte = true;
      this.show_modal_select = false;
    },

    handleDataFromChild(data) {
      this.numberOfSettledStudents = data;
    },

    getSettledStudents() {
      return this.Students.filter(
        (student) => student.payment_status === "Settled"
      );
    },
    hideModal() {
      this.showModal = false;
      setTimeout(() => {
        window.location.reload(true);
        this.configApplied = true;
      });
    },
    async selectConfig(configId) {
      if (!this.configApplied && !this.isApplyingConfig) {
        this.isApplyingConfig = true;
        this.payment_config_id = configId;
        await this.applyPaymentConfig();
        this.configApplied = true;
        this.isApplyingConfig = false;
      }
    },
    async selectConfig1(configId) {
      if (!this.configApplied && !this.isApplyingConfig) {
        this.isApplyingConfig = true;
        this.payment_config_id = configId;
        await this.applyPaymentConfigchange();
        this.configApplied = true;
        this.isApplyingConfig = false;
      }
    },
    openModal(config) {
      this.selectedConfig = config;
      this.grade = config.grade_code;
      this.montantTotal = config.total_amount;
      this.installments = config.installment_list;
      this.configApplied = false;
    },
    applyConfiguration() {
      if (this.selectedConfig) {
        this.selectConfig(this.selectedConfig.id);
      }
    },

    //==================information  classroon =========================

    async fetchClassroomReport(classroom_id) {
      try {
        // Appeler la fonction pour récupérer les informations de la classe
        const response = await getclassroomreport(classroom_id);
        if (response) {
          this.classroomReport = response;
           this.updateFormattedValues()

           
        }
      } catch (error) {
        console.error("Error fetching classroom report:", error);
      }
    },
    //=================== refraiche data  ============================   
    refreshComponentData() {

      this.refreshKey++;


      this.loadInstallments();
      this.fetchInstallments();
      this.showOverlay = false;
    },
    //=================== appliquer  la config ============================
    async applyPaymentConfig() {
      try {
        this.loading = true;
        this.button = "disabled";

        let data = {
          payment_config_id: this.selectedConfig.id,
          classroom_id: this.classroom_id,
        };



        let res = await applyPaymentConfig(data);

        if (!navigator.onLine) {
          const message = this.$t("message.notinternet");
          toast.error(message, {
            autoClose: 3000,
          });
        } else if (res.status === 404) {
          const message = this.$t("message.user404");
          toast.error(message, {
            autoClose: 3000,
          });
        } else if (res.status === 201) {
          this.show_modal_config = false;
          this.show_modal_success = true;

        }
      } catch (error) {
        console.error(error);

        if (error.response && error.response.status === 404) {
          // Afficher un message spécifique pour l'erreur 404
          toast.error("User not found. Please check the provided information", {
            autoClose: 3000,
          });
        } else {
          // Afficher un message d'erreur générique pour d'autres erreurs
          toast.error("An error occurred while creating the member", {
            autoClose: 3000,
          });
        }
      } finally {
        this.loading = false;
        this.button = "";
      }
    },

    //=================== get installment config apply ============================

    async fetchInstallments() {
      try {
        const response = await getInstallmentsForClassroom(this.classroom_id);
        if (response && response.length > 0) {
          this.installmentsData = response;

        } else {
          console.warn("Aucun versement trouvé.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des versements:", error);
      }
    },

    //=================== Changer la config ============================

    async applyPaymentConfigchange() {
      try {
        this.loading = true;
        this.button = "disabled";

        let data = {
          payment_config_id: this.payment_config_id,
          classroom_id: this.classroom_id,
        };



        if (!this.payment_config_id || !this.classroom_id) {
          const message = this.$t("message.requiredfile");
          toast.info(message, {
            autoClose: 3000,
          });

          return;
        }

        if (
          this.classroom_id === "" ||
          this.classroom_id === null ||
          this.payment_config_id === "" ||
          this.payment_config_id === null
        ) {
          this.required = "required";
          this.button = "";
        } else {
          let res = await applyPaymentConfig(data);

          if (!navigator.onLine) {
            const message = this.$t("message.notinternet");
            toast.error(message, {
              autoClose: 3000,
            });
          } else if (res.status === 404) {
            const message = this.$t("message.user404");
            toast.error(message, {
              autoClose: 3000,
            });
          } else if (res.status === 201) {
            const message = "Groupe modifie avec succès";
            toast.success(message, {
              autoClose: 3000,
            });

            // Supprimez chaque installment associée à la configuration

            setTimeout(() => {
              window.location.reload();
              this.showConfirmation = false;
            }, 3000);

            this.showOverlay = false;

            setTimeout(() => {
              this.successMessage = "";
            }, 3000);
          }
        }
      } catch (error) {
        console.error(error);

        if (error.response && error.response.status === 404) {
          // Afficher un message spécifique pour l'erreur 404
          toast.error("User not found. Please check the provided information", {
            autoClose: 3000,
          });
        } else {
          // Afficher un message d'erreur générique pour d'autres erreurs
          toast.error("An error occurred while creating the member", {
            autoClose: 3000,
          });
        }
      } finally {
        this.loading = false;
        this.button = "";
      }
    },
    async loadInstallments() {
      this.loading = true;

      try {
        const configDetails = await getInstallmentsForClassroom(
          this.classroom_id
        ); // Utilisez la fonction pour récupérer les versements

        if (configDetails.length > 0) {
          // Mettez à jour la propriété configInstallments avec les versements récupérés
          this.configInstallments = configDetails || [];
        } else {
          console.warn("Aucun détail de configuration trouvé.");
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des détails de la configuration:",
          error
        );
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.configId = this.$route.classroom_id;
    this.loadInstallments();
  },
};
</script>

<style lang="scss">
.main1 {
  position: relative;
  overflow-x: hidden;

  height: 100%;
}

#classlist {
  position: relative;


}

#ho {
  background: rgba(0, 0, 0, 0.96);
  position: absolute;
  z-index: 2;
  top: 0;
  height: 75%;
  width: 100%;
  left: 0;
  border-radius: 10px;
}

.list-installment {
  list-style: none;

  li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 5px;
    padding-top: 5px;
  }
}

.other table thead {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.other table thead th {
  padding-top: 20px;
  padding-bottom: 2px;
}

.other table thead tr th {
  transform: translateY(-5px);
}

.other table tbody td {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

#cl-student-import {
  .dropdown-item:hover {
    span {
      color: #1a2035;
    }
  }
}

.nbrset {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding-top: 5px;
  margin-left: 7%;
}

#dropdown-menu {
  border-radius: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.4) 0px 54px 55px,
    rgba(0, 0, 0, 0.4) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.4) 0px 12px 13px,
    rgba(0, 0, 0, 0.4) 0px -3px 5px;
  max-height: 500px;
  overflow-y: auto;
}

#dropdown-menu::-webkit-scrollbar {
  width: 8px;

}

#dropdown-menu::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

#dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.information {
  display: none;
  position: relative;

  .overlay {
    position: absolute;
    height: 100%;
    top: 0;
    left: 2.3%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.95);
    border-radius: 10px;
    width: 97.5%;
    text-align: center;

    .select {
      width: 30%;
      height: 50px;
      margin-left: 30%;
      font-size: 17px;
      border: 1px solid #eb4d4b;
      color: white;
      background-color: transparent;

      option {
        color: black;
      }
    }
  }
}

.menu-students {
  li:hover {
    h6 {
      span {
        color: black;
      }
    }
  }
}

.modal1 {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  width: 100%;
  height: 120%;
  z-index: 100;
  left: 0;
  top: -100px;
  display: none;
}

.modal-active1 {
  display: block;
}

.modal1 .modal-content {
  width: 70%;
  margin-left: 13%;
  margin-right: auto;
  text-align: center;
  margin-top: 10%;
  background: none;
}

.modal1 .modal-content .card {
  padding-bottom: 40px;
  margin-top: 10%;
}

.modal1 .modal-content .card .card-body p {
  margin-top: 10%;
  font-size: 20px;
}

#dropdown {
  padding-left: 37%;
}

.dropdown-menu li .select-config {
  transition: 0.7s;
}

.dropdown-menu li .select-config:hover {
  background: rgba(0, 0, 0, 0.5);
}


.nav-page {
  position: absolute;
  left: 0;
  bottom: 0;
}

.pagination {
  transform: scale(0.8);

  display: flex;
}

.pagination button {
  margin: 0 5px;
  cursor: pointer;

  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 20px;
  transform: translateY(15px);
}

.pagination button:hover {
  font-weight: bold;
}

.pagination button:disabled {
  cursor: not-allowed;
}
</style>