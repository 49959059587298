<template>
  <div>
    <!-- Alerte hors ligne -->
    

    <!-- Background landing si c'est la page de landing -->
    <div
      v-show="this.$store.state.layout === 'landing'"
      class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
    ></div>
    
    <!-- Barre de navigation latérale -->
    <sidenav
      :custom_class="this.$store.state.mcolor"
      :class="[
        this.$store.state.isTransparent,
        this.$store.state.isRTL ? 'fixed-end' : 'fixed-start'
      ]"
      v-if="this.$store.state.showSidenav"
    />

    <!-- Contenu principal -->
    <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
      <!-- Navbar -->
      <navbar
        :class="[navClasses]"
        :textWhite="
          this.$store.state.isAbsolute ? 'text-white opacity-8' : 'text-white'
        "
        :minNav="navbarMinimize"
        v-if="this.$store.state.showNavbar"
      />
      
      <!-- Vue du routeur -->
      <router-view />
      
      <!-- Pied de page -->
      <app-footer v-show="this.$store.state.showFooter" />
      
      <!-- Configurateur -->
      <configurator
        :toggle="toggleConfigurator"
        :class="[
          this.$store.state.showConfig ? 'show' : '',
          this.$store.state.hideConfigButton ? 'd-none' : ''
        ]"
      />
    </main>
  </div>
</template>

<script>
import Sidenav from './widget/Sidenav';
import Configurator from '@/widget/Configurator.vue';
import Navbar from '@/widget/Navbars/Navbar.vue';
import AppFooter from '@/widget/Footer.vue';

import { mapMutations } from 'vuex';
import { activateDarkMode, deactivateDarkMode } from '@/assets/js/dark-mode';
import { logoutUser } from "@/utils/auth"; 

export default {
  name: 'App',
  components: {
    Sidenav,
    Configurator,
    Navbar,
    AppFooter,
     
  },
  methods: {
    ...mapMutations(['toggleConfigurator', 'navbarMinimize']),
    startInactivityTimer() {
      let inactivityTime = function () {
        let time;
        window.onload = resetTimer;
        document.onmousemove = resetTimer;
        document.onkeypress = resetTimer;
        document.ontouchstart = resetTimer;

        function resetTimer() {
          clearTimeout(time);
          time = setTimeout(logoutUser, 6 * 60 * 60 * 1000); // Déconnexion après 6 heures d'inactivité
        }
      };
      inactivityTime();
    }
  },
  computed: {
    navClasses() {
      return {
        'position-sticky bg-white left-auto top-2 z-index-sticky': this.$store.state.isNavFixed && !this.$store.state.darkMode,
        'position-sticky bg-default left-auto top-2 z-index-sticky': this.$store.state.isNavFixed && this.$store.state.darkMode,
        'position-absolute px-4 mx-0 w-100 z-index-2': this.$store.state.isAbsolute,
        'px-0 mx-4': !this.$store.state.isAbsolute,
      };
    }
  },
  beforeMount() {
    this.$store.state.isTransparent = 'bg-transparent';
    if (this.$store.state.darkMode) {
      this.$store.state.darkMode = false;
      this.$store.state.sidebarType = 'bg-white';
      deactivateDarkMode();
    } else {
      this.$store.state.darkMode = true;
      this.$store.state.sidebarType = 'bg-default';
      activateDarkMode();
    }
  },
  mounted() {
    this.startInactivityTimer();
  }
}
</script>

<style>
.bg-y-gradient {
  background: linear-gradient(to bottom, #FFCD66, #FBAD41);
}
body {
  overflow-x: hidden;
}
</style>
