<template>
  <NetworkStatus v-if="isOffline" />
  <div v-else class="py-4 container">
    <div class="row mt-3 mb-4 text-center">
      <h5 class="mb-5">
        {{ $t("update_class.class") }} {{ grade_code }} {{ speciality }} {{ number }}
      </h5>
      <div class="row mt-3">
        <div class="col-md-6 col-xs-6 col-lg-7 content text-start">
          <div class="p-3 card w-">
            <div class="card-header pb-0"></div>
            <div class="card-body px-0 pt-0 pb-2">
              <!-- Grade -->
              <div class="mb-4" v-if="GRADE_CODE.length">
                <p style="transform: translateY(13px)" class="small">
                  {{ $t("update_class.grade") }}
                </p>
                <select class="form-control form-select" style="height: 50px" v-model="grade_code">
                  <option v-for="item in GRADE_CODE" :key="item.code" :value="item.code">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <!-- Speciality -->
              <div class="mb-4" v-if="SPECIALITY_CODE.length">
                <p style="transform: translateY(13px)" class="small">
                  {{ $t("update_class.speciality") }}
                </p>
                <select class="form-control form-select" style="height: 50px" v-model="speciality">
                  <option v-for="item in SPECIALITY_CODE" :key="item.code" :value="item.short_name">
                    {{ item.short_name }} - {{ item.title }}
                  </option>
                  <option>{{ $t("new_class.no_speciality") }}</option>
                </select>
              </div>
              <!-- Classroom number -->
              <div class="mb-4">
                <p style="transform: translateY(13px)" class="small">
                  {{ $t("update_class.number") }} <em style="color: #f60000">*</em>
                </p>
                <input type="text" class="form-control" style="height: 50px;" :class="{
                  'required': showError.number,
                  'required-thick': showError.number
                }" :placeholder='$t("new_class.pleceholder")' v-model="number" @input="handleNumberInput"
                  maxlength="2" pattern="[a-zA-Z0-9]*">
                <small class="text-danger" v-if="showError.number">
                  {{ showError.numberMessage || $t("new_student.required_field") }}
                </small>
              </div>
              <!-- Student count -->
              <div class="mb-4">
                <p style="transform: translateY(13px)" class="small">
                  {{ $t("update_class.student_count") }} <em style="color: #f60000">*</em>
                </p>
                <span style="font-size: 12px; opacity: 0.8;">{{ $t("new_class.description") }}</span>
                <argon-input :class="{ 'required': showError.student_count, 'required-thick': showError.student_count }"
                  required type="number" v-model="student_count" @input="validateField('student_count')"></argon-input>
                <small class="text-danger" style="transform: translateY(-45px);" v-if="showError.student_count">
                  {{ $t("new_student.required_field") }}
                </small>
              </div>
              <!-- Save button -->
              <!-- Save button -->
              <div class="mt-5">
                <div class="row">
                  <div class="col-6">
                    <argon-button data-bs-toggle="modal" data-bs-target="#staticBackdrop8" fullWidth color="success"
                      variant="gradient" class="my-4 mb-2" :disabled="!isFormValid || loading" size="lg"
                      @click="showModal" :class="{ 'button-disabled': !isFormValid }">
                      {{ $t("button.save") }}
                    </argon-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--========================= modal update =================================== -->
    <div class="modal fade" id="staticBackdrop8" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="card">
          <div class="modal-content">
            <div class="modal-header" style="border-bottom: 1px solid rgba(250, 250, 250, 0.6);">
              <h5 class="modal-title" id="staticBackdropLabel8"> </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center pt-4 pb-5">
              <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
              <h6 class="mt-3 mb-5">{{ $t('update_class.title_modal') }}</h6>
              <table class="table">
                <thead class="border-0">
                  <tr>
                    <th
                      style="text-transform: none; font-size: 14px; opacity: 0.8; border: 1px solid rgba(250, 250, 250, 0.6);"
                      scope="col">{{ $t('update_class.grade') }}</th>
                    <th
                      style="text-transform: none; font-size: 14px; opacity: 0.8; border: 1px solid rgba(250, 250, 250, 0.6);"
                      scope="col">{{ $t('update_class.speciality') }}</th>
                    <th
                      style="text-transform: none; font-size: 14px; opacity: 0.8; border: 1px solid rgba(250, 250, 250, 0.6);"
                      scope="col">{{ $t('update_class.number') }}</th>
                    <th
                      style="text-transform: none; font-size: 14px; opacity: 0.8; border: 1px solid rgba(250, 250, 250, 0.6);"
                      scope="col">{{ $t('update_class.student_count') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="border: 1px solid rgba(250, 250, 250, 0.6)">{{ grade_code }}</td>
                    <td style="border: 1px solid rgba(250, 250, 250, 0.6)">{{ speciality }}</td>
                    <td style="border: 1px solid rgba(250, 250, 250, 0.6)">{{ number }}</td>
                    <td style="border: 1px solid rgba(250, 250, 250, 0.6)">{{ student_count }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer" style="border-top: 1px solid rgba(250, 250, 250, 0.6)">
              <argon-button type="button" class="btn bg-transparent" data-bs-dismiss="modal"
                style="width: 150px; height: 50px; padding-top: 15px; border: 1px solid rgba(250, 250, 250, 0.4)">
                {{ $t('button.cancel') }}
              </argon-button>

              <argon-button :loading="loading" :active="buttonActive || showConfirmation" color="danger"
                variant="gradient" type="button" class="mt-1" style="width: 150px; height: 50px; padding-top: 15px"
                @click="updateClassroom">
                {{ $t('button.confirm') }}
              </argon-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";
import ArgonInput from "@/components/ArgonInput";
import NetworkStatus from "@/views/components/NetworkStatus.vue";
import {
  getClassroomById,
  updateClassroom,
  getGradeCode,
  getSpeciality,
} from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "UpdateClass",
  components: { ArgonButton, ArgonInput, NetworkStatus },

  data() {
    return {
      GRADE_CODE: [],
      SPECIALITY_CODE: [],
      grade_code: "",
      speciality: "",
      number: "",
      student_count: 0,
      loading: false,
      buttonActive: false,
      showConfirmation: false,
      isOffline: false,
      apiError: false,
      showError: {
        number: false,
        numberMessage: '',
        student_count: false,
        grade_code: false,
        speciality: false
      },
      detailclassroom: {
        id: "",
      },
      intervalId: null
    };
  },

  computed: {
    isFormValid() {
      return (
        this.number.trim() !== '' &&
        !this.showError.number &&
        this.student_count > 0 &&
        !this.showError.student_count &&
        this.grade_code !== '' &&
        this.speciality !== ''
      );
    }
  },

  watch: {
    grade_code() {
      this.validateField('grade_code');
    },
    speciality() {
      this.validateField('speciality');
    },
    number() {
      this.validateField('number');
    },
    student_count() {
      this.validateField('student_count');
    }
  },


  methods: {
    handleNumberInput(event) {
      this.number = event.target.value.toUpperCase();
      this.validateField('number');
    },

    validateField(fieldName) {
      const alphanumericRegex = /^[a-zA-Z0-9]*$/;
      switch (fieldName) {
        case 'number':
          if (!this.number.trim()) {
            this.showError.number = true;
            this.showError.numberMessage = this.$t("new_student.required_field");
          } else if (!alphanumericRegex.test(this.number)) {
            this.showError.number = true;
            this.showError.numberMessage = "Only alphanumeric characters are allowed";
          } else if (this.number.length > 2) {
            this.number = this.number.slice(0, 2);
          } else {
            this.showError.number = false;
            this.showError.numberMessage = '';
          }
          break;
        case 'student_count':
          this.showError.student_count = !this.student_count || this.student_count <= 0;
          break;
        case 'grade_code':
          this.showError.grade_code = !this.grade_code;
          break;
        case 'speciality':
          this.showError.speciality = !this.speciality;
          break;
      }
    },

    // Ajout d'une méthode pour valider tous les champs
    validateAllFields() {
      this.validateField('number');
      this.validateField('student_count');
      this.validateField('grade_code');
      this.validateField('speciality');
    },
  

    async updateOnlineStatus() {
      if (navigator.onLine) {
        try {
          await this.checkConnection();
          this.isOffline = false;
        } catch (error) {
          console.error("Error checking connection:", error);
          this.isOffline = true;
        }
      } else {
        this.isOffline = true;
      }
    },

    async checkConnection() {
      try {
        await Promise.all([getGradeCode()]);
        this.apiError = false;
      } catch (error) {
        console.error("API Error:", error);
        this.apiError = true;
      }
    },

    async checkInternetConnection() {
      await this.updateOnlineStatus();
    },

    async getData() {
      try {
        let grades = await getGradeCode();
        this.GRADE_CODE = grades.data;
        this.grade_code = grades.data[0].code;

        let specialities = await getSpeciality();
        this.SPECIALITY_CODE = specialities.data;

        const classId = this.$route.params.classId;
        const classroom = await getClassroomById(classId);

        if (classroom) {
          this.grade_code = classroom.grade_code;
          this.speciality = classroom.speciality_code;
          this.number = classroom.number;
          this.student_count = classroom.student_count;
        } else {
          console.error("Les données de la classe sont indisponibles.");
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données de la classe :",
          error
        );
      }
    },

    showModal() {
      this.validateField('number');
      this.validateField('student_count');

      if (this.showError.number || this.showError.student_count) {
        return;
      }
    },

    async updateClassroom() {
      try {
        if (this.loading) {
          return;
        }

        if (!navigator.onLine) {
          const message = this.$t("message.notinternet");
          toast.error(message, {
            autoClose: 3000,
          });
          return;
        }

        this.loading = true;
        this.buttonActive = true;

        const classId = this.$route.params.classId;
        const data = {
          grade_code: this.grade_code,
          speciality_code: this.speciality,
          number: this.number,
          student_count: parseInt(this.student_count),
        };

        const response = await updateClassroom(classId, data);

        if (response.status === 200) {
          if (!this.showConfirmation) {
            this.showConfirmation = true;
            const message = this.$t("update_class.message_success");
            toast.success(message, {
              autoClose: 3000,
            });

            document.body.classList.remove("modal-open");
            const modalBackdrops = document.getElementsByClassName("modal-backdrop");
            for (const backdrop of modalBackdrops) {
              backdrop.parentNode.removeChild(backdrop);
            }

            setTimeout(() => {
              this.showConfirmation = false;
              this.$router.push({ path: "/classes", query: { forceReload: true } });
            }, 4000);
          }
        } else {
          const message = this.$t("message.updateFailed");
          toast.error(message, {
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.error("Erreur lors de la mise à jour de la classe :", error);
        toast.error(this.$t("message.updateFailed"), {
          autoClose: 3000,
        });
      } finally {
        this.loading = false;
        this.buttonActive = false;
      }
    },
  },

  async mounted() {
    await this.updateOnlineStatus();
    this.validateAllFields();
    this.getData();
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    this.intervalId = setInterval(this.checkInternetConnection, 1000);
  },

  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
};
</script>

<style scoped>
.content {
  margin-left: auto;
  margin-right: auto;
}

.required {
  border: 1px solid red !important;
}

.required-thick {
  border-width: 2px !important;
}

.input-error {
  border: 1px solid red;
  border-radius: 10px;
}

.form-control.form-select {
  background-color: var(--bs-body-bg);
  border: 1px solid #d2d6da;
  padding: 0.625rem 0.75rem;
  border-radius: 0.5rem;
}

.form-control.form-select:focus {
  border-color: #35d28a;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(53, 210, 138, 0.25);
}

.button-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Style spécifique pour le bouton désactivé */
.button-disabled button {
  background: #cccccc !important;
  border-color: #cccccc !important;
  color: #666666 !important;
}
</style>