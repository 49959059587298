export default {
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre nom d'utilisateur et votre mot de passe pour vous connecter."])},
    "password_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe requis"])},
    "username_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur requis"])},
    "label_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
    "label_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se souvenir de moi"])},
    "placeholder_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre nom d'utilisateur"])},
    "placeholder_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre mot de passe"])},
    "invalid_credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur ou mot de passe incorrect"])},
    "error_occurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de connexion"])}
  },
  "help": {
    "check_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez consulter notre documentation"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide?"])},
    "help_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide? Cliquez ici"])}
  },
  "navbar": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un élève..."])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé"])},
    "add_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez cet élève"])},
    "select_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la classe"])}
  },
  "sidebar": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle d'accès"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
    "classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classes"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurations"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
    "params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "other-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres paiements"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scolarité"])},
    "Pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dSv8rGLEBC5cmw"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messagerie"])},
    "sponsoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsoring"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferts"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérations financières"])},
    "scolarité": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scolarité"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide?"])},
    "profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])}
  },
  "button": {
    "See-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus"])},
    "see_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout voir"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "add_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle tranche"])},
    "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le groupe"])},
    "modify_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le groupe"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer (fichier excel)"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer"])},
    "send_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer la facture"])},
    "add_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel élève"])},
    "excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier Excel"])},
    "add_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Elèves"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploader"])},
    "select_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le fichier"])},
    "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])}
  },
  "dashboard": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue !"])},
    "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents"])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élèves"])},
    "audience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audience Eska"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçus"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "share_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager le lien"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le lien"])},
    "success_copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien copié dans le presse-papiers !"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour partager le lien sur Facebook"])},
    "x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour partager le lien sur X"])},
    "linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour partager le lien sur LinkedIn"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour copier le lien"])},
    "title-card-payemnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçus en attente de vérification"])},
    "title-no-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun reçu en attente"])},
    "text-no-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les reçus envoyés par les parents s'afficheront ici"])},
    "total-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "pending-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
    "parents_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de parents inscrits sur Eska"])},
    "stats": {
      "money": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Total"])},
        "title_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement Total"])}
      },
      "users": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echéance dépassée"])},
        "title_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement à echéance dépassée"])}
      },
      "clients": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" En attente"])},
        "title_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement en attente"])}
      },
      "sales": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Manuellement"])},
        "title_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements confirmés manuellement"])}
      }
    }
  },
  "classroom": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salles de classe"])},
    "classroom_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Liste de classe"])},
    "code_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
    "code_overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Dépassés"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant en attente"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder"])},
    "filter_option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de classe"])},
    "filter_option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
    "filter_option3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echéances dépassées"])},
    "add_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une classe"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe supprimée avec succès"])},
    "delete_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe supprimée avec succès"])},
    "add_class_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez les salles de classes et définissez pour chacune d'elles les frais attendus. Les parents d'élèves recevront des alertes sur l'application Eska et pouront payer."])},
    "button_new_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nouvelle classe"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Accéder à la classe pour configurer les paiements et ajouter les élèves."])},
    "alert_list_classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune classe créer"])},
    "p_list_classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de créer des classes, d'y inscrire des élèves, de générer des paiements pour chaque classe et de permettre aux parents de recevoir des alertes via l'application mobile"])},
    "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer cette classe ?"])},
    "modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La suppression de cette classe entraînera la perte de certaines données associées. Cette opération n’est pas rétroactive, les paiements déjà envoyés ou réglés ne seront pas impactés."])}
  },
  "classdetails": {
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
    "button_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertez les parents ayant souscrit à ce service"])},
    "no_group_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe de paiement créé "])},
    "no_group_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez cliquer sur le bouton ci-dessous pour créer des groupes."])},
    "select_groupe_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez appliquer un groupe a la classe afin de pourvoir appliquer ses tranches de paiement aux élèves de la classe."])},
    "no_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun tranche configurée."])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche"])},
    "modal_apply_groupe_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point d'appliquer ce groupe à cette classe."])},
    "modal_success_groupe_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le groupe a été appliqué avec succès à la classe. "])},
    "modal_success_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant ajouter des élèves à la classe et changer le statut de leurs paiements."])},
    "payment_no_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements approuvés manuellement."])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçus"])},
    "receipt_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les reçus envoyés par les parents apparaîtront ici."])},
    "other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres paiements"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scolarité"])},
    "photo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ici pour voir les détails de cet élève."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "no_other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun autre paiement créé."])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "modal_noinstallment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'appliquer le groupe à la classe"])},
    "modal_noinstallment_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas appliquer ce groupe à une classe car il ne contient pas de tranche de paiement."])},
    "PendingPayment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements en attente"])}
    },
    "PaymentOverdue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements en retard"])}
    },
    "class_alert_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas ajouter d'élèves"])},
    "class_alert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez appliquer un groupe à la classe pour pouvoir ajouter des élèves"])},
    "school_monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi Scolaire"])}
  },
  "studentlist": {
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élèves"])},
    "no_student_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun élève ajouté"])},
    "no_student_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez ajouter des élèves à la classe afin de pouvoir leur appliquer les tranches de paiement du groupe appliquées à la classe."])},
    "table_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les élèves pour modifier le statut de leurs paiements."])},
    "table_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
    "table_matricul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "table_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "schooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scolarité"])},
    "no_statut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun statut"])},
    "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépassés"])},
    "table_current_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
    "settled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglés"])},
    "select_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le statut"])},
    "select_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la tranche"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche"])},
    "title_modal_apply_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir appliquer ces frais à la classe ?"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'échéance"])},
    "title_modal_no_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun statut sélectionné."])},
    "title_modal_status_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de changer le statut de ce paiement ?"])},
    "text_modal_settled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En l'absence de reçu, un message sera envoyé aux différents responsables pour les informer de cette modification."])},
    "title_modal_status_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le statut a été modifié avec succès"])},
    "text_modal_status_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le bouton Envoyer ci-dessous pour informer les parents des élèves concernés qu'un paiement est en attente."])},
    "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
    "other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres paiements"])},
    "parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scolarité"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
    "no_statut_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun statut appliqué"])},
    "pending_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements en attente"])},
    "settled_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Paiements réglés"])},
    "overdue_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements à echéance depassée"])},
    "select_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour modifier le statut de cette tranche"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertes"])},
    "note_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes disponibles"])},
    "sequence_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Dates des séquences"])},
    "course_suspension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspension temporaire des cours"])},
    "convocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convocation  Conseil de Discipline"])},
    "absence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période d'absence"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport de Suivi"])},
    "notif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertes"])},
    "alert_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souscrit aux alertes"])}
  },
  "payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe de classes"])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécialité"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["montant total"])},
    "total_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" tranches"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir les tranches de paiement pour ce groupe"])},
    "button_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe supprimé avec succès"])},
    "no_payment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisez vos classes"])},
    "no_payment_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Chaque classe doit être associée à un groupe de paiement. Veuillez créer les différents groupes.<br/> Pour chacun de ces groupes vous allez ensuite définir les tranches de paiements. "])},
    "modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer ce groupe ?"])},
    "modal_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette opération n'est pas rétroactive, les paiements déjà effectués ou réglés ne seront pas impactés. Vous devrez redéfinir les frais applicables pour les classes auxquelles le groupe a été appliqué."])}
  },
  "newpayment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau groupe "])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un groupe est un ensemble de classes qui ont les mêmes frais de scolarité . <br/> Ex: 6ème, 2ndeA, 4ème. Choisissez un niveau et une specialité, puis sauvegardez. "])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialité"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "modal_button_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer des tranches"])},
    "title_icon_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer des tranches "])},
    "title_icon_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le groupe"])},
    "modal_button_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un groupe"])},
    "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe créé avec succès"])},
    "modal_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant créer les tranches de paiement associées à ce groupe ou créer un autre groupe."])}
  },
  "other_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres paiements"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'échéance"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Période"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Unique"])},
    "period_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualiser la liste des périodes"])},
    "recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Récurrent"])},
    "title_icon_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le paiement"])},
    "title_icon_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le paiement"])},
    "title_no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun autre paiement"])},
    "modal_add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de paiement"])},
    "modal_add_card1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement effectué en une seule fois."])},
    "modal_add_card2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement qui se répète de manière régulière, de façon périodique."])},
    "message_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement supprimé avec succès"])}
  },
  "single_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement unique"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un paiement unique"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'échéance"])},
    "message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement créé avec succès"])},
    "page_modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le paiement"])},
    "message_modify_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement modifié avec succès"])},
    "message_modify_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la modification du paiement"])},
    "modal_modify_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir modifier ce paiement?"])},
    "modal_modify_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette opération n’est pas rétroactive, les paiements déjà envoyés ou réglés ne seront pas impactés. Vous devrez redéfinir les frais applicables pour les classes auxquelles le paiement est lié."])}
  },
  "recurring_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement récurrent"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un paiement récurrent"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désignation"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
    "error_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant est requis"])},
    "error_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mois est requis"])},
    "error_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le jour est requis"])},
    "add_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une période"])},
    "delete_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la période"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement créé avec succès"])},
    "period_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans cette section, vous pouvez définir les différentes périodes de paiement applicables, par exemple mensuel, trimestriel ou annuel. Cela nous permettra de mieux comprendre vos besoins en termes de fréquence de paiement."])}
  },
  "installment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tranches de paiement"])},
    "max_installments_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum 6 tranches autorisées"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé"])},
    "modal_modify_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la tranche"])},
    "title_payement_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez désormais attribuer les tranches à chaque élève de cette classe"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'échéance"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "amount_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le montant"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuration des tranches est terminée, vous pouvez consulter vos paiements"])},
    "no_installment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune tranche de paiement "])},
    "no_installment_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Les tranches de paiement correspondent à des periodes définies pour le versement des frais de scolarité"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche"])},
    "title_icon_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la tranche"])},
    "title_icon_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la tranche"])},
    "modal_modify_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir modifier cette tranche?"])},
    "modal_modify_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette opération n’est pas rétroactive, les paiements déjà envoyés ou réglés ne seront pas impactés. Vous devrez redéfinir les frais applicables pour les classes auxquelles le groupe a été lié."])},
    "modify_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la tranche"])},
    "add_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter  tranche"])},
    "modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer cette tranche ?"])},
    "modal_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette opération n’est pas rétroactive, les paiements déjà effectués ou réglés ne seront pas impactés. Vous devrez redéfinir les frais applicables pour les classes auxquelles le groupe a été appliqué."])},
    "message_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche supprimée avec succès"])},
    "message_modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche modifiée avec succès"])},
    "newinstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle tranche"])}
  },
  "params": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez ces formulaires impressionnants pour vous connecter ou créer un nouveau compte dans votre projet gratuitement."])},
    "params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de configuration"])},
    "training_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de formation"])},
    "education_system ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système éducatif"])},
    "education_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "country_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code pays"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire"])},
    "devise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
    "neighbourhood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quartier"])},
    "school_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'établissement"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations incorrectes ?"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez contacter la plateforme "])},
    "logo_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo prévisualisé"])},
    "school_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo de l'école"])},
    "change_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le logo"])},
    "choose_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un logo"])},
    "click_circle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquer sur le cercle pour"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modifier"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uploader"])},
    "your_logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["votre logo"])}
  },
  "accounts": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
    "title_icon_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer les informations"])},
    "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculin"])},
    "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Féminin"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle"])},
    "autorisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
    "no_admint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun administrateur ajouté"])},
    "lock_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir retirer l’accès cet utilisateur?"])},
    "lock_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’utilisateur n’aura plus accès à cette interface d’administration."])},
    "unlock_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir autoriser l’accès à cet utilisateur?"])},
    "unlock_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet utilisateur pourra à nouveau accéder à cet interface d’administration."])},
    "title_icon_lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquer l'utilisateur "])},
    "title_icon_unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débloquer l'utilisateur"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
    "lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisé"])},
    "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqué"])},
    "no_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page réservée aux administrateurs"])},
    "message_lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur bloqué avec succès"])},
    "message_unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur débloqué avec succès"])}
  },
  "newaccounts": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel administrateur"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un administrateur"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone (utilisé sur Eska)"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["687623235"])},
    "no_eska": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur n'est pas sur Eska."])},
    "message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur ajouté avec succès."])}
  },
  "paymentpending": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Attente de confirmation"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement Confirmé avec succès"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "matricul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçus"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "title_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails paiement en attente"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tranche"])},
    "button_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "button_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeté"])},
    "button_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
    "title_modal_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeter le paiement"])},
    "p_modal_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquer le motif du rejet du versement "])},
    "modal_reject_motif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le motif...."])},
    "title_modal_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir confirmer ce paiement?"])},
    "text_modal_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vide, quantum, inquam, fallare, Torquate. oratio me istius philosophi non offendit; nam et complectitur verbis, quod vult, et dicit plane, quod intellegam; et tamen ego a philosopho, si afferat eloquentiam, non asperner, si non habeat, non admodum flagitem. re mihi non aeque"])}
  },
  "updatepayment": {
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le groupe"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialité"])},
    "no_speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune Specialité"])},
    "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir modifier ce groupe?"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe modifié avec succès"])}
  },
  "students_detail": {
    "modal_no_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer l'élève"])},
    "modal_no_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas supprimer cet élève car il a déjà effectué des paiements."])},
    "modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Êtes-vous sûr de vouloir supprimer l'élève"])},
    "modal_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette opération est irréversible. Son historique des paiements sera conservée."])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéros de téléphone des parents"])},
    "phone_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après avoir ajouté le numéro veuillez appuyer sur la barre d’espace afin de sauvegarder."])},
    "place_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
    "sexe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
    "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feminin"])},
    "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculin"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scolarité"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche"])},
    "settled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglé"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" En attente"])},
    "other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres paiements"])},
    "update_succes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de l'élève modifieés avec success"])},
    "delete_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élève supprimé avec success"])},
    "update_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la modification des informations de l'élève"])}
  },
  "new_student": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel élève"])},
    "number_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce numéro existe déjà dans la liste"])},
    "error_checking_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la vérification du numéro"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un nouvel élève"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "number_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le matricule ou générez-le automatiquement"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
    "gendered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
    "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculin"])},
    "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Féminin"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéros des parents"])},
    "phone_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après avoir ajouté le numéro veuillez appuyer sur la barre d’espace afin de sauvegarder."])},
    "place_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
    "image_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez dans le cadre d'image pour ajouter une image"])},
    "placeholder_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le(s) numéro(s)"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élève ajouté avec succès"])},
    "new_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel élève"])},
    "old_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien élève"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
    "already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'élève existe déjà dans cette classe"])},
    "required_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ obligatoire"])},
    "save_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sauvegarder le numéro de téléphone en appuyant sur Entrer"])},
    "invalid_phone_lengt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de téléphone n'est pas correct"])}
  },
  "import_student": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer des élèves à partir d'un fichier XLSX."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez le modèle de fichier XLSX suivant pour remplir les élèves afin de les ajouter à la plateforme (Vous pouvez les remplir dans un fichier Excel en suivant ce modèle et en le sauvegardant à la fin au format XLSX)."])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier XLSX"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour visualiser "])},
    "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer"])},
    "generate_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si l’élève n’a pas de matricule, Appuyez sur Générer pour créer un nouveau."])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'élèves"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le nombre d'élèves à générer"])},
    "old_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anciens élèves"])},
    "new_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveaux élèves"])},
    "error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le nombre de matricules à générer."])},
    "title_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer le matricule"])},
    "title_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour télécharger"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour télécharger le fichier"])},
    "title_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer les matricules ?"])},
    "text_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un fichier modèle sera pré-rempli avec des matricules générés."])},
    "btn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer et télécharger"])},
    "btn2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non merci!"])},
    "text_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le bouton suivant pour télécharger le fichier"])},
    "top_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez remplir un fichier en respectant le model suivant :"])},
    "btn_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le modèle"])}
  },
  "new_class": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle classe"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une salle de classe"])},
    "pleceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ex: 1 ou A"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécialité"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de la classe"])},
    "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d’élèves estimé"])},
    "no_speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune Specialité"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe ajoutée avec succès"])},
    "impossible_add_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de créer la classe car elle existe déjà"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous n'avez pas le chiffre exact, estimez sur la base de celui de l'année passée."])}
  },
  "update_class": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la classe"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une salle de classe"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialité"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de la classe"])},
    "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'élèves"])},
    "title_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir modifier cette classe?"])},
    "message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe modifiée avec succès"])}
  },
  "modal_pending": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer un statut à cette tranche pour les élèves sélectionnés?"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le statut de paiement de la tranche indiquée, pour tous les élèves sélectionnés, sera défini par l’un des choix ci-dessous. Veuillez cocher le choix adapté, puis confirmer."])},
    "status_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement en attente"])},
    "status_pending_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette tranche sera marquée comme impayée pour les élèves sélectionnés, les demandes de paiements seront envoyées aux parents sur l’appli Eska et ceux-ci recevront une notification."])},
    "status_settled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement réglé"])},
    "status_settled_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette option indique que vous avez déjà reçu l’argent des élèves sélectionnés pour cette tranche, le paiement apparaîtra comme réglé sur votre interface, ainsi que sur l’application Eska. Vérifiez soigneusement avant de confirmer."])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'échéance"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une option avant de confirmer. "])}
  },
  "modal_pending_success": {
    "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification envoyée sur Eska"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'échéance"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut modifié avec succès"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Cliquez sur le bouton Envoyer ci-dessous pour informer les parents des élèves concernés qu’un paiement est en attente."])}
  },
  "modal_settled_success": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut modifié avec succès"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" En absence de reçu, un message sera envoyé aux différents responsables pour les informer de cette modification."])}
  },
  "receipt_list": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçus envoyés"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçus"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])}
  },
  "payment_pending": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements en cours"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun paiement en cours."])}
  },
  "no_bill_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements approuvés sans reçu"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçus"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun paiement approuvé."])}
  },
  "duedate_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiemnens à échéance dépassée"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçus"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule"])},
    "no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun paiement."])}
  },
  "list_class_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Paiements approuvés regroupés par classe"])}
  },
  "eska": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques générales"])}
  },
  "signin": {
    "login200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login successful. Welcome!"])},
    "userBlocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account is inactive. Please contact your administrator."])}
  },
  "logout": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir vous déconnecter?"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur déconnecté avec succès."])}
  },
  "message": {
    "notinternet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes hors ligne."])},
    "send_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertes de paiement envoyées aux parents."])},
    "requiredfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir tous les champs."])},
    "user404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, la page demandée est introuvable."])}
  },
  "title": {
    "page_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements en attentes"])},
    "page_noreceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements approuvés sans reçus"])},
    "page_overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements à échéance dépassés"])}
  },
  "transaction": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des transactions"])},
    "see_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout voir"])},
    "successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réussi"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
    "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérateur"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "id_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID utilisateur"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier"])},
    "last_28_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derniers 28 jours"])},
    "since_the_1st": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depuis le premier jour"])},
    "all_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les statuts"])},
    "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusées"])},
    "no_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune transaction "])},
    "no_transaction_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pourrez suivre les payments ici en temps réel"])},
    "all_operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les opérateurs"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer"])},
    "filter_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre ...."])},
    "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer les transactions"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])}
  },
  "transfer": {
    "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum de transfert"])},
    "info_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre collecte vous sera virée dès que le montant atteint "])},
    "info_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de transfert: 4 770 TTC"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "no_transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun transfert"])},
    "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire"])},
    "receiver_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte récepteur"])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque"])},
    "account_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de compte"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modifier"])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferts"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier"])},
    "last_28_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 28 derniers jours"])},
    "since_the_seven_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 7 derniers jours"])},
    "all_periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les périodes"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
    "reclamation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réclamation"])},
    "not_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virement en retard"])},
    "contested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant incorrect"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" En attente"])},
    "executed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executé"])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêt"])},
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement"])},
    "modal_bank_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations bancaires requises"])},
    "modal_bank_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour recevoir vos virements, nous vous prions de bien vouloir soumettre votre attestation bancaire."])},
    "modal_bank_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complétez vos informations bancaires"])},
    "late_transfer_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'est pas utile de faire cette réclamation car le délai pour recevoir un virement peut aller jusqu'à 72h. Votre virement a été émis le"])},
    "late_transfer_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre compte jusqu'au "])},
    "late_transfer_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Passé ce délai, faites votre réclamation si vous ne l'avez toujours reçu."])},
    "late_transfer_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de faire une réclamation pour le transfert avec les détails suivants:"])},
    "late_transfer_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virement en retard"])},
    "contest_amount_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant incorrect"])},
    "contest_amount_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant de poursuivre veuillez vérifier le montant de la collecte à partir du menu Transactions. Les détails du transfert sont les suivants:"])},
    "contest_amount_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Si le montant vous semble incorrect pour la période indiquée vous pouvez poursuivre."])},
    "contest_current_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant actuel"])},
    "contest_pending_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant attendu"])},
    "contest_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le montant attendu"])},
    "no_bank_docs_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prévisualisation du document"])},
    "dropzone_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glissez et déposez le fichier"])},
    "dropzone_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ou"])}
  },
  "configuration": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais et paiements"])},
    "transfert_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La montant minimum pour le transfert est de"])},
    "bearcosts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporter les frais"])},
    "bearcosts_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les frais de transactions seront prélevés à vous plutôt qu’aux parents."])},
    "splitpayment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement en petite tranche"])},
    "splitpayment_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les parents pourront payer à leur rythme tant qu’ils restent dans les délais. Vous aurez les détails de chaque paiement, ainsi que le récapitulatif."])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
    "bank_info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations bancaires"])},
    "admin_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette page est strictement réservée aux administrateurs."])}
  },
  "min_transfer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum de transfert"])},
    "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre montant minimum de transfert est de"])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour le modifier, veuillez sélectionner une nouvelle valeur et sauvegarder"])},
    "title_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
    "title_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir modifer le minimum de transfert?"])},
    "description_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre montant minimum de transfert sera modifié à "])},
    "title_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a été transmise avec succès! "])},
    "description_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a bien été transmise. Vous recevrez un email de confirmation dès que votre demande sera traitée."])}
  },
  "profil": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations personnelles"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])}
  },
  "todolist": {
    "tuition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scolarité"])},
    "other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres paiements"])},
    "instalment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranche"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versement"])},
    "no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun paiement"])},
    "no_other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun autre paiement pour le moment."])}
  },
  "sponsoring": {
    "title_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement sponsorisé"])},
    "body_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre établissement est soumis au programme de sponsoring ESKA. Vous bénéficiez de tous les avantages premium."])},
    "bottom_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme actif"])}
  },
  "date_sequence": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de passage de l'évaluation - Étape"])},
    "label1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la séquence"])},
    "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une séquence."])},
    "label2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le ou les niveaux concernés"])},
    "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner au moins un niveau."])},
    "label3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la ou les matières dont les notes sont disponibles "])},
    "error3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner au moins une matière."])},
    "label4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
    "label5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
    "error4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner la date de début"])},
    "error5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner la date de fin"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information envoyée avec succès."])}
  },
  "temporary_suspension": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspension temporaire des cours."])},
    "label1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la ou les niveaux concernés."])},
    "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner au moins un niveau."])},
    "label2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début."])},
    "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner la date de début."])},
    "label3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin."])},
    "error3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner la date de fin."])},
    "label4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif."])},
    "error4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un motif."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de début doit être antérieure à la date de fin."])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspension envoyée avec succès."])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ex: Grève, vacances..."])}
  },
  "grade_available": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes disponibles - Étape"])},
    "label1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la séquence."])},
    "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une séquence."])},
    "label2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la ou les matières dont les notes sont disponibles."])},
    "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner au moins une matière."])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilité des notes envoyée avec succès."])},
    "seq1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sequence 1"])},
    "seq2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sequence 2"])},
    "seq3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sequence 3"])},
    "seq4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sequence 4"])},
    "seq5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sequence 5"])},
    "seq6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sequence 6"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la fin de cette opération, les parents seront informés que les copies ont été corrigées par l'enseignant et remises aux élèves."])}
  },
  "diciplinary": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convocation au conseil de discipline"])},
    "label1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les motifs pour lesquels l'enfant est traduit au conseil de discipline."])},
    "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner au moins un motif."])},
    "label2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date du conseil de discipline."])},
    "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une date."])},
    "label3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure du conseil."])},
    "error3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une heure."])},
    "violence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violence physique"])},
    "harassment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harcèlement"])},
    "vandalism": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vandalisme"])},
    "substances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage de substances interdites"])},
    "insubordination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insubordination"])},
    "onlineBehavior": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comportement inapproprié en ligne"])},
    "absences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absences répétées"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convocation envoyée avec success"])}
  },
  "absence": {
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période d'absence"])},
    "hours_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'heures d'absence"])},
    "enter_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le nombre d'heures"])},
    "enter_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un nombre"])},
    "period_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode d'absence"])},
    "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la période"])},
    "week_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Semaine"]), _normalize([_interpolate(_named("count")), " Semaines"])])},
    "select_period_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une période"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode d'absence envoyée avec success"])}
  },
  "comportement": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport de suivi"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "presence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence"])},
    "punctuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponctualité"])},
    "attitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attitude"])},
    "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intérêt"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "attitude_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez évaluer l'attitude"])},
    "interest_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez évaluer l'intérêt"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport de suivi envoyé avec succès"])}
  },
  "attitude": {
    "veryPoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défiant / Insolent"])},
    "needsImprovement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troubleur"])},
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranquille"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonne / Appréciable"])},
    "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplaire"])},
    "pleaseRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez évaluer l'attitude"])}
  },
  "interest": {
    "veryLow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endormi / Ailleurs"])},
    "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distrait"])},
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intéressé"])},
    "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intéressé et curieux"])},
    "veryHigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participatif"])},
    "pleaseRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez évaluer l'intérêt"])}
  },
  "messages": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Messagerie enrichie"])},
    "quota_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre quota est atteint pour aujourd'hui"])},
    "quota_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos 500 messages de la journée sont épuisés. Vous pouvez attendre demain ou choisir une autre option tarifaire."])},
    "change_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer d'option tarifaire"])},
    "group_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire groupé"])},
    "individual_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire individuel"])},
    "select_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le niveau"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
    "select_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
    "all_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les classes"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe"])},
    "number_on_eska": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce numéro est sur Eska"])},
    "number_not_on_eska": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce numéro n'est pas sur Eska, il sera invité a télécharger Eska"])},
    "numbers_marked_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les numéros marqués avec"])},
    "will_be_invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ils seront invités à télécharger l'application pour les recevoir."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
    "title_french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre en français"])},
    "title_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre en anglais"])},
    "enter_title_french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le titre en français"])},
    "enter_title_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter title in English"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "french_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version française"])},
    "english_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version anglaise"])},
    "add_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un média"])},
    "added_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier ajouté"])},
    "file_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu du fichier"])},
    "delete_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le fichier"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "errors": {
      "phone_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir au moins un numéro de téléphone"])},
      "title_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir au moins un titre (français ou anglais)"])},
      "message_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir au moins un message (français ou anglais)"])},
      "character_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le message dépasse la limite de caractères autorisée"])},
      "invalid_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de classe invalide"])},
      "send_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'envoi du message. Veuillez réessayer."])}
    },
    "success": {
      "campaign_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagne créée avec succès!"])},
      "message_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message envoyé avec succès!"])}
    },
    "resend_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de renvoi"])},
    "confirm_resend_campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir renvoyer ce message groupé?"])},
    "title_fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre (FR)"])},
    "title_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre (EN)"])},
    "classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classroom"])},
    "sent_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages groupés envoyés"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement..."])},
    "no_messages_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun message trouvé"])},
    "view_campaign_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les details de la campagne"])},
    "resend_campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer le message groupé"])},
    "message_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du Message"])},
    "french_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre Français"])},
    "english_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre Anglais"])},
    "french_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Français"])},
    "english_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Anglais"])},
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce jointe"])},
    "attached_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image attachée"])}
  },
  "editor": {
    "characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caractères"])},
    "character_limit_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de caractères atteinte ! Veuillez réduire votre texte."])}
  },
  "synchronisation": {
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher par nom..."])},
    "all_subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les matières"])},
    "all_classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les classes"])},
    "all_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les status"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
    "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmé"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeté"])},
    "revoked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révoqué"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignant"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matière"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "no_sync_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune demande de synchronisation"])},
    "confirm_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la synchronisation"])},
    "confirm_sync_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir accepter cette demande?"])},
    "confirm_sync_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'enseignant pourra avoir accès à la liste des élèves de cette classe et saisir leurs notes à partir d'Eska."])},
    "reject_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser la synchronisation"])},
    "reject_sync_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir refuser cette demande?"])},
    "reject_sync_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'enseignant ne pourra pas avoir accès à la liste des élèves de cette classe et ne pourra pas saisir leurs notes à partir d'Eska."])},
    "revoke_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révoquer la synchronisation"])},
    "revoke_sync_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir révoquer cette synchronisation?"])},
    "resync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronisation"])},
    "resync_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous restaurer cette synchronisation?"])},
    "select_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la classe"])},
    "no_class_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune classe disponible pour ce niveau"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "no_sync_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune demande en attente"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
    "revoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révoquer"])},
    "synchronized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronisé"])},
    "rejected_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeté"])},
    "revoked_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révoqué"])},
    "pending_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
    "message_no_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une classe"])},
    "message_syns_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une synchronisation identique existe déjà pour cet enseignant dans cette classe"])},
    "message_sync_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La synchronisation a été effectuée avec succès"])},
    "message_sync_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la synchronisation"])},
    "message_sync_rejectted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La synchronisation a été rejetée avec succès"])},
    "message_sync_error_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors du refus de la synchronisation"])},
    "message_sync_revoked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La synchronisation a été révoquée avec succès"])},
    "message_sync_error_revoked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la révocation de la synchronisation"])},
    "message_error_get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors du chargement des données"])},
    "title_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour synchroniser"])},
    "title_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour refuser"])},
    "title_revoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour révoquer"])},
    "title_resync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour synchroniser à nouveau"])}
  },
  "note": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes des élèves"])},
    "api_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé d'API"])},
    "access_grades_api": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder aux notes via l'API"])},
    "api_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez accéder aux notes soumises avec Eska en utilisant la l'API et la clé"])},
    "see_documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la documentation"])},
    "show_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher la clé"])},
    "sequences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séquences"])},
    "sequence_number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Séquence ", _interpolate(_named("number"))])},
    "classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classes"])},
    "subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matières"])},
    "all_subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les matières"])},
    "export_excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter les notes en fichier Excel"])},
    "student_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nombre d'élèves : ", _interpolate(_named("count"))])},
    "no_grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune note disponible"])},
    "names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noms"])},
    "select_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les filtres"])},
    "please_select_sequence_and_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une séquence et une classe pour afficher les notes"])}
  },
  "fees_modal": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supporter les frais?"])},
    "content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En supportant les frais, les parents vont payer le montant exact sans supplément. Les frais de transaction XSchoolPay vous seront prélevés."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a été transmise avec succès!"])},
    "content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a bien été transmise. Vous recevrez un email de confirmation dès que votre demande sera traitée."])}
  },
  "small_install_modal": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Êtes-vous sûr de vouloir bloquer les paiements en petites tranches?"])},
    "content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les parents ne pourront pas payer à leur rythme tant qu'ils restent dans les délais."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a été transmise avec succès!"])},
    "content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a bien été transmise. Vous recevrez un email de confirmation dès que votre demande sera traitée."])}
  }
}