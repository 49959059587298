<template>
  <NetworkStatus v-if="isOffline" />
  <div v-else>
    <main>
    <div class="container-fluid">
      <!-- Header section with background image -->
      <div class="page-header min-height-300"
        style="background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80')">
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>

      <!-- Profile header card -->
      <div class="card shadow-lg header-profile mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-3">
              <div class="">
                <img v-if="studentDetails.photo_url" width="300" :src="studentDetails.photo_url"
                  class="rounded-circle img-fluid border border-2 border-white" />
                <img v-else width="300" src="@/assets/img/no_body.jpg"
                  class="rounded-circle img-fluid border border-2 border-white" />
              </div>
            </div>

            <div class="col-9 pt-7">
              <div class="row">
                <div class="col-6">
                  <h6 class="text-uppercase text-sm mt-4">
                    {{ studentDetails.first_name }} {{ studentDetails.last_name }}
                  </h6>
                </div>
                <div class="col-6 text-end px-0">
                  <argon-button color="danger" variant="gradient" style="height: 50px; padding-top: 15px; width: 150px"
                    data-bs-toggle="modal" data-bs-target="#staticBackdrop13">
                    {{ $t("button.delete") }}
                  </argon-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Main content section -->
      <div class="py-4 container-fluid">

        <div class="row">
          <div class="col-md-8">
            <div class="card pb-4">
              <div class="card-body">


                <spinner v-if="loading" class="text-center mt-8 mb-8" style="height: 200px">
                  <div><span class="mt-4">
                      <Spinner></Spinner>
                    </span></div>
                </spinner>

                <div class="" v-else-if="studentDetails">
                  <div class="row">


                    <div class="col-md-6">
                      <label class="form-control-label">{{ $t("students_detail.number") }}</label>
                      <argon-input type="text" v-model="studentDetails.registration_number" />
                    </div>

                    <div class="col-md-6">
                      <label class="form-control-label">{{ $t("students_detail.last_name") }}</label>
                      <argon-input type="text" v-model="studentDetails.last_name" />
                    </div>

                    <div class="col-md-6">
                      <label class="form-control-label">{{ $t("students_detail.first_name") }}</label>
                      <argon-input type="text" v-model="studentDetails.first_name" />
                    </div>

                    <!-- Enhanced phone input section -->

                    <div class="col-md-6">
                      <label class="form-control-label">{{ $t("students_detail.sexe") }}</label>
                      <div class="d-flex">
                        <div class="form-check">
                          <input class="form-check-input" type="radio" value="M" v-model="studentDetails.gender"
                            id="maleRadio" />
                          <label class="form-check-label" for="maleRadio">{{ $t("students_detail.M") }}</label>
                        </div>
                        <div class="form-check mx-4">
                          <input class="form-check-input" type="radio" value="F" v-model="studentDetails.gender"
                            id="femaleRadio" />
                          <label class="form-check-label" for="femaleRadio">{{ $t("students_detail.F") }}</label>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 mt-3">
                      <label class="form-control-label">{{ $t("students_detail.place_birth") }}</label>
                      <argon-input type="text" v-model="studentDetails.pob" />
                    </div>

                    <div class="col-md-6 mt-3">
                      <label class="form-control-label">{{ $t("students_detail.date") }}</label>
                      <argon-input type="date" v-model="studentDetails.dob" />
                    </div>



                    <div class="col-md-12">
                      <label class="form-control-label">{{ $t("students_detail.phone") }}</label>
                      <div class="tag-input " :class="{ 'required': phoneValidationMessage }" style="min-height: 40px">
                        <div v-for="(tag, index) in tags" :key="index" class="tag-input__tag "
                          :title="tag.is_on_eska ? $t('messages.number_on_eska') : $t('messages.number_not_on_eska')"
                          :style="{
                            backgroundColor: tag.is_on_eska ? '#2980b9' : '#2980b9',
                            opacity: tag.is_on_eska ? '1' : '0.8',
                            cursor: tag.is_on_eska ? 'pointer' : 'pointer'
                          }">
                          <img style="transform: translateY(-1px);" v-if="tag.is_on_eska" src="@/assets/img/eska.png"
                            class="eska-tag-logo me-1" alt="Eska">
                          <i v-else class="bi bi-question-circle-fill me-1"></i>
                          {{ tag.number }}
                          <span class="ms-2 text-danger" @click="removeTag(index)"><i class="fa fa-close"></i></span>
                        </div>
                        <div class="d-flex align-items-center">
                          <input type="text" :placeholder="$t('new_student.placeholder_number')"
                            class="tag-input__text  " @keydown="checkKey" @keypress="validateNumber"
                            :disabled="isCheckingNumber" autocomplete="off" />
                          <div v-if="isCheckingNumber" class="spinner-border spinner-border-sm ms-2" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </div>
                      <span v-if="phoneValidationMessage" class="validation-message">{{ phoneValidationMessage }}</span>
                      <span v-else style="font-size: 12px">{{ $t("students_detail.phone_text") }}</span>
                    </div>


                  </div>
                  <div class="col-6 mt-4">
                    <argon-button :loading="loading" :color="hasChanges ? 'success' : 'secondary'"
                      :disabled="!hasChanges" full-width @click="saveChanges" style="height: 50px; padding-top: 15px"
                      :class="{ 'no-click': !hasChanges }">
                      {{ $t("button.save") }}
                    </argon-button>
                  </div>

                </div>

              </div>
            </div>
          </div>

          <div class="col-md-4">
            <todo-list-card :total-due="studentDetails.total_due" :current_payment="studentDetails.current_payment"
              :payment_status="studentDetails.payment_status" :students="studentDetails.payments"
              :studentDetails="studentDetails" />
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Modal -->
    <div class="modal fade" id="staticBackdrop13" data-bs-backdrop="static" tabindex="-1">
      <!-- Your existing modal code -->
    </div>
  </main>
  </div>
  
</template>

<script>
import { deleteStudent, updateStudent, getStudent, getchecknumberEska } from "@/utils/services";
import ArgonButton from "@/components/ArgonButton";
import ArgonInput from "@/components/ArgonInput.vue";
import TodoListCard from "@/views/components/TodoListCard";
import Spinner from "../components/Spinner.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import NetworkStatus from "@/views/components/NetworkStatus.vue";

export default {
  name: "student-profile",
  components: { TodoListCard, ArgonInput, ArgonButton, Spinner,NetworkStatus },

  data() {
    return {
      studentDetails: {},
      loading: false,
      buttonActive: false,
      showConfirmation: false,
      phoneValidationMessage: '',
      isCheckingNumber: false,
      originalDetails: {},
      tags: [],
      eska: false,
      originalTags: [],
      isOffline: false,  
      apiError: false, 
    };
  },

  computed: {
    parsedPhoneList() {
      if (this.studentDetails.phone_list) {
        const fixedJson = this.studentDetails.phone_list
          .replace(/'/g, '"')
          .replace(/True/g, 'true')
          .replace(/False/g, 'false');
        return JSON.parse(fixedJson);
      }
      return [];
    },
    hasChanges() {
      // Vérifier si les détails de l'étudiant ont changé
      const detailsChanged = JSON.stringify(this.getComparableDetails(this.studentDetails)) !==
        JSON.stringify(this.getComparableDetails(this.originalDetails));

      // Vérifier si les tags ont changé
      const tagsChanged = JSON.stringify(this.tags) !== JSON.stringify(this.originalTags);

      return detailsChanged || tagsChanged;
    }
  },

  created() {
    this.loadStudentDetails();
  },

  async mounted() {
    // Initialiser l'état de connexion au montage
    await this.updateOnlineStatus();

    // Ajouter les event listeners pour les changements de réseau
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    this.intervalId = setInterval(this.checkInternetConnection, 1000);
    this.loadStudentDetails();

    // Charger les données initiales
    if (!this.isOffline) {
      
      await this.loadStudentDetails();
    }
  },

  

 
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

  methods: {
    async updateOnlineStatus() {
    // Vérifier si le navigateur est en ligne
    if (navigator.onLine) {
      try {
        // Vérifier si les API sont accessibles
        await this.checkConnection();
        this.isOffline = false; // Tout fonctionne, on est en ligne
      } catch (error) {
        console.error("Error checking connection:", error);
        this.isOffline = true; // Les API ne répondent pas, on est hors ligne
      }
    } else {
      // Pas de connexion internet détectée
      this.isOffline = true;
    }
  },

  async checkConnection(studentId) {
    try {
      // Essayer les deux appels API
      await Promise.all([
      getStudent(studentId)
         
      ]);
      this.apiError = false; // Les API répondent correctement
    } catch (error) {
      console.error("API Error:", error);
      this.apiError = true; // Les API ne répondent pas
    }
  },

    close() {
      this.eska = false;
    },
    validateNumber(event) {
      // Permet uniquement les chiffres
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
      }
      return true;
    },
    getComparableDetails(details) {
      if (!details) return {};
      return {
        registration_number: details.registration_number,
        last_name: details.last_name,
        first_name: details.first_name,
        gender: details.gender,
        pob: details.pob,
        dob: details.dob
      };
    },
    async checkKey(event) {
      if (event.key === 'Enter' || event.key === ' ' || event.key === ',' || event.key === ';') {
        event.preventDefault();
        let val = event.target.value.trim();

        // Vérifier si la valeur ne contient que des chiffres
        if (!/^\d+$/.test(val)) {
          toast.error(this.$t("new_student.only_numbers"));
          return;
        }

        if (val.length > 0) {
          if (!val.startsWith('237')) {
            val = '237' + val;
          }

          // Vérifier si le numéro a la bonne longueur (237 + 9 chiffres)
          if (val.length !== 12) {
            toast.error(this.$t("new_student.invalid_phone_length"));
            return;
          }

          // Le reste de votre code existant...
          const numberExists = this.tags.some(tag => tag.number === val);
          if (numberExists) {
            toast.error(this.$t("new_student.number_already_exists"), {
              autoClose: 3000,
            });
            event.target.value = "";
            return;
          }

          try {
            this.isCheckingNumber = true;
            const response = await getchecknumberEska({
              number_list: [val]
            });

            if (response.available?.includes(val)) {
              this.tags.push({
                number: val,
                is_on_eska: true
              });
              this.eska = false;
            } else {
              this.tags.push({
                number: val,
                is_on_eska: false
              });
              this.eska = true;
            }
            event.target.value = "";
            this.phoneValidationMessage = '';
          } catch (error) {
            console.error("Error checking number:", error);
            toast.error(this.$t("new_student.error_checking_number"));
          } finally {
            this.isCheckingNumber = false;
          }
        }
      }
    },

    async loadStudentDetails() {
      try {
        this.loading = true;
        const studentId = this.$route.params.id;
        this.studentDetails = await getStudent(studentId);

        // Stocker une copie profonde des détails originaux
        this.originalDetails = JSON.parse(JSON.stringify(this.studentDetails));

        // Initialiser les tags avec les numéros existants
        if (this.studentDetails.phone_list) {
          this.tags = JSON.parse(this.studentDetails.phone_list
            .replace(/'/g, '"')
            .replace(/True/g, 'true')
            .replace(/False/g, 'false')
          );
          // Stocker une copie profonde des tags originaux
          this.originalTags = JSON.parse(JSON.stringify(this.tags));
        }
      } catch (error) {
        console.error("Error loading student details:", error);
        toast.error(this.$t("messages.error.load_failed"));
      } finally {
        this.loading = false;
      }
    },


    removeTag(index) {
      this.tags.splice(index, 1);
    },

    async saveChanges() {
      try {
        if (this.loading) return;

        this.loading = true;

        if (this.tags.length === 0) {
          this.phoneValidationMessage = this.$t("new_student.required_field");

          return;
        }


        // Mettre à jour phone_list dans studentDetails
        const updatedStudent = {
          ...this.studentDetails,
          phone_list: JSON.stringify(this.tags)
        };

        const response = await updateStudent(updatedStudent.id, updatedStudent);

        if (response) {
          toast.success(this.$t("students_detail.update_success"));
          await this.loadStudentDetails();
        }
      } catch (error) {
        console.error("Error updating student:", error);
        toast.error(this.$t("messages.update_failed"));
      } finally {
        this.loading = false;
      }
    },


    async deleteStudent() {
      try {
        this.loading = true;
        await deleteStudent(this.studentDetails.id);
        toast.success(this.$t("students_detail.delete_success"));
        this.$router.push({
          name: "class-details",
          params: { classId: this.$route.params.classroom_id }
        });
      } catch (error) {
        toast.error(this.$t("messages.error.delete_failed"));
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.header-profile {
  background: transparent !important;
}

.argon-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.no-click {
  pointer-events: none;
}

.tag-input {
  width: 100%;
  background: transparent;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 10px;
  font-size: 0.9em;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag-input__tag {
  display: inline-flex;
  align-items: center;
  height: 30px;
  color: white;
  font-size: 14px;
  border-radius: 15px;
  padding: 0 8px;
  margin: 2px;
}

.tag-input__text {
  flex: 1;
  min-width: 420px;
  height: 30px;
  border: none;
  outline: none;
  font-size: 1em;
  background: none;
  color: white;
}

.eska-tag-logo {
  width: 16px;
  height: 16px;
  object-fit: contain;
  vertical-align: middle;
  border-radius: 50%;
}

.bi-question-circle-fill {
  font-size: 14px;
  color: white;
}

.validation-message {
  font-size: 0.875rem;
  color: #f13939;
  font-weight: 500;
}

.tag-input.required {
  border: 2px solid #f13939 !important;
  animation: shake 0.5s;
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  75% {
    transform: translateX(5px);
  }
}

.input-size-45 {
  height: 45px;
  font-size: 16px;
  /* Ajustez la taille de la police si nécessaire */
}
</style>
