<template>
  <NetworkStatus v-if="isOffline" />
  <div v-else>
    <main class="mt-0 main-content min-vh-100">


      <section>
        <div class="container-fluid">
          <div class="row">
            
            <div class="col-12 col-lg-6 py-0 text-center d-flex flex-column justify-content-center min-vh-100 px-3">
              
              <div class="auth-wrapper mx-auto w-100 ">
                <div class="image mt-lg-7" style="transform: translateY(-50%);">
                  <img class="logo-img" src="@/assets/img/logo-ct.png" alt="">
                </div>
                <div class="title mt-n3" style="transform: translateY(-50%);">
                  <h2 class=" text-white font-weight-bolder position-relative text-uppercase">
                    Scolarfleet
                  </h2>
                </div>

                
                <div class="card login-card mx-auto">
                  <div class="pb-0 card-header text-start">
                    <h4 class="font-weight-bolder">{{ $t("login.title") }}</h4>
                    <p class="mb-0">
                      {{ $t("login.instruction") }}
                    </p>
                  </div>

                  <div class="card-body">
                   
                    <div v-if="loginError"
                      class="alert alert-danger text-white d-flex justify-content-between align-items-center"
                      role="alert">
                      <span>{{ loginError }}</span>
                      <button type="button" class="btn-close btn-close-white" aria-label="Close"
                        @click="loginError = ''"></button>
                    </div>

                    
                    <form role="form">
                       
                      <div class="mb-3">
                        <label class="w-100 text-start d-flex" for="username">
                          {{ $t("login.label_username") }}<span class="text-danger ms-1">*</span>
                        </label>
                        <div class="input-container position-relative">
                          <argon-input v-model="username" name="username"
                            :placeholder='$t("login.placeholder_username")' size="lg" type="text"
                            :class="{ 'is-invalid': usernameError }" @input="clearUsernameError" />
                          <span class="input-icon position-absolute top-50 translate-middle-y">
                            <i class="text-dark bi bi-person-fill"></i>
                          </span>
                        </div>
                        <small class="text-danger text-start" v-if="usernameError">{{ usernameError }}</small>
                      </div>

                      
                      <div class="mb-3 position-relative">
                        <label class="w-100 text-start d-flex" for="password">
                          {{ $t("login.label_password") }}<span class="text-danger ms-1">*</span>
                        </label>
                        <div class="password-input-container position-relative">
                          <argon-input v-model="password" name="password"
                            :placeholder='$t("login.placeholder_password")' size="lg"
                            :type="showPassword ? 'text' : 'password'" :class="{ 'is-invalid': passwordError }"
                            @input="clearPasswordError" />
                          <span class="input-icon position-absolute top-50 translate-middle-y">
                            <i class="text-dark bi bi-lock-fill"></i>
                          </span>
                          <span class="password-toggle-icon position-absolute top-50 translate-middle-y"
                            @click="togglePasswordVisibility">
                            <i v-if="showPassword" class="text-dark bi bi-eye-fill"></i>
                            <i v-else class="text-dark bi bi-eye-slash-fill"></i>
                          </span>
                        </div>
                        <small class="text-danger text-start" v-if="passwordError">{{ passwordError }}</small>
                      </div>

                    
                      <div class="mb-3">
                        <argon-switch id="rememberMe">{{ $t("login.remember") }}</argon-switch>
                      </div>

                       
                      <div class="text-center">
                        <argon-button class="mt-4 w-100" color="success" size="lg" variant="gradient" :loading="loading"
                          :active="buttonActive || showConfirmation" :clickEvent="button" @click="login">
                          {{ $t("button.sign_in") }}
                        </argon-button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

             
            <div class="col-lg-6 d-none  d-lg-flex position-relative overflow-hidden">
              <div class="position-absolute h-100 w-100">
                <transition-group name="fade" tag="div" class="h-100  py-0">
                  <div v-for="(banner, index) in banner" :key="index" class="slider-image position-relative"
                    v-show="currentImageIndex === index" :style="{
                      backgroundImage: `url(${banner.image})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center'
                    }">
                    <span class="mask"></span>
                    <div v-if="banner.title && banner.description"
                      class="banner-content position-absolute bottom-0 text-white p-4">
                      <div class="title">
                        <h3>{{ banner.title }}</h3>
                        <p>{{ banner.description }}</p>
                      </div>
                    </div>
                  </div>
                </transition-group>
              </div>
            </div>

          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import NetworkStatus from "@/views/components/NetworkStatus.vue";
import { getTbanners } from "@/utils/services";
import { loginUser } from "@/utils/auth";
import { getConfig } from "@/utils/auth";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "xschool-signin",
  components: {
    ArgonInput,
    ArgonSwitch,
    ArgonButton,
    NetworkStatus,


  },
  data() {
    return {
      button: "",
      username: "",
      password: "",
      failedLogin: "",
      successLogin: "",
      loading: false,
      buttonActive: false,
      showConfirmation: false,
      showPassword: false,
      isOffline: false,
      usernameError: "",
      passwordError: "",
      formTouched: false,
      currentImageIndex: 0,
      slideInterval: null,
      loginError: "",
      banner: []
    };
  },
  mounted() {
    this.refreshBanners();
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.startSlideshow();

  },
  async created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    document.body.classList.remove("bg-gray-100");

    // Charger les informations sauvegardées si elles existent
    const rememberedUsername = localStorage.getItem('rememberedUsername');
    const hasRememberedCredentials = localStorage.getItem('hasRememberedCredentials');

    if (rememberedUsername && hasRememberedCredentials) {
      this.username = rememberedUsername;
      this.rememberMe = true;
      // Focus sur le champ de mot de passe pour faciliter la connexion
      this.$nextTick(() => {
        const passwordInput = document.querySelector('input[name="password"]');
        if (passwordInput) passwordInput.focus();
      });
    }
  },

  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    document.body.classList.add("bg-gray-100");
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
    this.stopSlideshow();

  },

  methods: {
    nextImage() {
      if (this.banner && this.banner.length > 0) {   
        this.currentImageIndex = (this.currentImageIndex + 1) % this.banner.length;
      }
    },

    startSlideshow() {
       
      if (this.banner && this.banner.length > 0) {
        this.slideInterval = setInterval(this.nextImage, 10000);
      }
    },

    stopSlideshow() {
      if (this.slideInterval) {
        clearInterval(this.slideInterval);
        this.slideInterval = null;
      }
    },
    updateOnlineStatus() {
      this.isOffline = !navigator.onLine;
    },

    clearUsernameError() {
      if (this.username.trim()) {
        this.usernameError = "";
      }
    },

    clearPasswordError() {
      if (this.password) {
        this.passwordError = "";
      }
    },

    validateForm() {
      let isValid = true;

      // Validation du nom d'utilisateur
      if (!this.username.trim()) {
        this.usernameError = this.$t("login.username_required");
        isValid = false;
      } else {
        this.usernameError = "";
      }

      // Validation du mot de passe
      if (!this.password) {
        this.passwordError = this.$t("login.password_required");
        isValid = false;
      } else {
        this.passwordError = "";
      }

      return isValid;
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    saveCredentials() {
      if (this.rememberMe) {
        localStorage.setItem('rememberedUsername', this.username);
        localStorage.setItem('hasRememberedCredentials', 'true');
      } else {
        localStorage.removeItem('rememberedUsername');
        localStorage.removeItem('hasRememberedCredentials');
      }
    },


    async login() {
      if (this.loading) {
        return;
      }

      if (!this.validateForm()) {
        return;
      }

      this.loading = true;
      this.button = "disabled";
      this.loginError = "";

      try {
        let data = await loginUser(this.username, this.password);

        if (data.status === 200) {

          this.saveCredentials();

          localStorage.setItem("username", this.username);


          if (!this.showConfirmation) {
            this.showConfirmation = true;
            const message = this.$t("signin.login200");
            toast.success(message, {
              autoClose: 3000,
            });
          }

          let config = await getConfig();
          if (config["is_verified"] === true) {
            localStorage.setItem("training_type", config["training_type"]);
            localStorage.setItem("education_grade", config["education_grade"]);
            localStorage.setItem("education_system", config["education_system"]);
            setTimeout(() => {
              this.$router.push({ name: "welcome" });
            }, 4000);
          } else {
            this.$router.push("/settings");
          }
        } else {
          this.loginError = this.$t("login.invalid_credentials");
        }
      } catch (err) {
        this.isOffline = true;
      } finally {
        this.loading = false;
        this.button = "";
      }
    },

    clearBannersCache() {
      localStorage.removeItem('banners');
      localStorage.removeItem('bannersTimestamp');
    },



    async refreshBanners() {

      this.clearBannersCache();

      await this.fetchbanners();
    },


    async fetchbanners() {
      this.loading = true;
      try {
        const cachedBanners = localStorage.getItem('banners');
        const cachedTimestamp = localStorage.getItem('bannersTimestamp');
        const currentTime = new Date().getTime();
        const cacheExpiration = 5 * 60 * 1000;


        if (!cachedBanners || !cachedTimestamp || (currentTime - parseInt(cachedTimestamp) > cacheExpiration)) {
          const browserLang = navigator.language.split('-')[0];
          const response = await getTbanners(browserLang === 'fr' ? true : false, browserLang === 'en' ? true : false);

          if (response) {
            const activeBanners = response.filter(banner => banner.is_active);


            localStorage.setItem('banners', JSON.stringify(activeBanners));
            localStorage.setItem('bannersTimestamp', currentTime.toString());

            this.banner = activeBanners;
            console.log('Nouvelles bannières chargées:', this.banner);
          } else {
            console.error('Format de réponse invalide:', response);
            this.banner = [];
          }
        } else {

          this.banner = JSON.parse(cachedBanners);
          console.log('Bannières depuis le cache:', this.banner);
        }


        if (this.banner && this.banner.length > 0) {
          this.currentImageIndex = 0;
          this.stopSlideshow();
          this.startSlideshow();
        }

      } catch (error) {
        console.error('Error fetching data banners:', error);
        const cachedBanners = localStorage.getItem('banners');
        if (cachedBanners) {
          this.banner = JSON.parse(cachedBanners);
        } else {
          this.banner = [];
        }
      } finally {
        this.loading = false;
      }
    }
  },


};
</script>

<style scoped>
.input-container {
  position: relative;
}

.input-icon {
  left: 1rem;
  z-index: 10;
}

/* Add padding to the input to make room for the icon */
:deep(.input-container input),
:deep(.password-input-container input) {
  padding-left: 2.5rem !important;
}

/* Adjust the password toggle icon position */
.password-toggle-icon {
  right: 1rem;
  z-index: 10;
  cursor: pointer;
}

form label span {
  color: red;
}

.alert {
  margin-bottom: 1rem;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
}

.alert-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.alert-danger.text-white {
  color: white !important;
}

.password-input-container {
  position: relative;
}

.password-toggle-icon {
  right: 1rem;
  z-index: 10;
  cursor: pointer;
}

/* Ajuster la position de l'input si nécessaire */
:deep(.password-input-container input) {
  padding-right: 2.5rem;
}

.mask {
  background: rgba(0, 0, 0, 0.3);
}

.text-danger {
  color: #dc3545;
  font-size: 0.875rem;
  display: block;
  margin-top: 0.25rem;
}

/* Style pour les champs invalides */
:deep(.is-invalid input) {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}

.slider-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
}

.slider-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease-in-out;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

/* Nouveaux styles responsives */
.auth-wrapper {
  max-width: 500px;
  width: 100%;
  padding: 1rem;
}

.logo-img {
  width: 120px;
  height: auto;
  max-width: 100%;
}

.login-card {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}

/* Media queries */
@media (max-width: 991.98px) {
  .auth-wrapper {
    max-width: 100%;
    padding: 1rem;
  }

  .logo-img {
    width: 100px;
  }

  .title h2 {
    font-size: 1.75rem;
  }
}

@media (max-width: 767.98px) {
  .auth-wrapper {
    padding: 0.5rem;
  }

  .card {
    margin: 1rem;
  }

  .title h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 575.98px) {
  .logo-img {
    width: 80px;
  }

  .card-body {
    padding: 1rem;
  }
}


:deep(.form-control),
:deep(.input-group) {
  width: 100%;
}


.slider-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 1s ease-in-out;

}


.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}


.banner-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.4);
  padding: 2rem;
}

.banner-content .title {
  position: absolute;
  bottom: 10%;
}

.banner-content h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.banner-content p {
  font-size: 1rem;
  margin-bottom: 0;
  opacity: 0.9;
}

.slider-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 1s ease-in-out;
  overflow: hidden;
}
</style>
