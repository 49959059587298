<template>
  <NetworkStatus v-if="isOffline" />
  <div v-else>  
    <!---modal delete payment-->

  <div class="modal fade" id="deletepaymentModald" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="col-md-12" v-if="show_detail_payment">
          <PaymentPendingDetail @delete="rejected" @continue="confirm" :selectedPayment="selectedPayment" />
        </div>
        <div class="col-md-12" v-if="show_raison_delete">
          <RefusePayment :selectedPayment="selectedPayment" @Cancel="cancel" />
        </div>
        <div class="col-md-12" v-if="show_confirmation">
          <ConfirmPayment @Cancel="cancel" :selectedPayment="selectedPayment"   :paymentId="selectedPayment.id" />
        </div>
      </div>
    </div>
  </div>

  <!---modal delete payment-->

  <div class="container-fluid  pt-0">
    <div class="card  px-0 ">
      <div class="card-body px-0">
        <div class="row" v-if="waitingConfirmation.length > 0">
          <div class="col-md-7" style="padding-left: 35px"></div>
          <div class="col-md-5 text-end" style="padding-right: 30px">
            <h6>
              Total :<span class="opacity-6 mx-2" style="font-size: 18px">XAF {{ Number(totalAmount).toLocaleString() }}
              </span>
            </h6>
          </div>
        </div>
        <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
          <div>
            <span class="mt-4">
              <Spinner></Spinner>
            </span>
          </div>
        </spinner>
        <div class="responsible-table" v-else-if="waitingConfirmation.length > 0">
          <table class="table px-0 mt-6 mb-6">
            <thead>
              <tr style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)">
                <th scope="col" class="border-0">Non</th>
                <th scope="col" class="border-0">Niveau</th>
                <th scope="col" class="border-0">Reçu</th>
                <th class="text-end border-0" scope="col">Montant</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(list, index) in paginatedWaitingConfirmation" :key="index"
                style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)">
                <th scope="row" class="px-4 border-0 pt-3">
                  {{ list.first_name }} {{ list.last_name }}
                </th>

                <td class="px-4 border-0">
                  {{ list.classroom_grade }} - {{ list.classroom_speciality }}
                  {{ list.classroom_number }}
                </td>
                <td class="px-4 border-0">
                  <a title="Cliquer pour visualier le reçu" class="opacity-6" target="_blank" :href="list.payment.receipt_image_url">
                    <i class="fas fa-file-pdf" aria-hidden="true"></i>
                    Receipt</a>
                </td>
                <td class="text-end px-4 position-relative border-0">
                  <span class="amount">
                    XAF {{ Number(list.amount_paid).toLocaleString() }}
                  </span>
                  <div class="action position-absolute top-0 mx-4">
                    <button class="btn btn-link text-secondary mb-0 text-xl" title="Detail du paiement"
                      data-bs-toggle="modal" data-bs-target="#deletepaymentModald" @click="openModal(list)">
                      <i style="
                          font-size: 18px;
                          transform: translateX(20px);
                          margin-top: 10px;
                        " class="fa fa-eye text-success" aria-hidden="true"></i>
                    </button>
                  </div>
                </td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <nav aria-label="...">
            <ul class="pagination">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="prevPage" :disabled="currentPage === 1"
                  style="background: none; color: white">
                  <i class="bi bi-chevron-left"></i>
                </button>
              </li>
              <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: currentPage === page }">
                <button class="page-link fw-bold" style="background: none" @click="() => setCurrentPage(page)">
                  {{ page }}
                </button>
              </li>

              <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                <button class="page-link" @click="nextPage" :disabled="currentPage === totalPages" style="
                    background: none;
                    color: white;
                  ">
                  <i class="bi bi-chevron-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
        <div class="else text-center pb-5" v-else>
          <div class="container">
            <div class="text mt-3">
              <img class="image" src="@/assets/img/paiementlist.png" alt="" />
              <h5 class="mt-4">
                {{ $t("dashboard.title-no-payment") }}
              </h5>
              <p class="mt-4">{{ $t("dashboard.text-no-payment") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>  
  
</template>

<script>
import RefusePayment from "./components/RefusePayment.vue";
import PaymentPendingDetail from "./components/PaymentPendingDetail.vue";
import Spinner from "../components/Spinner.vue";
import { getdashboardreport } from "@/utils/services";
import ConfirmPayment from "./components/ConfirmPayment.vue";
import NetworkStatus from "@/views/components/NetworkStatus.vue";


export default {
  name: "List-Payment",
  components: { RefusePayment, PaymentPendingDetail, Spinner, ConfirmPayment,NetworkStatus },
  data() {
    return {
      show_detail_payment: true,
      show_raison_delete: false,
      waitingConfirmation: [],
      loading: false,
      selectedPayment: null,
      currentPage: 1,
      itemsPerPage: 50,
      show_confirmation: false,
      isOffline: false,  
      apiError: false, 
      
     
      
    };
  },

   

  async mounted() {
    // Initialiser l'état de connexion au montage
    await this.updateOnlineStatus();
    this.fetchDashboardData();

    // Ajouter les event listeners pour les changements de réseau
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    this.intervalId = setInterval(this.checkInternetConnection, 1000);
    

    // Charger les données initiales
    if (!this.isOffline) {
      
      await this.fetchDashboardData();
    }
  },

  

 
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

  computed: {
    totalAmount() {
      return this.waitingConfirmation.reduce(
        (total, item) => total + parseFloat(item.amount_paid || 0),
        0
      );
    },
    totalPages() {
      return Math.ceil(this.waitingConfirmation.length / this.itemsPerPage);
    },
    paginatedWaitingConfirmation() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.waitingConfirmation.slice(startIndex, endIndex);
    },
  },
  methods: {
    async updateOnlineStatus() {
    // Vérifier si le navigateur est en ligne
    if (navigator.onLine) {
      try {
        // Vérifier si les API sont accessibles
        await this.checkConnection();
        this.isOffline = false; // Tout fonctionne, on est en ligne
      } catch (error) {
        console.error("Error checking connection:", error);
        this.isOffline = true; // Les API ne répondent pas, on est hors ligne
      }
    } else {
      // Pas de connexion internet détectée
      this.isOffline = true;
    }
  },

  async checkConnection() {
    try {
      // Essayer les deux appels API
      await Promise.all([
      getdashboardreport()
         
      ]);
      this.apiError = false; // Les API répondent correctement
    } catch (error) {
      console.error("API Error:", error);
      this.apiError = true; // Les API ne répondent pas
    }
  },

    openModal(list) {
      this.show_detail_payment = true;
      this.show_raison_delete = false;
      this.show_confirmation = false;
      this.selectedPayment = list;
      this.isMessageValid= true;
    },

    rejected() {
      this.show_detail_payment = false;
      this.show_raison_delete = true;
    },

    cancel() {
      this.show_detail_payment = true;
      this.show_raison_delete = false;
    },
    confirm() {
      this.show_detail_payment = false;
      this.show_raison_delete = false;
      this.show_confirmation = true;
    },

    


    async fetchDashboardData() {
      try {
        this.loading = true;
        this.dashboardData = await getdashboardreport();


        if (this.dashboardData) {
          this.waitingConfirmation = this.dashboardData.waiting_confirmation;
           
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        this.loading = false;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },
  },
   
};
</script>

<style scoped>
/*=======css table ====*/
.start-table {
  margin-left: 0px;
}

.table tbody tr td {
  position: relative;
}

.table tbody tr td .button {
  position: absolute;
  right: 15px;
  height: 100%;
  top: 0;
  display: none;
}

.table tbody tr {
  transition: 0.7s;
}

.table tbody tr:hover {
  background: rgba(0, 0, 0, 0.3);
}

.table tbody tr:hover td .button {
  display: block;
}

.table tbody tr:hover td .amount {
  filter: blur(2px);
}

/*=======css table ====*/
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal .modal-dialog {
  max-width: 80%;
  width: 70%;
  margin-left: 26%;
  height: 80%;
}

.modal .modal-dialog .modal-content {
  background: transparent;
}

.pagination {
  transform: translateY(-50px);
  display: flex;
}

.pagination button {
  margin: 0 5px;
  cursor: pointer;
  background-color: #081739;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 20px;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

</style>
