<template>
  <div class="card card-confirm">
    <div class="card-header">
      <h6>{{ $t("paymentpending.title_modal") }}</h6>
    </div>
    <div class="card-body border-botto">
      <div class="row">
        <div class="col-md-5" v-if="selectedPayment">
          <table class="table table-hover h-100">
            <thead></thead>
            <tbody>
              <tr>
                <th class="border w-30 pt-4">{{ $t("paymentpending.name") }}</th>
                <td class="border pt-4">{{ selectedPayment.last_name }}</td>
              </tr>
              <tr>
                <th class="border w-30 pt-4">{{ $t("paymentpending.last_name") }}</th>
                <td class="border pt-4">{{ selectedPayment.first_name }}</td>
              </tr>
              <tr>
                <th class="border w-30 pt-4">{{ $t("paymentpending.matricul") }}</th>
                <td class="border pt-4">
                  {{ selectedPayment.registration_number }}
                </td>
              </tr>
              <tr>
                <th class="border w-30 pt-4">{{ $t("paymentpending.grade") }}</th>
                <td class="border pt-4">
                  {{ selectedPayment.classroom_grade }} -
                  {{ selectedPayment.classroom_speciality }}
                  {{ selectedPayment.classroom_number }}
                </td>
              </tr>
              <tr>
                <th class="border w-30 pt-4">{{ $t("paymentpending.amount") }}</th>
                <td class="border pt-4">
                  {{currency_code}} 
                  {{ Number(selectedPayment.amount_paid).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          }) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-7">
          <div v-if="loadind" class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="image-container border position-relative" v-else-if="selectedPayment">
            <img class="zoom-image" :src="selectedPayment.payment.receipt_image_url" alt="">
            <button title="Visualiser la facture" class="download-button">
              <a target="_blank" :href="selectedPayment.payment.receipt_image_url"
                :download="selectedPayment.payment.receipt_image_url">
                <i class="fa fa-eye"></i>
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer mt-3">
      <div class="row">
        <div class="col-lg-6 d-flex">
          <argon-button :loading="loading" :active="buttonActive || showConfirmation" color="warning"
            title="Confirm payment" type="button" class="mt-1" data-bs-dismiss="modal" aria-label="Close"
            style="width: 150px; height: 50px; padding-top: 15px; cursor: pointer; margin-right: 10px;">
            {{ $t("button.cancel") }}
          </argon-button>
        </div>
        <div class="col-lg-6 d-flex justify-content-end">
          <argon-button :loading="loading" :active="buttonActive || showConfirmation" color="danger"
            title="Rejeter le paiement" type="button" class="mt-1" @click="deletePayment"
            style="width: 150px; height: 50px; padding-top: 15px; cursor: pointer; margin-right: 10px;">
            {{ $t("button.reject") }}
          </argon-button>
          <argon-button :loading="loading" :active="buttonActive || showConfirmation" color="success"
            title="Confirmer le paiemnet" type="button" class="mt-1" @click="confirmPayment"
            style="width: 150px; height: 50px; padding-top: 15px; cursor: pointer;">
            {{ $t("button.continue") }}
          </argon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import api_config from "@/config";
export default {
  name: "Payment-Pending-Detail",
  
  data(){
  return{
    loadind:false,
    currency_code: api_config.CURRENCY_CODE,
  }
  },

  components: {
    ArgonButton,
  },
  methods: {
    deletePayment() {
      this.$emit("delete");
    },
    confirmPayment() {
      this.$emit("continue");
    },
  },
  props: {
    selectedPayment: Object,
    payment: {
      type: String,
    },
    name: {
      type: String,
    },
    grade: {
      type: String,
    },
    amount: {
      type: String,
    },
  },
};
</script>

<style scoped>
.card-header {
  padding: 0;
}

.card .card-body textarea {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  padding: 20px;
  opacity: 0.8;
}

.image-container {
  height: 380px;
  overflow: hidden;
  position: relative;
  background: transparent;
}

.zoom-image {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
}

.image-container:hover .zoom-image {
  transform: scale(1.2);
}

.download-button {
  height: 50px;
  width: 50px;
  background: #2DCE89;
  border-radius: 100%;
  border: none;
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
