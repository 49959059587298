<template>

  <div class="py-4 container">
    <div class="row mt-3 mb-4 text-center">
      <h5 class="mb-5">{{ $t("recurring_payment.title") }}</h5>
      <div class="row mt-3">
        <div class="col-md-6 col-xs-6 col-lg-7 content text-start">
          <div class="p-3 card w-">
            <div class="card-header pb-0"></div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="mb-4">
                <label>{{ $t("recurring_payment.description") }} <em style="color: #f60000">*</em></label>
                <argon-input placeholder="Désignation..." class="p-1" v-model="description" required></argon-input>
              </div>
              <div class="mb-4">
                <label>{{ $t("recurring_payment.period") }}</label>
              </div>
              <p style="font-size: 13px;">
                {{ $t("recurring_payment.period_text") }}
              </p>
              <div v-for="(period, index) in periods" :key="index" class="mb-4" style="position: relative">
                <div class="row align-items-center  pb-3 ">
                  <div class="col-5" style="margin-top:-14px ;">
                    <label style="transform: translateY(8px);"> {{ $t("recurring_payment.amount") }} <em
                        style="color: #f60000">*</em></label>
                    <input style="transform: translateY(8px);" type="number"
                      :placeholder="$t('recurring_payment.amount')" class="p-1 input" v-model="period.amount"
                      :class="{ 'is-invalid': errors[index]?.amount }" />
                    <div style="transform:translateY(8px)" v-if="errors[index]?.amount" class="text-danger">{{
                      errors[index].amount }}</div>
                  </div>
                  <div class="col-3">
                    <label> {{ $t("recurring_payment.month") }} <em style="color: #f60000">*</em></label>
                    <div class="">
                      <select style="height:50px" v-model="period.label" class="form-select"
                        :class="{ 'is-invalid': errors[index]?.label }">
                        <option v-for="(month, monthIndex) in months" :key="monthIndex" :value="monthIndex + 1">{{ month
                          }}</option>
                      </select>
                    </div>
                    <div v-if="errors[index]?.label" class="text-danger">{{ errors[index].label }}</div>
                  </div>
                  <div class="col-3">
                    <label>{{ $t("recurring_payment.day") }} <em style="color: #f60000">*</em></label>
                    <div class="d-flex">
                      <select style="height:50px; border:1x solid red" v-model="period.date" class="form-select me-2"
                        :class="{ 'is-invalid': errors[index]?.date }">
                        <option v-for="day in days" :key="day" :value="day">{{ day }}</option>
                      </select>
                    </div>
                    <div v-if="errors[index]?.date" class="text-danger">{{ errors[index].date }}</div>
                  </div>

                  <div style="transform: translateX(-14px)" class=" col-md-1 mt-3  text-start pe-2 " v-if="index > 0">
                    <button  style="  width:50px; height: 50px;" @click="removePeriod(index)"
                      class="btn btn-danger mt-4 " :title="$t('recurring_payment.delete_period')">
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="button text-end">
                <button @click="addPeriod" class="btn btn-success" :title="$t('recurring_payment.add_period')">
                  <i class="bi bi-plus"></i>
                </button>
              </div>
              <div class="mt-5">
                <div class="row">

                  <div class="col-md-6">
                    <argon-button :loading="loading" :active="buttonActive || showConfirmation" fullWidth
                      color="success" variant="gradient" class="my-4 mb-2" :clickEvent="button" :disabled="loading"
                      @click="createotherconfig" size="lg">
                      {{ $t("button.save") }}
                    </argon-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";
import ArgonInput from "@/components/ArgonInput";
import { createotherconfig } from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "Recurent_Payment_Form",
  components: { ArgonButton, ArgonInput },
  data() {
    return {
      designation: "",
      periods: [{ label: '', amount: '', date: '' }],
      storedPeriods: [],
      loading: false,
      days: Array.from({ length: 31 }, (_, i) => i + 1),
      months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"], // Mois de l'année
      errors: [{ date: '', label: '', amount: '' }],
      is_on_off: false,
      decription: "",
      amount: 0,
      due_date: "2024-05-10",
      buttonActive: false,
      showConfirmation: false,
    };
  },
  methods: {
    addPeriod() {
      // Mettre à jour l'état de chargement
      this.loading = true;

      let hasError = false;

      // Réinitialiser les erreurs
      this.errors = this.periods.map(() => ({ label: '', amount: '', date: '' }));

      this.periods.forEach((period, index) => {
        if (!period.amount) {
          this.errors[index].amount = this.$t("recurring_payment.error_amount");
          hasError = true;
        }
        if (!period.label) {
          this.errors[index].label = this.$t("recurring_payment.error_month");
          hasError = true;
        }
        if (!period.date) {
          this.errors[index].date = this.$t("recurring_payment.error_day");
          hasError = true;
        }
      });

      if (hasError) {

        this.loading = false; // Réinitialiser l'état de chargement
        return;
      }

      // Ajouter une nouvelle période vide
      this.periods.push({ label: '', amount: '', date: '' });
      this.errors.push({ label: '', amount: '', date: '' }); // Ajouter un objet d'erreur pour la nouvelle période

      // Réinitialiser l'état de chargement
      this.loading = false;
    },
    removePeriod(index) {
      this.periods.splice(index, 1);
      this.errors.splice(index, 1); // Supprimer l'erreur correspondante
    },

    async createotherconfig() {
      try {
        if (this.loading) {
          return;
        }


        if (!this.description) {


          if (!this.showConfirmation) {
            this.showConfirmation = true;
            const message = this.$t("message.requiredfile");
            toast.error(message, {
              autoClose: 3000,
            });
            setTimeout(() => {
              this.showConfirmation = false;
            }, 4000);
          }
          return;
        }

        // Validation des champs requis


        this.loading = true;
        this.button = "disabled";

        // Formater la date au format ISO 8601
        let formattedDueDate = new Date(this.due_date).toISOString();

        let data = {
          is_on_off: this.is_on_off,
          description: this.description,
          amount: this.amount,
          due_date: formattedDueDate,  // Utiliser la date formatée
          invoices: this.periods
        };

       

        let res = await createotherconfig(data);

        if (!navigator.onLine) {
          const message = this.$t("message.notinternet");
          toast.error(message, {
            autoClose: 3000,
          });
        } else if (res.status === 404) {
          const message = this.$t("message.user404");
          toast.error(message, {
            autoClose: 3000,
          });
        } else if (res.status === 201) {
          this.showConfirmation = true;
          const message = this.$t("recurring_payment.success_message");
          toast.success(message, {
            autoClose: 3000,
          });

          setTimeout(() => {
            this.showConfirmation = false;
            this.$router.push({
              path: "/other-payment",
              query: { forceReload: true },
            });
          }, 4000);
        }
      } catch (error) {
        console.error(error);

        if (error.response && error.response.status === 404) {
          toast.error(".  error", {
            autoClose: 3000,
          });
        } else {
          const message = this.$t("message.duplicateotherpayment");
          toast.error(message, {
            autoClose: 3000,
          });
        }
      } finally {
        this.loading = false;
        this.button = "";
      }
    }

  }
}
</script>

<style scoped>
.content {
  margin-left: auto;
  margin-right: auto;
}

.input {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid white;

}

.is-invalid {
  border-color: red !important;
}
</style>
