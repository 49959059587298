<template>
<NetworkStatus v-if="isOffline" />
<div v-else>  
  
   

  <div class="container-fluid">
    
    <div class="card px-0 mt-3">
      <div class="card-body px-0">
        <div class="row">
          <div class="col-md-6 " style="padding-left: 35px">
             
            <i  class="bi bi-cash-stack fs-3"></i>
          </div>
          <div class="col-md-6 text-end" style="padding-right: 30px">
            <RouterLink to="/create-payment-config" v-if="config.length > 0">
              <argon-button color="success" size="sm" variant="outline">
                <i class="fas fa-plus me-2"></i>
                {{ $t("payment.button_add") }}
              </argon-button>
            </RouterLink>
          </div>
        </div>

        <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
          <div>
            <span class="mt-4"><Spinner /></span>
          </div>
        </spinner>

        <table class="table border-0 px-0 mt-6 mb-6" v-else-if="config.length > 0">
          <thead>
            <tr style="border-bottom: 1px solid rgba(255, 255, 255, 0.1);">
              <th scope="col" class="border-0">{{ $t("payment.grade") }}</th>
              <th scope="col" class="border-0">{{ $t("payment.speciality") }}</th>
              <th scope="col" class="border-0 text-end pe-1 w-10">{{ $t("payment.total_installment") }}</th>
              <th scope="col" class="border-0 w-40 text-end">{{ $t("payment.total_amount") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="list in config" :key="list.id" @mouseover="list.showCustomDiv = list.installment_count === 0" @mouseleave="list.showCustomDiv = false" style="position: relative; border-bottom: 1px solid rgba(255, 255, 255, 0.1);">
              <td scope="row" class="px-4 border-0" title="Cliquez pour modifier le groupe">
                <router-link :to="{ name: 'update-config', params: { id: list.id } }"> <span style="opacity:0.6">{{ list.grade_code }}</span> </router-link>
              </td>
              <td class="px-4 border-0" v-if="list.speciality_code === 'Aucune Specialité'|| list.speciality_code==='No Speciality'">-------------</td>
              <td class="px-4 border-0" v-else>{{ list.speciality_code }}</td>
              <td class=" border-0 text-end position-relative w-10">
                <div v-if="list.showCustomDiv" class="custom-div  text-dark" :class="{ 'custom-div-active': list.showCustomDiv }">{{ $t("payment.alert") }}</div>
                <span class="alert bg-transparent" v-if="list.installment_count === 0" style="z-index: 1"><i class="bi fs-6 text-warning bi-exclamation-triangle-fill"></i></span>
                {{ list.installment_count }}
                
              </td>
              <td class="text-end  position-relative border-0">
                <span class="amount border-0" style="transform: translateY(-10px);">{{currency_code }}
                  {{ Number(list.total_amount).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0}) }}
                  </span>
                <div class="action  position-absolute top-0 ">
                  <router-link :to="{ name: 'detail-config', params: { id: list.id } }">
                    <button class="btn btn-link text-secondary mb-0 text-xl" :title="$t('newpayment.title_icon_add')">
                      <i class="fa fa-plus fs-5 mt-2 text-success" aria-hidden="true"></i>
                    </button>
                  </router-link>
                  <button class="btn btn-link text-secondary mb-0 text-xl" :title="$t('newpayment.title_icon_delete')" data-bs-toggle="modal" data-bs-target="#staticBackdrop3" @click="confirmDelete(list.id)">
                    <i class="fa fa-trash-alt fs-5 mt-2 text-success" aria-hidden="true"></i>
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <div class="else text-center pb-5" v-else>
          <div class="container">
            <div class="text mt-3">
              <img class="image" src="@/assets/img/groupe.png" alt="" />
              <h5 class="mt-4">{{ $t("payment.no_payment_title") }}</h5>
              <p class="mt-4" v-html="$t('payment.no_payment_p')"></p>
              <p class="mt-5">
                <router-link to="/create-payment-config" class="btn w-20 fs-6 text-center bg-gradient-success" style="width: 200px; z-index: 2">
                  <span class="fa fa-plus-circle m-2"></span>
                  {{ $t("payment.button_add") }}
                </router-link>
              </p>
            </div>
          </div> 
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Delete -->
  <div class="modal fade" id="staticBackdrop3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom:1px solid rgba(255, 255, 255, 0.4);">
            <h5 class="modal-title" id="staticBackdropLabel3"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center pt-4 pb-4">
            <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
            <h6>{{ $t("payment.modal_delete_title") }}</h6>
            <p class="text-justify px-6">{{ $t("payment.modal_delete_text") }}</p>
          </div>
          <div class="modal-footer" style="border-top:1px solid rgba(255, 255, 255, 0.4);">
            <argon-button type="button"    class="btn bg-transparent " data-bs-dismiss="modal" style="width: 150px; height: 50px; padding-top: 15px; border:1px solid rgba(255, 255, 255, 0.4);">
              {{ $t("button.cancel") }}
            </argon-button>
            <argon-button :loading="loading" :active="buttonActive || showConfirmation"  color="danger" variant="gradient" type="button" class="mt-1" style="width: 150px; height: 50px; padding-top: 15px" @click="deleteConfiguration(configToDeleteId)">
              {{ $t("button.confirm") }}
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>  
</template>


<script>
import ArgonButton from "@/components/ArgonButton";
import Spinner from "../components/Spinner.vue";
import NetworkStatus from "@/views/components/NetworkStatus.vue";
import api_config from "@/config";

import {
  getcreateconfig,
  deleteConfig,
  getCreateConfigDetails,
  deleteInstallment,
  

 
} from "@/utils/services";
import { ref, onMounted } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  name: "payment-config",
  components: { ArgonButton, Spinner,NetworkStatus },
  data() {
    return {
      grade_list: [],
      grade_code: "",
      installment_count: "",
      total_amount: "",
      installment_list: [],
      showCustomDiv: false,
      configToDeleteId: null,
      buttonActive: false,
      showConfirmation: false,
      isOffline: false,  
      apiError: false, 
      currency_code: api_config.CURRENCY_CODE,
      
    };
  },

  async mounted() {
    // Initialiser l'état de connexion au montage
   
    this.fetchConfig;

    // Ajouter les event listeners pour les changements de réseau
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    this.intervalId = setInterval(this.checkInternetConnection, 1000);
    

    // Charger les données initiales
    if (!this.isOffline) {
      
       this.fetchConfig;
    }
  },

  

 
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

   
   
  methods: {

    async updateOnlineStatus() {
    // Vérifier si le navigateur est en ligne
    if (navigator.onLine) {
      try {
        // Vérifier si les API sont accessibles
        await this.checkConnection();
        this.isOffline = false; // Tout fonctionne, on est en ligne
      } catch (error) {
        console.error("Error checking connection:", error);
        this.isOffline = true; // Les API ne répondent pas, on est hors ligne
      }
    } else {
      // Pas de connexion internet détectée
      this.isOffline = true;
    }
  },

  async checkConnection() {
    try {
      // Essayer les deux appels API
      await Promise.all([
      getcreateconfig()
         
      ]);
      this.apiError = false; // Les API répondent correctement
    } catch (error) {
      console.error("API Error:", error);
      this.apiError = true; // Les API ne répondent pas
    }
  },

    getObject(grade) {
      this.grade_code = grade.grade_code;
      this.installment_count = grade.installment_list.length;
      this.total_amount = grade.total_amount;
      this.installment_list = grade.installment_list;
    },

    confirmDelete(configId) {
      this.configToDeleteId = configId;
    },

    async deleteConfiguration(configId) {
      try {
        if (this.loading) {
          return;
        }

        this.loading = true;
        // Récupérez la configuration pour obtenir la liste des installments
        const config = await getCreateConfigDetails();

        // Supprimez la configuration
        const deleted = await deleteConfig(configId);

        if (deleted) {
          this.showConfirmation = true;
          const message = this.$t("payment.success_message");
          toast.success(message, {
            autoClose: 3000,
          });

          // Supprimez chaque installment associée à la configuration
          if (config && config.installment_list) {
            for (const installment of config.installment_list) {
              await deleteInstallment(installment.id);
            }
          }

          setTimeout(() => {
            window.location.reload();
            this.showConfirmation = false;
          }, 3000);
        }
      } catch (error) {
        console.error("Error while deleting configuration:", error);
      } finally {
        this.loading = false;
      }
    },
  },



  setup() {

    
    const config = ref([]);
    const loading = ref(false);

    const fetchConfig = async () => {
      try {
        loading.value = true;
        const result = await getcreateconfig();

        if (result.status === 200) {
          // Initialiser showCustomDiv à false pour chaque élément de la liste
          config.value = result.data.map((item) => ({
            ...item,
            showCustomDiv: false,
          }));
          this.showModal = true;
        } else {
          console.error(
            "Erreur lors de la récupération des membres :",
            result.error
          );
        }
      } catch (error) {
        console.error(
          "Erreur inattendue lors de la récupération des membres :",
          error
        );
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchConfig();
    });

    return {
      config,
      loading,
    };
  },
};
</script>

<style scoped>


/*=======css table ====*/
.else .image {
  width: 300px;
  margin-top: 10px;
}
.card .table thead th {
  width: 30%;
}
.card .table tbody tr  td .amount {
   position: absolute;
   right: 20px;
 
}

.card .table tbody tr:hover td .amount {
  filter: blur(2px);
 
}
.card .table tbody tr {
  transition: 0.7s;
  cursor: pointer;
}
.action {
  display: none;
  right:0;
}
.card .table tbody tr:hover .action {
  display: block;
}

.table tbody tr td {
  padding-top: 13px;
  padding-bottom: 13px;
}

table tbody tr:hover td .button {
  display: block;
}
.table tbody tr:hover td .amount p {
  filter: blur(2px);
  
}

.table thead tr th {
  width: 20%;
}

.td-warning {
  position: relative;
}

.warning {
  font-size: 20px;
  position: absolute;
  right: 90px;
  top: 6px;
}

.text-warning1 {
  background: white;
  color: black;
  position: absolute;
  top: -45px;
  right: 100px;
  padding: 15px 30px;
}

/*=======css table ====*/

.custom-div {
  position: absolute;
  background-color: white;
  padding: 10px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: none;
  margin-bottom: -30px; /* Ajoutez cette ligne pour compenser le déplacement vers le haut */
  margin-left: -10px; /* Ajoutez cette ligne pour compenser le déplacement vers la gauche */
}

.custom-div-active {
  display: block;
}

/*=======css modal ====*/
.modal {
  background: rgba(0, 0, 0, 0.4);
}
.modal .modal-dialog {
  max-width: 55%;
  width: 55;
  margin-left: 30%;
}

.modal .modal-dialog .modal-content {
  background: transparent;
}
.t-titre {
  width: max-content;
  margin-left: 4%;
}
.table tbody tr td {
  padding-top: 13px;
  padding-bottom: 13px;
 
}

table tbody tr:hover td .button {
  display: block;
}
.table tbody tr:hover td .amount p {
  filter: blur(2px);
}
.table tbody tr:hover td .alert {
  filter: blur(4px);
}
.table thead tr th {
  width: 20%;
}

.td-warning {
  position: relative;
}

.warning {
  font-size: 20px;
  position: absolute;
  right: 90px;
  top: 6px;
}

.text-warning1 {
  background: white;
  color: black;
  position: absolute;
  top: -45px;
  right: 100px;
  padding: 15px 30px;
}

@media (max-width: 767px) {
  .card .card-body .table-responsive {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .modal .modal-dialog {
    max-width: 90%;
    width: 90%;
    margin-left: 5%;
    height: 80%;
  }

  .t-titre {
    width: max-content;
    margin-left: 0%;
  }
  .card .table tbody .else .button-else {
    display: none;
  }
}

@media (min-width: 768px) {
  .card .card-body .table-responsive {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}
</style>
