<template>

  <NetworkStatus v-if="isOffline" />
  <div v-else>



    <div class="container top-0 position-sticky z-index-sticky"></div>

    <main class="main-content mt-0">
      <div class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg" style="
        background-image: url(&quot;https://cdn.pixabay.com/photo/2015/07/19/10/00/school-work-851328_640.jpg&quot;);
      ">
        <span class="mask bg-gradient-dark opacity-6"></span>
        <div class="container">
          <div class="row justify-content-center">
            <spinner class="text-center" v-if="loading" style="height: 200px">
              <div>
                <span class="mt-4">
                  <Spinner></Spinner>
                </span>
              </div>
            </spinner>

            <div class="col-lg-5 mt-8 text-center mx-auto" v-else>
              <h3 class="text-white mb-2 mt-3">
                {{ $t("params.params") }}{{ schoolInfo.name }}
              </h3>

              <div class="button mt-4 mb-4">

                <span @click="saveConfig" title="Aller la page d'accueil" class="opacity-6 heart-animation"
                  :loading="loading" :active="buttonActive || showConfirmation">
                  {{ $t("button.continue") }}
                </span>

              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row mt-md-n11 mt-n10 justify-content-center">
          <div class="col-xl-10 col-lg-10 col-md-10 mx-auto">
            <div class="card z-index-0">
              <div class="card-body">
                <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
                  <div>
                    <span class="mt-4">
                      <Spinner></Spinner>
                    </span>
                  </div>
                </spinner>

                <form role="form" v-else>

                  <div class="image text-center mx-auto mb-5">
                    
                    <div class="mx-auto upload-container">
                      <input type="file" id="logoUpload" @change="onFileChange" accept="image/*" class="file-input">
                      <label for="logoUpload" class="upload-label">
                        <img v-if="previewImage" :src="previewImage" :alt="$t('params.logo_preview')"
                          class="preview-image">
                        <img v-else-if="schoolLogo" :src="schoolLogo" :alt="$t('params.school_logo')"
                          class="preview-image">
                        <span v-else class="upload-icon bi bi-file-image"></span>
                        <span class="upload-text">{{ schoolLogo ? $t('params.change_logo') : $t('params.choose_logo')
                          }}</span>
                      </label>
                    </div>
                    <span style="font-size:13px">{{ $t('params.click_circle') }} {{ schoolLogo ? $t('params.modify') :
                      $t('params.upload') }} {{ $t('params.your_logo') }}</span>
                  </div>

                  <div class="row">
                    <div class="mb-3 col-6 px-8">
                      <h6 style="transform: translateY(13px)" class="fw-bold">
                        {{ $t("params.school_name") }}
                      </h6>
                      <p class="mt-4">{{ name }}</p>
                      <hr class="mt-2 horizontal" />
                    </div>

                    <div class="mb-3 col-6 px-8">
                      <h6 style="transform: translateY(13px)" class="fw-bold">
                        {{ $t("params.training_type") }}
                      </h6>
                      <p class="mt-4">{{ training_type1 }}</p>
                      <hr class="mt-2 horizontal" />
                    </div>

                    <div class="mb-3 col-6 px-8">
                      <h6 style="transform: translateY(13px)" class="fw-bold">
                        {{ $t("params.education_system") }}
                      </h6>

                      <p class="mt-3">{{ education_system1 }}</p>
                      <hr class="mt-2 horizontal" />
                    </div>

                    <div class="mb-3 col-6 px-8">
                      <h6 style="transform: translateY(13px)" class="fw-bold">
                        {{ $t("params.education_grade") }}
                      </h6>

                      <p class="mt-3">{{ education_grade1 }}</p>
                      <hr class="mt-2 horizontal" />
                    </div>

                    <div class="mb-3 col-6 px-8">
                      <h6 style="transform: translateY(13px)" class="fw-bold">
                        {{ $t("params.country_code") }}
                      </h6>

                      <p class="mt-4">{{ country_code }}</p>
                      <hr class="mt-2 horizontal" />
                    </div>

                    <div class="mb-3 col-6 px-8">
                      <h6 style="transform: translateY(13px)" class="fw-bold">
                        {{ $t("params.currency") }}
                      </h6>

                      <p class="mt-4">{{ currency_code }}</p>
                      <hr class="mt-2 horizontal" />
                    </div>

                    <div class="mb-3 col-6 px-8">
                      <h6 style="transform: translateY(13px)" class="fw-bold">
                        {{ $t("params.owner") }}
                      </h6>

                      <p class="mt-4">{{ owner }}</p>
                      <hr class="mt-2 horizontal" />
                    </div>

                    <div class="mb-3 col-6 px-8">
                      <h6 style="transform: translateY(13px)" class="fw-bold">
                        {{ $t("params.region") }}
                      </h6>

                      <p class="mt-4">{{ region }}</p>
                      <hr class="mt-2 horizontal" />
                    </div>
                    <div class="mb-3 col-6 px-8">
                      <h6 style="transform: translateY(13px)" class="fw-bold">
                        {{ $t("params.city") }}
                      </h6>

                      <p class="mt-4">{{ town }}</p>
                      <hr class="mt-2 horizontal" />
                    </div>

                    <div class="mb-3 col-6 px-8">
                      <h6 style="transform: translateY(13px)" class="fw-bold">
                        {{ $t("params.neighbourhood") }}

                      </h6>

                      <p class="mt-4">{{ hood }}</p>
                      <hr class="mt-2 horizontal" />
                    </div>
                  </div>
                </form>

                <div class="text text-center mt-4" v-if="welcome">
                  <p> {{ $t("params.text1") }}</p>
                  <p>
                    {{ $t("params.text2") }}
                    <span><a title="Cliquer pour contacter Eska" class="link fw-bold" target="_blank"
                        href="#">Eska</a></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="container mt-4 " v-if="hasAdmin">
        <div class="card  col-xl-10 col-lg-10 col-md-10 mx-auto">
          <div class="card-body">
            <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
              <div>
                <span class="mt-4">
                  <Spinner></Spinner>
                </span>
              </div>
            </spinner>
            <div class="title mb-4 mt-n1">
              <h6>{{ $t("configuration.title") }}</h6>
            </div>

            <div class="school-info">
              <div class="row pt-2 pb-5">
                <div class="col-6">
                  <div class="mt-3 d-flex pe-4  w-60 mb-3 ">
                    <h6>{{ $t("configuration.bearcosts_title") }}</h6>
                    <div class="form-check form-switch ps-0  my-auto" style="margin-left: 100px;">
                      <input class=" mt-n1 form-check-input" type="checkbox" id="supportFeesCheckbox"
                        v-model="isSupportFeesModalVisible" />
                    </div>
                  </div>
                  <p class="mt-2  d-block">{{ $t("configuration.bearcosts_p") }}</p>
                </div>
                <div class="col-6">
                  <div class="mt-3 d-flex w-60 mb-3">
                    <h6>{{ $t("configuration.splitpayment_title") }}</h6>
                    <div class="form-check form-switch ps-0 ms-auto my-auto ">
                      <input class=" form-check-input mt-n1" type="checkbox" id="smallInstallmentCheckbox"
                        v-model="isSmallInstallmentModalVisible" />
                    </div>
                  </div>
                  <p class="mt-2  d-block">{{ $t("configuration.splitpayment_p") }}</p>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div class="container mt-4" v-if="hasAdmin" id="payment-settings">
        <div class="card col-xl-10 col-lg-10 col-md-10 mx-auto">
          <div class="card-body">
            <div class="title mb-4 mt-n1">
              <h6>{{ $t("min_transfer.title") }} </h6>
            </div>
            <p class="mt-4 mb-4  d-block">{{ $t("min_transfer.description1") }}
              {{ currency_code }}

              {{ Number(transfer_min_amount).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }) }}
              {{ $t("min_transfer.description2") }} </p>

            <div class="amounts-container">
              <div class="row">
                <div class="col-md-8">
                  <div class="d-flex flex-wrap gap-3">
                    <div v-for="amount in transferAmounts" :key="amount" class="form-check">
                      <input type="radio" class="form-check-input" :id="'amount-' + amount" :value="amount"
                        v-model="selectedAmount" name="transfer-amount">
                      <label class="form-check-label" :for="'amount-' + amount">
                        {{ currency_code }}

                        {{ Number(amount).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0
                        }) }}


                      </label>
                    </div>
                  </div>
                </div>
              </div>


              <div class="mt-4">
                <button class="btn btn-success text-white" @click="openConfirmModal" data-bs-toggle="modal"
                  data-bs-target="#confirmTransferModal"
                  :disabled="!selectedAmount || selectedAmount === transfer_min_amount">
                  {{ $t("button.save") }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal de confirmation -->
        <div class="modal fade" id="confirmTransferModal" tabindex="-1">
          <div class="modal-dialog">
            <div class="card">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{{ $t("min_transfer.title_modal") }}</h5>
                  <button type="button" class="btn-close" @click="closeModal" data-bs-dismiss="modal"
                    aria-label="Close"></button>
                </div>

                <div class="modal-body text-center">
                  <div class="modal-body text-center pt-4 pb-4" v-if="alert_text">
                    <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
                    <h6> {{ $t("min_transfer.title_content") }}</h6>
                    <p class="px-6">{{ $t("min_transfer.description_modal") }}
                      {{ currency_code }}

                      {{ Number(selectedAmount).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      }) }} ?



                    </p>
                  </div>

                  <div class="modal-body text-center pt-4 pb-4" v-if="!alert_text">
                    <i style="color: #f1c40f; font-size: 70px" class="bi bi-check2-circle"></i>
                    <h6> {{ $t("min_transfer.title_success") }}</h6>
                    <p class="px-6">
                      {{ $t("min_transfer.description_success") }}
                    </p>
                  </div>
                </div>

                <div class="modal-footer" style="border-top:1px solid rgba(255, 255, 255, 0.4);">
                  <argon-button v-if="alert_text" type="button" class="btn bg-transparent" @click="closeModal"
                    data-bs-dismiss="modal" aria-label="Close"
                    style="width: 150px; height: 50px; padding-top: 15px ;border:1px solid rgba(255, 255, 255, 0.4);">
                    {{ $t("button.cancel") }}
                  </argon-button>
                  <argon-button v-if="!alert_text" type="button" class="btn bg-transparent" @click="closeModal"
                    data-bs-dismiss="modal" aria-label="Close"
                    style="width: 150px; height: 50px; padding-top: 15px ;border:1px solid rgba(255, 255, 255, 0.4);">
                    {{ $t("button.close") }}
                  </argon-button>
                  <argon-button v-if="alert_text" :loading="loading" :active="buttonActive || showConfirmation"
                    :clickEvent="button" color="danger" variant="gradient" type="button" class="mt-1"
                    style="width: 150px; height: 50px; padding-top: 15px" @click="saveNewAmount">
                    <span v-if="!loading"> {{ $t("button.confirm") }}</span>
                  </argon-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Include the modals -->
      <SupportFeesModal :isVisible="isSupportFeesModalVisible" @update:isVisible="updateSupportFeesModalVisibility"
        @close="resetSupportFeesCheckbox" />
      <SmallInstallmentModal :isVisible="!isSmallInstallmentModalVisible"
        @update:isVisible="updateSmallInstallmentModalVisibility" @close="resetSmallInstallmentCheckbox"
        @save="saveNewAmount" />
    </main>
    <app-footer />

  </div>
</template>

<script>
import api_config from "@/config";
import AppFooter from "@/widget/PageLayout/Footer.vue";
import { getConfig } from "@/utils/auth";
import SupportFeesModal from '@/views/modal/SupportFeesModal.vue';
import SmallInstallmentModal from '@/views/modal/SmallInstallmentModal.vue';
import ArgonButton from "@/components/ArgonButton";
import NetworkStatus from "@/views/components/NetworkStatus.vue";



import "vue3-toastify/dist/index.css";
import { getSchoolinfo, submit_claims, uploadFile } from "@/utils/services";
import Spinner from "../components/Spinner.vue";
import { pullGrades, pullSpecialities, updateConfig } from "@/utils/setup";
import { getMembers, xpayconnection } from "@/utils/services";
import { ref, onMounted } from "vue";
const body = document.getElementsByTagName("body")[0];

export default {
  name: "app-config",
  components: {
    AppFooter,
    Spinner,
    SupportFeesModal,
    SmallInstallmentModal,
    ArgonButton,
    NetworkStatus,


  },
  data() {
    return {
      button: "",
      buttonActive: false,
      showConfirmation: false,
      schoolInfo: {},
      isOffline: false,
      apiError: false,
      isSupportFeesModalVisible: false,
      isSmallInstallmentModalVisible: true,
      transferAmounts: [],
      confirmModal: null,
      selectedAmount: null,
      transfer_min_amount: '',
      alert_text: true,
      currency_code: api_config.CURRENCY_CODE,
      configId: "",
      country_code: api_config.COUNTRY_CODE,
      training_type1: "",
      education_system1: "",
      education_grade1: "",
      education_system: "",
      school_id: "",
      test: "",
      welcome: null,
      loading: false,
      name: "",
      loacalisation: "",
      region: "",
      town: "",
      hood: "",
      owner: "",
      Min_transfer_amount: api_config.MIN_TRANSFER_AMOUNT,
      previewImage: null,
      selectedFile: null,
      schoolLogo: null,

    };
  },



  async mounted() {
    // Initialiser l'état de connexion au montage
    await this.updateOnlineStatus();
    this.fetchxpayconnection();


    // Ajouter les event listeners pour les changements de réseau
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    this.intervalId = setInterval(this.checkInternetConnection, 1000);


    // Charger les données initiales
    if (!this.isOffline) {

      this.fetchxpayconnection();
    }
  },




  setup() {
    const members = ref([]);

    const fetchMembers = async () => {
      try {
        const result = await getMembers();

        if (result.status === 200) {
          members.value = result.data;
          localStorage.setItem("members", JSON.stringify(result.data));
        } else {
          //console.error("Erreur lors de la récupération des membres :", result.error);
        }
      } catch (error) {
        //console.error("Erreur inattendue lors de la récupération des membres :", error);
      }
    };

    onMounted(() => {
      fetchMembers();
    });

    return {
      members,
    };
  },



  methods: {


    async updateOnlineStatus() {
      if (navigator.onLine) {
        try {
          await this.checkConnection();
          this.isOffline = false;
        } catch (error) {
          console.error("Error checking connection:", error);
          this.isOffline = true;
        }
      } else {
        this.isOffline = true;
      }
    },

    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;

        // Créer une prévisualisation
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
          this.schoolLogo = null; // Masquer l'ancien logo pendant la prévisualisation
        };
        reader.readAsDataURL(file);

        // Appeler directement la méthode d'upload
        this.uploadLogo(file);
      }
    },


    closeModal() {
      this.alert_text = true
    },

    async uploadLogo(file) {
      try {
        this.loading = true;

        const uploadData = {
          selectedFile: file,
          model_name: "schooling.schoolconfig",
          media_field: "logo",
          object_id: this.configId
        };

        const response = await uploadFile(uploadData);

        if (response) {
          console.log("Logo uploaded successfully");
          // Vous pouvez ajouter un message de succès ici
        }
      } catch (error) {
        console.error("Error uploading logo:", error);
        // Vous pouvez ajouter un message d'erreur ici
      } finally {
        this.loading = false;
      }
    },

    async checkConnection(sf_school_id) {
      try {
        // Essayer les deux appels API
        await Promise.all([
          xpayconnection(sf_school_id)

        ]);
        this.apiError = false; // Les API répondent correctement
      } catch (error) {
        console.error("API Error:", error);
        this.apiError = true; // Les API ne répondent pas
      }
    },


    async fetchxpayconnection() {
      this.loading = true;
      const sf_school_id = localStorage.getItem("schoolId")

      try {
        this.loading = true;
        let response = await xpayconnection(sf_school_id);


        if (response && response.length > 0) {
          const connection = response[0];
          this.xpay_connection_id = connection.id;
          this.bank_slug = connection.bank_name;
          this.transfer_min_amount = connection.transfer_min_amount;
          this.transaction_number = connection.school_account_number;
          this.selectedAmount = connection.transfer_min_amount;

          if (connection.data && connection.data.min_transfer_options) {
            this.transferAmounts = connection.data.min_transfer_options;
          }


          this.bank_logo = connection.bank_logo
          this.status_code = connection.status_code;

          localStorage.setItem('xpay_connection_id', this.xpay_connection_id);

          if (this.xpay_connection_id) {
            await this.fetchTransfers();
            await this.fetchXpconnection();
          }

        } else {
          console.error("Invalid response data or empty array:", response);
        }
      } catch (error) {
        console.error("Error during getconnection call:", error);
      } finally {
        this.loading = false;
      }
    },

    async saveNewAmount() {
      this.showValidation = true;
      if (this.loading) return;

      this.loading = true;
      this.button = "disabled";
      this.buttonActive = true;
      try {
        const response = await submit_claims(this.Min_transfer_amoun, this.xpay_connection_id);

        if (response && response.status === 200) {

          if (!this.showConfirmation) {
            this.showConfirmation = true;
            this.alert_text = false

          }

        }
      } catch (error) {
        console.error("Erreur lors de la soumission de la demande:", error);


      } finally {
        this.loading = false;
        this.button = "";
        this.buttonActive = false;
        this.showConfirmation = false;
      }
    },




    updateSupportFeesModalVisibility(isVisible) {
      this.isSupportFeesModalVisible = !isVisible;
    },
    resetSupportFeesCheckbox() {
      this.isSupportFeesModalVisible = false;
    },
    updateSmallInstallmentModalVisibility(isVisible) {
      this.isSmallInstallmentModalVisible = isVisible;
    },
    resetSmallInstallmentCheckbox() {
      this.isSmallInstallmentModalVisible = true;
    },




    updateSchoolId(id) {
      this.school_id = id;
      localStorage.setItem("schoolId", id);
    },


    async getAppConfig() {
      try {
        this.loading = true;
        let data = await getConfig();

        setTimeout(() => {
          if (!navigator.onLine) {
            alert(
              "Vous n'êtes pas connecté à Internet. Veuillez vérifier votre connexion et réessayer."
            );
          }
        }, 30000);
        this.configId = data[0].id;



        if (!this.training_type1) {
          this.training_type1 = data.training_type;
        }
        if (!this.education_system1) {
          this.education_system1 = data.education_system;
        }
        if (!this.education_grade1) {
          this.education_grade1 = data.education_grade;
        }
        if (!this.school_id) {
          this.school_id = data[0].school_id;
          localStorage.setItem("schoolId", this.school_id);

        }
        if (data[0].logo) {
          this.schoolLogo = data[0].logo;
        }

        if (!this.currency_code) {
          this.currency_code = data[0].currency_code;
        }




        // Récupérez school_id

        // Appelez getSchoolInfo avec school_id
        await this.getSchoolInfo(this.school_id);

        // Prendre en compte les valeurs renvoyées par getSchoolInfo
        if (this.schoolInfo.length > 0) {
          if (!this.education_grade1) {
            this.education_grade1 = this.schoolInfo[0].education_grade;
          }
          if (!this.education_system1) {
            this.education_system1 = this.schoolInfo[0].education_system;
          }
          if (!this.training_type1) {
            this.training_type1 = this.schoolInfo[0].training_type;
          }

          if (!this.name) {
            this.name = this.schoolInfo[0].name;
          }
          if (!this.region) {
            this.region = this.schoolInfo[0].region;
          }
          if (!this.town) {
            this.town = this.schoolInfo[0].town;
          }
          if (!this.hood) {
            this.hood = this.schoolInfo[0].hood;
          }
          if (!this.owner) {
            this.owner = this.schoolInfo[0].owner;
          }
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de la configuration :",
          error
        );
      } finally {
        this.loading = false;
      }



    },

    watch: {
      
      training_type1(value) {
        this.training_type = value;
      },
      education_system1(value) {
        this.education_system = value;
      },
      education_grade1(value) {
        this.education_grade = value;
      },
    },

    async getSchoolInfo(schoolId) {
      try {
        const response = await getSchoolinfo(schoolId);

        if (response.status === 200) {
          this.schoolInfo = response.data;

        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des informations de l'école :",
          error
        );
      }
    },

    async saveConfig() {

      this.loading = true;
      this.button = "disabled";


      let data = {


        is_verified: 1,
      };


      let grade = await pullGrades();

      if (grade.status !== 200) {
        this.button = "";
        return;
      }

      let specialities = await pullSpecialities();
      if (specialities.status !== 200) {
        this.button = "";
        return;
      }

      let configId = this.configId;
      let config = await updateConfig(configId, data);

      if (config.status !== 200) {
        this.button = "";
        return;
      }



      if (this.schoolInfo.length > 0) {
        if (!this.education_grade1) {
          this.education_grade1 = this.schoolInfo[0].education_grade;
        }
        if (!this.education_system1) {
          this.education_system1 = this.schoolInfo[0].education_system;
        }
        if (!this.training_type1) {
          this.training_type1 = this.schoolInfo[0].training_type;
        }

        if (!this.name) {
          this.name = this.schoolInfo[0].name;
        }
        if (!this.region) {
          this.region = this.schoolInfo[0].region;
        }
        if (!this.town) {
          this.town = this.schoolInfo[0].town;
        }
        if (!this.hood) {
          this.hood = this.schoolInfo[0].hood;
        }
        if (!this.owner) {
          this.owner = this.schoolInfo[0].owner;
        }
      }

      // type  de paiment 
      const payment_type = this.owner === 'Private' ? 'SchoolFees' : 'govFees';
      localStorage.setItem('payment_type', payment_type);
      // Stockage des données dans le localStorage
      localStorage.setItem("training_type", this.training_type1);
      localStorage.setItem("education_grade", this.education_grade1);
      localStorage.setItem("education_system", this.education_system1);
      localStorage.setItem("name", this.name);
      localStorage.setItem("region", this.region);
      localStorage.setItem("town", this.town);
      localStorage.setItem("hood", this.hood);
      localStorage.setItem("owner", this.owner);
      localStorage.setItem("schoolId", this.school_id);




      this.$router.push("/");
      setTimeout(() => {
        this.button = "";
      }, 1000);
    },

  },
  created() {
    this.getAppConfig();
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },



  computed: {


    hasAdmin() {
      return this.members.some(member => member.is_staff);
    }
  },


  beforeUnmount() {
    this.getAppConfig();
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
};
</script>

<style scoped>
.heart-animation {
  text-align: center;
  padding: 10px 25px;
  color: #fff;
  border: 2px solid white;
  box-shadow: 0 0 0 0 rgb(230, 235, 232);
  animation: battement 2s infinite cubic-bezier(0.66, 0, 0, 1);
  text-decoration: none;
  transition: 0.7s;
  cursor: pointer;
}

.heart-animation i {
  font-size: 30px;
  font-weight: bold;

}

.heart-animation:hover {
  text-align: center;


  color: #fff;

  border: 2px solid rgb(7, 156, 40);
  box-shadow: 0 0 0 0 rgb(28, 226, 107);
  animation: battement 2s infinite cubic-bezier(0.66, 0, 0, 1);
  text-decoration: none;

}

@keyframes battement {
  to {
    box-shadow: 0 0 0 30px rgba(255, 71, 84, 0);
  }
}

.horizontal {
  background-image: linear-gradient(to right,
      rgba(255, 255, 255, 0),
      white,
      rgba(255, 255, 255, 0)) !important;
  transform: translateX(-40px);
}

.link {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  color: red;
}

.amount-btn {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: white;
  padding: 8px 16px;
  transition: all 0.3s ease;
}

.amount-btn:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.6);
  color: white;
}

.amount-btn.active {
  background: #4f61c4;
  border-color: #4f61c4;
  color: white;
}

.modal-content {
  background: #111c44;
  color: white;
}

.modal-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.gap-3 {
  gap: 1rem;
}


.sweet-popup {
  background: #111c44 !important;
  color: white !important;
}

.swal2-popup {
  background: #111c44 !important;
}

.swal2-title,
.swal2-content {
  color: white !important;
}

.swal2-success-circular-line-left,
.swal2-success-circular-line-right,
.swal2-success-fix {
  background-color: #111c44 !important;
}

.upload-container {
  width: 220px;
  height: 220px;
  position: relative;
}

.file-input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}








.upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px dashed #41b883;
  background-color: #1a2035;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
  /* Pour s'assurer que l'image reste dans le cercle */
  position: relative;
  /* Pour le positionnement du texte */
}

.upload-label:hover {
  background-color: #2a3045;
}

.upload-label:hover .upload-text {
  opacity: 1;
  transform: translateY(0);
}

.upload-icon {
  font-size: 67px;
  color: #41b883;
  margin-bottom: 10px;
}

.upload-text {
  color: #ffffff;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 4px;
  position: absolute;
  bottom: 10px;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
</style>
