<template>
  <div class="offline-card">
    <div class="offline-content   ">
      <img class="offline-icon" src="@/assets/img/wifi.png" alt="Icône hors-ligne">
      <div class="offline-message">
        <h6>Vous êtes hors ligne.</h6>
        <p>Veuillez vérifier votre connexion Internet.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NetworkStatus"
};
</script>

<style scoped>
.offline-card {
  height: max-content;
  width: max-content;
  align-items: center;
  justify-content: center;
  background: var(--bs-card-bg);
  margin-top: 15%;
  margin-left: 30%;
   border: 1px solid rgba(250, 250, 250, 0.1);
  border-radius: 5px;
  
 
}

.offline-content {
  text-align: center;
  padding: 2rem;
  margin-left: auto;
}

.offline-icon {
  width: 120px;
  margin-bottom: 2rem;
}

.offline-message {
  h6 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
  p {
    color: var(--bs-text-muted);
  }
}

@media (max-width: 768px) {
  .offline-icon {
    width: 80px;
  }
}
</style>
