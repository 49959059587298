<template>
    <div class="modal fade" :class="{ show: isVisible }" :style="{ display: isVisible ? 'block' : 'none' }"
        tabindex="-1" aria-labelledby="supportFeesModalLabel2" aria-modal="true" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content" style="background: #111c44;">
                <div class="modal-header" style="border-bottom:1px solid rgba(250, 250, 250, 0.6);">
                    <h6 class="modal-title" id="supportFeesModalLabel"> </h6>
                    <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
                </div>
                <div class="modal-body text-center">
                    <div class="modal-body text-center pt-4 pb-4" v-if="alert_text">
                        <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
                        <h6> {{ $t("fees_modal.title1") }} </h6>
                        <p class=" px-6">{{ $t("fees_modal.content1")}}
                            
                        </p>
                    </div>
                    <div class="modal-body text-center pt-4 pb-4" v-if="!alert_text">
                        <i style="color: #f1c40f; font-size: 70px" class="bi bi-check2-circle"></i>
                        <h6> {{ $t("fees_modal.title2") }} </h6>
                        <p class="px-6">
                            {{ $t("fees_modal.content2")}}
                        </p>
                    </div>
                </div>
                <div class="modal-footer" style="border-top:1px solid rgba(255, 255, 255, 0.4);">
                    <argon-button v-if="alert_text" type="button" class="btn bg-transparent" @click="closeModal"
                        aria-label="Close"
                        style="width: 150px; height: 50px; padding-top: 15px ;border:1px solid rgba(255, 255, 255, 0.4);">
                        {{ $t("button.cancel") }}
                    </argon-button>
                    <argon-button v-if="!alert_text" type="button" class="btn bg-transparent" @click="closeModal"
                        aria-label="Close"
                        style="width: 150px; height: 50px; padding-top: 15px ;border:1px solid rgba(255, 255, 255, 0.4);">
                        {{ $t("button.close") }}
                    </argon-button>
                    <argon-button v-if="alert_text" :loading="loading" :active="buttonActive || showConfirmation"
                        :clickEvent="button" color="danger" variant="gradient" type="button" class="mt-1"
                        style="width: 150px; height: 50px; padding-top: 15px" @click="saveNewAmount">

                        <span v-if="!loading"> {{ $t("button.confirm") }}</span>
                    </argon-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api_config from "@/config";
import ArgonButton from "@/components/ArgonButton";
import { submit_claims } from "@/utils/services";

export default {
    name: 'SupportFeesModal',
    data() {
        return {
            loading: false,
            buttonActive: false,
            showConfirmation: false,
            xpay_connection_id: localStorage.getItem('xpay_connection_id'),
            alert_text: true,
            message_success: false,
            showValidation: false,
            Fees_on_payer:api_config.FEES_ON_PAYER,
        }
    },
    props: ['isVisible'],
    components: {
        ArgonButton
    },
    methods: {
        hideModal() {
            this.$emit('update:isVisible', false);
        },
        closeModal() {
            this.$emit('update:isVisible', false);
            this.$emit('close');
            this.alert_text = true
        },

        async saveNewAmount() {

            this.showValidation = true;
            if (this.loading) return;
            this.loading = true;
            this.button = "disabled";
            this.buttonActive = true;
            try {
                const response = await submit_claims(this.Fees_on_payer, this.xpay_connection_id);

                if (response && response.status === 200) {
                    if (!this.showConfirmation) {
                        this.showConfirmation = true;
                        this.alert_text = false

                    }
                }
            } catch (error) {
                console.error("Erreur lors de la soumission de la demande:", error);


            } finally {
                this.loading = false;
                this.button = "";
                this.buttonActive = false;
                this.showConfirmation = false;
            }
        },
    },
};
</script>

<style scoped>
.modal.show {
    display: block;
}
</style>