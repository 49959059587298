<template>
    <div class="w-80 px-7 pb-5 mx-auto">
        <div class="content text-center">
            <i class="fas fs-1 fa-exclamation-triangle text-warning mb-2" style="font-size: 2rem;"></i>
            <h5 class="modal-title mt-4 mb-3">{{ $t("transfer.modal_bank_title") }}</h5>
            <p>{{ $t("transfer.modal_bank_body") }}</p>
            <drop-zone @change="selectedFile" @drop.prevent="drop"></drop-zone>
            <p v-if="errorMessage" class="text-danger mt-2">{{ errorMessage }}</p>
        </div>

        <!-- Modal de prévisualisation -->
        <div class="modal" v-if="showPreviewModal" tabindex="-1" style="display: block; background: rgba(0,0,0,0.5);">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="card">
                        <div class="modal-header" style="border-bottom: 1px solid rgba(250,250,250,0.5);">
                            <h6 class="modal-title">{{ $t("transfer.no_bank_docs_modal")}} </h6>
                                  
                            <button type="button" class="btn-close" @click="closePreviewModal"></button>
                        </div>
                        <div class="modal-body">
                            <!-- Prévisualisation PDF -->
                            <embed v-if="selectedFileType === 'application/pdf'" :src="previewUrl"
                                type="application/pdf" width="100%" height="500px" />

                            <!-- Prévisualisation Image -->
                            <img v-if="selectedFileType.includes('image')" :src="previewUrl" class="img-fluid"
                                alt="Prévisualisation" />
                        </div>
                        <div class="modal-footer" style="border-top: 1px solid rgba(250,250,250,0.5);">
                            <button type="button" class="btn text-white" style="border: 1px solid rgba(250,250,250,0.5);" @click="closePreviewModal">{{$t("button.cancel")}}</button>
                            <button type="button" class="btn btn-success" @click="confirmUpload">{{ $t("button.upload") }} </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DropZone from "@/components/DropZone";
import { uploadFile } from "@/utils/services";

export default {
    name: 'Bank-Doc',
    data() {
        return {
            school_name: "",
            xpay_connection_id: localStorage.getItem('xpay_connection_id'),
            errorMessage: "",
            allowedTypes: [
                'application/pdf',
                'image/jpeg',
                'image/jpg',
                'image/png'
            ],
            fileURL: null,
            isValidFile: false,
            successMessage: "",
            showPreviewModal: false,
            previewUrl: null,
            selectedFileType: "",
            selectedFileData: null
        }
    },

    components: {
        DropZone
    },

    props: {
        amount: {
            type: Boolean,
            required: true
        },
        installmentNumber: {
            type: Boolean,
            required: true
        },
        due_date: {
            type: Boolean,
            required: true
        },
        close1: {
            type: Function,
            required: true,
        },
        sendinvoice: {
            type: Function,
            required: true,
        },
        refreshComponentData: {
            type: Function,
            required: true,
        }
    },

    methods: {
        selectedFile(event) {
            const file = event.target.files[0];
            if (file) {
                this.validateAndProcessFile(file);
            }
        },

        drop(event) {
            event.preventDefault();
            const files = event.dataTransfer.files;
            if (files && files.length > 0) {
                this.validateAndProcessFile(files[0]);
            }
        },

        async validateAndProcessFile(file) {
            if (!file) return;

            this.errorMessage = "";
            this.successMessage = "";

            if (!this.allowedTypes.includes(file.type)) {
                this.errorMessage = "Seuls les fichiers PDF et images (JPG, JPEG, PNG) sont acceptés";
                return;
            }

            const maxSize = 5 * 1024 * 1024; // 5MB en octets
            if (file.size > maxSize) {
                this.errorMessage = "Le fichier est trop volumineux. La taille maximale est de 5MB";
                return;
            }

            // Créer l'URL de prévisualisation
            this.previewUrl = URL.createObjectURL(file);
            this.selectedFileType = file.type;
            this.selectedFileData = file;
            this.showPreviewModal = true;
        },

        closePreviewModal() {
        // Réinitialisation complète de l'upload
        this.showPreviewModal = false;
        this.previewUrl = null;
        this.selectedFileData = null;
        this.selectedFileType = "";
        this.fileURL = null;
        this.errorMessage = "";
        this.successMessage = "";
        this.isValidFile = false;
        
        // Révoquer l'URL de prévisualisation pour libérer la mémoire
        if (this.previewUrl) {
            URL.revokeObjectURL(this.previewUrl);
        }
    },
        async confirmUpload() {
            try {
                let res = await uploadFile(this.selectedFileData);
                this.fileURL = res.path;
                this.successMessage = "Le fichier a été téléchargé avec succès!";

                if (this.isValidFile) {
                    this.scrollToButtons();
                }

                if (this.sendinvoice) {
                    this.sendinvoice(this.selectedFileData);
                }

                this.closePreviewModal();
            } catch (error) {
                this.errorMessage = "Une erreur s'est produite lors du téléchargement du fichier";
            }
        }
    },

    beforeUnmount() {
        // Nettoyer les URLs de prévisualisation
        if (this.previewUrl) {
            URL.revokeObjectURL(this.previewUrl);
        }
    }
}
</script>

<style scoped>
.w-80 {
    width: 80%;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
}

.modal-dialog {
    margin: 1.75rem auto;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}
</style>