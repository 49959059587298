<template>
  <NetworkStatus v-if="isOffline" />
  <div v-else> 
    <div class="container-fluid" style="margin-top:80px;">
    <!-- Modal -->
    <div class="modal fade" id="exampleModal40" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="card">
            <div class="modal-header" style="border-bottom: 1px solid rgba(250,250, 250, 0.4);">
              <h5 class="modal-title" id="exampleModalLabel">{{ $t('note.api_key') }}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="api-key mt-5 mb-5 d-flex align-items-center mx-auto" style="width: max-content;">
                <p class="mx-2 py-2 px-3" style="border: 1px solid rgba(250, 250, 250, 0.6);">{{ apiKey }}</p>
                <button @click="copyToClipboard" class="btn" style="background: #75acff; height: 45px; color: white; border-radius: 0;">
                  {{ $t('button.copy') }}
                </button>
              </div>
            </div>
            <div class="modal-footer" style="border-top: 1px solid rgba(250,250, 250, 0.5);">
              <argon-button type="button" class="btn" color="danger" variant="gradient" data-bs-dismiss="modal" style="width: 150px; height: 50px; padding-top: 15px">
                {{ $t("button.close") }}
              </argon-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- API Card -->
    <div class="card-api mt-4 mb-6 px-3" style="border: 1px solid rgba(250,250, 250, 0.5); border-radius: 5px; background: #111c44;">
      <div class="card-api-content d-flex justify-content-between">
        <div class="d-flex mt-3 pt-1">
          <div class="key pe-2">
            <i style="color: #75acff" class="fs-4 mt-3 fa fa-key"></i>
          </div>
          <div class="text mx-3">
            <h6 style="font-size: 14px;">{{ $t('note.access_grades_api') }}</h6>
            <p style="font-size: 14px;">{{ $t('note.api_description') }}</p>
            <p style="font-size: 14px; margin-top: -10px;">
              <a style="color:#4f61c4 !important;" target="_blank" href="#">{{ $t('note.see_documentation') }}</a>
            </p>
          </div>
        </div>
        <div class="button" style="align-items: center">
          <argon-button data-bs-toggle="modal" data-bs-target="#exampleModal40" color="success" size="xl" style="background: #75acff; width: 150px; margin-top: 20px;">
            {{ $t('note.show_key') }}
          </argon-button>
        </div>
      </div>
    </div>

    <!-- Filters -->
    <div class="row">
      <div class="filters">
        <div class="dropdown">
          <button class="dropdown-toggle" type="button" id="dropdownMenuButton3" data-bs-toggle="dropdown" aria-expanded="false">
            {{ selectedSequence ? $t('note.sequence_number', { number: selectedSequence }) : $t('note.sequences') }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
            <li v-for="sequence in uniqueSequences" :key="sequence" @click="selectSequence(sequence)">
              <a class="dropdown-item" href="#">{{ $t('note.sequence_number', { number: sequence }) }}</a>
            </li>
          </ul>
        </div>

        <div class="dropdown">
          <button class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            {{ selectedClass ? selectedClass : $t('note.classes') }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li v-for="grade in uniqueGradesWithClassroom" :key="grade.gradeSpeciality" @click="selectClass(grade.gradeSpeciality)">
              <a class="dropdown-item" href="#">{{ grade.gradeSpeciality }} {{ grade.classroom_number }}</a>
            </li>
          </ul>
        </div>

        <div v-if="selectedGradeSpeciality" class="dropdown mx-3">
          <button class="dropdown-toggle" type="button" id="dropdownMenuButton4" data-bs-toggle="dropdown" aria-expanded="false">
            {{ selectedClassNumber ? `${selectedGradeSpeciality} ${selectedClassNumber}` : $t('note.class') }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton4">
            <li v-for="number in availableClassNumbers" :key="number" @click="selectClassNumber(number)">
              <a class="dropdown-item" href="#">{{ selectedGradeSpeciality }} {{ number }}</a>
            </li>
          </ul>
        </div>

        <div class="dropdown">
          <button class="dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
            {{ selectedSubject || $t('note.subjects') }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
            <li @click="selectSubject('')">
              <a class="dropdown-item" href="#">{{ $t('note.all_subjects') }}</a>
            </li>
            <li v-for="subject in uniqueSubjects" :key="subject" @click="selectSubject(subject)">
              <a class="dropdown-item" href="#">{{ subject }}</a>
            </li>
          </ul>
        </div>

        <button :title="$t('note.export_excel')" type="button" class="btn text-light" @click="exportToExcel" style="height: 45px; margin-top: -10px; border-radius: 5px; background: #75acff;">
          <i class="fas fa-download"></i>
        </button>
      </div>
    </div>

    <!-- Message to select filters -->
    <div class="text-center mt-5 mb-5" v-if="!selectedSequence || !selectedClass">
      <div class="container">
        <div class="text mt-3">
          <img class="image1 w-30" src="@/assets/img/note.png" alt="" />
          <h5 class="mt-4">{{ $t('note.select_filters') }}</h5>
          <p>{{ $t('note.please_select_sequence_and_class') }}</p>
        </div>
      </div>
    </div>

    <div class="student_number" v-else>
      <p>{{ $t('note.student_count', { count: filteredGrades.length }) }}</p>
    </div>

    <Spinner class="text-center mt-8 mb-8" v-if="loading && selectedSequence && selectedClass" style="height: 200px">
      <div>
        <span class="mt-4">
          <Spinner></Spinner>
        </span>
      </div>
    </Spinner>

    <div class="table-responsive" v-else-if="selectedSequence && selectedClass && groupedStudents.length">
      <table class="table table-bordered table-striped text-center">
        <thead class="table-dark">
          <tr>
            <th class="p-0 w-0">
              <div class="diagonal-div">
                <div class="triangle top text-start ps-4 text-white">
                  <span style="position: absolute; bottom: 10px; left: 20px;">{{ $t('note.names') }}</span>
                </div>
                <div class="triangle bottom text-end pe-3">
                  <span style="position: absolute; top: 15px; right: 20px;">{{ $t('note.subjects') }}</span>
                </div>
              </div>
            </th>
            <th v-for="subject in displayedSubjects" :key="subject" :title="grades.find(g => g.subject_code === subject)?.student?.subject_name">
              {{ subject }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="student in groupedStudents" :key="student.id">
            <th class="name" :title="`${student.name.last_name} ${student.name.first_name}`">
              <span class="text-white" style="font-size: 14px;">{{ student.name.last_name.slice(0, 16) }}{{ student.name.last_name.length > 16 ? '...' : '' }}</span><br>
              <span class="text-white" style="font-size: 14px;">{{ student.name.first_name.slice(0, 16) }}{{ student.name.first_name.length > 16 ? '...' : '' }}</span>
            </th>
            <td v-for="subject in displayedSubjects" :key="subject">
              {{ getGrade(student, subject) }}
            </td>
          </tr>
          <tr style="border:1px solid rgba(250,250, 250, 0.4)"></tr>
        </tbody>
      </table>
    </div>

    <div class="else text-center pb-5" v-else-if="selectedSequence && selectedClass && !groupedStudents.length">
      <div class="container">
        <div class="text mt-3">
          <img class="image1 w-30" src="@/assets/img/note.png" alt="" />
          <h5 class="mt-4">{{ $t('note.no_grades') }}</h5>
        </div>
      </div>
    </div>
  </div>
  </div> 
</template>



<script>
import ArgonButton from "@/components/ArgonButton";
import { getClassroomexamsgrade } from "@/utils/services";
import Spinner from "../components/Spinner.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import * as XLSX from 'xlsx';
import api_config from "@/config";
import NetworkStatus from "@/views/components/NetworkStatus.vue";

export default {
  name: "Note_Vue",
  components: {
    ArgonButton,
    Spinner,
    NetworkStatus
  },
  data() {
    return {
      searchQuery: '',
      selectedClass: '',
      selectedSubject: '',
      selectedSequence: '',
      loading: false,
      grades: [],
      apiKey: api_config.API_ENDPOINT + "/api/exam-grades/",
      selectedGradeSpeciality: '',
      selectedClassNumber: '',
      isOffline: false,  
      apiError: false, 
    };
  },

  async mounted() {
    // Initialiser l'état de connexion au montage
    await this.updateOnlineStatus();
    this.fetchExamsgrade();

    // Ajouter les event listeners pour les changements de réseau
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    this.intervalId = setInterval(this.checkInternetConnection, 1000);
    
    // Charger les données initiales
    if (!this.isOffline) {
      await this.fetchExamsgrade();
    }
  },

  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

  computed: {
    uniqueGrades() {
      // Combiner grade_code et speciality_code
      return [...new Set(this.grades.map(grade =>
        `${grade.student.grade_code} ${grade.student.speciality_code}`
      ))];
    },
    uniqueGradeSpecialities() {
      // Retourne uniquement les combinaisons grade_code + speciality_code
      return [...new Set(this.grades.map(grade =>
        `${grade.student.grade_code} ${grade.student.speciality_code}`
      ))];
    },

    availableClassNumbers() {
      // Retourne les classroom_number disponibles pour la classe sélectionnée
      if (!this.selectedGradeSpeciality) return [];

      return [...new Set(this.grades
        .filter(grade =>
          `${grade.student.grade_code} ${grade.student.speciality_code}` === this.selectedGradeSpeciality
        )
        .map(grade => grade.student.classroom_number)
        .filter(number => number) // Filtre les valeurs null/undefined
      )];
    },

    displayedSubjects() {
      // Si une matière est sélectionnée, ne retourner que celle-là
      if (this.selectedSubject) {
        return [this.selectedSubject];
      }
      // Sinon retourner toutes les matières
      return this.uniqueSubjects;
    },
    filteredGrades() {
      return this.grades.filter(grade => {
        const gradeSpecialityMatch = !this.selectedGradeSpeciality ||
          `${grade.student.grade_code} ${grade.student.speciality_code}` === this.selectedGradeSpeciality;

        const classNumberMatch = !this.selectedClassNumber ||
          grade.student.classroom_number === this.selectedClassNumber;

        const subjectMatch = !this.selectedSubject ||
          grade.subject_code === this.selectedSubject;

        const sequenceMatch = !this.selectedSequence ||
          grade.session === Number(this.selectedSequence);

        return gradeSpecialityMatch && classNumberMatch && subjectMatch && sequenceMatch;
      });
    },
    uniqueSubjects() {
      return [...new Set(this.grades.map(grade => grade.subject_code))];
    },
    uniqueSequences() {
      return [...new Set(this.grades.map(grade => grade.session))];
    },
    uniqueGradesWithClassroom() {
      const gradesMap = new Map();

      this.grades.forEach(grade => {
        const gradeSpeciality = `${grade.student.grade_code} ${grade.student.speciality_code}`;

        if (!gradesMap.has(gradeSpeciality)) {
          gradesMap.set(gradeSpeciality, {
            gradeSpeciality,
            classroom_number: grade.student.classroom_number || ''
          });
        }
      });

      return Array.from(gradesMap.values());
    },

    // Modified to return all students without pagination
    groupedStudents() {
      const studentMap = new Map();

      // Appliquer d'abord les filtres
      this.filteredGrades.forEach(grade => {
        if (!studentMap.has(grade.student_id)) {
          studentMap.set(grade.student_id, {
            id: grade.student_id,
            name: {
              first_name: grade.student.first_name,
              last_name: grade.student.last_name
            },
            grade_code: `${grade.student.grade_code} ${grade.student.speciality_code}`,
            grades: new Map()
          });
        }

        const student = studentMap.get(grade.student_id);
        if (!this.selectedSequence || grade.session === Number(this.selectedSequence)) {
          student.grades.set(grade.subject_code, grade.value);
        }
      });

      return Array.from(studentMap.values());
    }
  },
  methods: {
    async updateOnlineStatus() {
      // Vérifier si le navigateur est en ligne
      if (navigator.onLine) {
        try {
          // Vérifier si les API sont accessibles
          await this.checkConnection();
          this.isOffline = false; // Tout fonctionne, on est en ligne
        } catch (error) {
          console.error("Error checking connection:", error);
          this.isOffline = true; // Les API ne répondent pas, on est hors ligne
        }
      } else {
        // Pas de connexion internet détectée
        this.isOffline = true;
      }
    },

    async checkConnection() {
      try {
        // Essayer les deux appels API
        await Promise.all([
          getClassroomexamsgrade()
        ]);
        this.apiError = false; // Les API répondent correctement
      } catch (error) {
        console.error("API Error:", error);
        this.apiError = true; // Les API ne répondent pas
      }
    },
    
    exportToExcel() {
      try {
        // Vérifier si une séquence et une classe sont sélectionnées
        if (!this.selectedSequence || !this.selectedClass) {
          toast.warning(this.$t('note.please_select_sequence_and_class'), {
            autoClose: 3000,
          });
          return;
        }
        
        // Créer les données pour l'export
        const exportData = this.groupedStudents.map(student => {
          const row = {
            'Nom': student.name.last_name,
            'Prénom': student.name.first_name,
            'Classe': student.grade_code,
          };

          // Ajouter les notes pour chaque matière
          this.displayedSubjects.forEach(subject => {
            row[subject] = this.getGrade(student, subject);
          });

          return row;
        });

        // Créer un nom de fichier avec les filtres actifs
        let fileName = 'Notes';
        if (this.selectedSequence) fileName += `_Sequence${this.selectedSequence}`;
        if (this.selectedClass) fileName += `_${this.selectedClass}`;
        if (this.selectedClassNumber) fileName += `_${this.selectedClassNumber}`;
        if (this.selectedSubject) fileName += `_${this.selectedSubject}`;
        fileName += '.xlsx';

        // Créer le workbook et la worksheet
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Notes');

        // Générer le fichier Excel
        XLSX.writeFile(wb, fileName);

        // Afficher un message de succès
        toast.success('Export Excel réussi!', {
          autoClose: 3000,
        });
      } catch (error) {
        console.error('Erreur lors de l\'export Excel:', error);
        toast.error('Erreur lors de l\'export Excel', {
          autoClose: 3000,
        });
      }
    },

    selectClass(grade) {
      this.selectedClass = grade;
      this.selectedGradeSpeciality = grade;
      this.selectedClassNumber = ''; // Reset the class number when changing grade
    },

    selectGradeSpeciality(grade) {
      this.selectedGradeSpeciality = grade;
      this.selectedClassNumber = ''; // Réinitialiser le numéro de classe lors du changement de classe
    },

    selectClassNumber(number) {
      this.selectedClassNumber = number;
    },

    selectSubject(subject) {
      this.selectedSubject = subject;
    },
    
    selectSequence(sequence) {
      this.selectedSequence = sequence;
    },
    
    getGrade(student, subject) {
      return student.grades.get(subject) || 'N/A';
    },
    
    copyToClipboard() {
      navigator.clipboard.writeText(this.apiKey).then(() => {
        alert('Clé API copiée dans le presse-papiers !');
      }).catch(err => {
        alert('Erreur lors de la copie de la clé API : ' + err);
      });
    },

    async fetchExamsgrade() {
      this.loading = true;
      try {
        const response = await getClassroomexamsgrade();
         
        if (response && response.data) {
          this.grades = response.data;
        } else {
          console.error('Format de réponse invalide:', response);
          this.grades = [];
          toast.error(this.$t('synchronisation.message_error_format'), {
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.error('Error fetching exam grades:', error);
        this.grades = [];
        toast.error(this.$t('synchronisation.message_error_get'), {
          autoClose: 3000,
        });
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
    this.fetchExamsgrade();
  }
};
</script>


<style scoped>
.filters {
  display: flex;
  margin-bottom: 40px;
}

.filters .dropdown .dropdown-toggle {
  width: 150px;
  color: white;
  margin-top: -40px;
}

.pagination {
  margin-top: 20px;
}

.pagination .page-item .page-link {
  border: 1px solid rgba(250, 250, 250, 0.4);
  margin: 0 5px;
  border-radius: 5px;
}

.pagination .page-item.active .page-link {
  border-color: #75acff;
  color: white;
}

.pagination .page-item .page-link:hover {
  background-color: rgba(117, 172, 255, 0.2) !important;
}

.pagination .page-item.disabled .page-link {
  background: none;
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}

.dropdown-menu .dropdown-item {
  height: 45px;
  color: white;
  border-bottom: 1px solid rgba(250, 250, 250, 0.6);
  padding-left: 10px;
  padding-top: 10px;
  transition: 0.4s;
}

.dropdown-menu .dropdown-item:hover {
  color: black;
  font-weight: bold;
}

.dropdown {
  margin-right: 10px;
}

.dropdown-toggle {
  background-color: transparent;
  border: 1px solid rgba(250, 250, 250, 0.6);
  color: #333;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-menu {
  border-radius: 4px;
  width: 100%;
  max-width: 200px;
}

.dropdown-item {
  cursor: pointer;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.search-bar input {
  background-color: transparent;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  margin-right: 5px;
}

.search-bar i {
  cursor: pointer;
}

.table-responsive {
  margin: 20px 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  overflow-y: hidden;
}

.table {
  margin-bottom: 0;
  width: 100%;
}

.table th,
.table td {
  padding: 12px 15px;
  vertical-align: middle;
}

.table thead th {
  color: white;
  font-weight: 500;
  border: 1px solid rgba(250, 250, 250, 0.1);
}

.table tbody tr:nth-of-type(even) {
  background-color: rgba(17, 28, 68, 0.05);
}

.table tbody tr:hover {
  background-color: rgba(117, 172, 255, 0.1);
}

.table td {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.card {
  background: transparent;
  width: 98%;
  border-radius: 8px;
  margin-top: 20px;
}

/* Style pour assurer que la table reste lisible sur mobile */
@media (max-width: 768px) {
  .table-responsive {
    overflow-x: auto;
  }

  .table th,
  .table td {
    white-space: nowrap;
    min-width: 100px;
  }

  .diagonal-div {
    width: 150px;
    height: 50px;
  }
}

.name {
  border: 1px solid rgba(250, 250, 250, 0.6);
  text-align: left;
  color: white !important;
}

.diagonal-div {
  position: relative;
  width: 200px;
  height: 70px;
  overflow: hidden;
}

.triangle {
  position: absolute;
  width: 100%;
  height: 100%;
}

.top {
  clip-path: polygon(0 0, 64% 63%, 100% 100%, 0 100%);
  background-color: #051139;
}

.bottom {
  background-color: #344767;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 59% 58%);
}
.btn-export {
  transition: all 0.3s ease;
}

.btn-export:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(117, 172, 255, 0.2);
}

.btn-export:active {
  transform: translateY(0);
}
</style>
