<template>
    <div>
      <div class="avatar-container mx-auto" @click="toggleZoom">
        <img 
          :src="photoUrl || defaultPhoto" 
          class="avatar-img"
          alt="Photo de profil"
        >
      </div>
      
      <!-- Modal de zoom -->
      <div v-if="isZoomed" class="zoom-modal" @click="toggleZoom">
        <div class="zoom-content" @click.stop>
          <img 
            :src="photoUrl || defaultPhoto" 
            class="zoomed-img"
            alt="Photo de profil agrandie"
          >
          <button class="close-btn" @click="toggleZoom">&times;</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ZoomableAvatar',
    props: {
      photoUrl: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isZoomed: false,
        defaultPhoto: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBld3h7GuDkpTKb35HgCIgr-N7UXvSDJqbRO_RnDM&s'
      }
    },
    methods: {
      toggleZoom() {
        this.isZoomed = !this.isZoomed;
        if (this.isZoomed) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = '';
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .avatar-container {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid rgba(250, 250, 250, 0.2);
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .avatar-container:hover {
    transform: scale(1.05);
  }
  
  .avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .zoom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .zoom-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
  }
  
  .zoomed-img {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  
  .close-btn {
    position: absolute;
    top: -40px;
    right: -40px;
    background: transparent;
    border: none;
    color: white;
    font-size: 30px;
    cursor: pointer;
  }
  </style>
  