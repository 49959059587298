<template>
 
  <div class="card pt-0 h-100">
    <div class="card-header d-flex mb-4">
      <!-- Case à cocher pour Scolarité -->
      <div class="form-check">
        <input type="radio" id="scolarite" value="scolarite" v-model="activeTab" class="form-check-input">
        {{ $t("todolist.tuition") }}
      </div>

      <!-- Case à cocher pour Autres Paiements -->
      <div class="form-check ms-3">
        <input type="radio" id="autre" value="autre" v-model="activeTab" class="form-check-input">
        {{ $t("todolist.other_payment") }}
      </div>
    </div>

    <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
      <div>
        <span class="mt-4">
          <Spinner></Spinner>
        </span>
      </div>
    </spinner>

    <div class="card-body pt-0" v-else-if="totalDue">
      <!-- Section Scolarité -->
      <div v-if="activeTab === 'scolarite'">
        <div class="content">
          <div class="titre" style="transform: translateY(-50px)">
            <h6>{{ $t("students_detail.school") }}</h6>
          </div>
          <ul class="list-group list-group-flush" data-toggle="checklist" style="transform: translateY(-50px)">
            <li class="checklist-entry list-group-item px-0"></li>
            <li v-for="(student, index) in students.filter(student => !student.is_other_payment)" :key="index"
              class="checklist-entry list-group-item px-0" @click="toggleDetails(index)" style="cursor: pointer;">
              <div class="checklist-item checklist-item-warning py-2 d-flex">
                <div class="checklist-info d-flex align-items-center">
                  <i class="bi bi-chevron-down me-2" :class="{ 'bi-chevron-up': activeIndex === index }"></i>
                  <h6 class="checklist-title mb-0" style="font-size: 14px;"> {{ $t("todolist.instalment") }}</h6>
                  <small class="text-xL ms-3"><span style="font-size:12px"> {{currency_code }} </span>{{ Number(student.amount).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          }) }}  </small>
                </div>
                <div class="form-check my-auto ms-auto text-end">
                  <span v-if="!student.status"> -----------</span>
                  <span class="badge badge-sm bg-gradient-warning" v-if="student.status == 'Pending'">
                    - {{ student.amount - student.splits_total_amount }}
                  </span>
                  <span class="badge badge-sm bg-gradient-success text-center" v-if="student.status == 'Settled'">
                    {{ $t("students_detail.settled") }}
                  </span>
                </div>
              </div>
              <div v-if="activeIndex === index" class="details px-4">
                <div class="payment" v-if="student.splits.length > 0">
                  <div class="row" v-for="(split, index) in student.splits" :key="index">
                    <div class="col-md-6 installment"><i class="me-1 bi bi-arrow-right"></i> {{ $t("todolist.payment") }}</div>
                    <div class="col-md-6 text-end"><span style="font-size:12px"> {{currency_code }} </span>
                      {{ Number(split.amount).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          }) }}
                      </div>
                  </div>
                </div>
                <div class="no-payment text-center py-3" v-else>
                  <p class="border py-2">{{ $t("todolist.no_payment") }}</p>
                </div>
              </div>
            </li>
            <li class="checklist-entry list-group-item px-0"></li>
          </ul>
        </div>
      </div>

      <!-- Section Autres Paiements -->
      <div v-if="activeTab === 'autre'">
        <div class="content">
          <div class="titre mb-4" style="transform: translateY(-50px)">
            <h6>{{ $t("todolist.other_payment") }}</h6>
          </div>
          <ul class="list-group list-group-flush" style="transform: translateY(-50px)">
            <li v-for="(student, index) in students.filter(student => student.is_other_payment)" :key="index"
              class="checklist-entry list-group-item px-0" @click="toggleDetails(index)" style="cursor: pointer; 
              border-top: 1px solid rgba(250, 250, 250, 0.3);
              border-bottom: 1px solid rgba(250, 250, 250, 0.3);
               ">
              <div class="checklist-item checklist-item-warning py-2 d-flex">
                <div class="checklist-info d-flex align-items-center">
                  <i class="bi bi-chevron-down me-2" :class="{ 'bi-chevron-up': activeIndex === index }"></i>
                  <h6 class="checklist-title mb-0" style="font-size: 14px;"> {{student.label}}</h6>
                  <small class="text-xL ms-3"><span style="font-size:12px"> {{currency_code }} </span>
                    
                  
                     {{ Number(student.amount).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          }) }}
                    </small>
                </div>
                <div class="form-check my-auto ms-auto text-end">
                  <span v-if="!student.status"> -----------</span>
                  <span class="badge badge-sm bg-gradient-warning" v-if="student.status == 'Pending'">
                    - {{ student.amount - student.splits_total_amount }}
                  </span>
                  <span class="badge badge-sm bg-gradient-success text-center" v-if="student.status == 'Settled'">
                    {{ $t("students_detail.settled") }}
                  </span>
                </div>
              </div>
              <div v-if="activeIndex === index" class="details px-4">
                <div class="payment" v-if="student.splits.length > 0">
                  <div class="row" v-for="(split, index) in student.splits" :key="index">
                    <div class="col-md-6 installment"><i class="me-1 bi bi-arrow-right"></i> {{$t("todolist.payment") }}</div>
                    <div class="col-md-6 text-end"><span style="font-size:12px"> {{currency_code }} </span>
                       {{ Number(split.amount).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          }) }}
                      </div>
                  </div>
                </div>
                <div class="no-payment text-center py-3" v-else>
                  <p class="border py-2">{{ $t("todolist.no_payment") }}</p>
                </div>
              </div>
            </li>
            <li v-if="students.filter(student => student.is_other_payment).length === 0" class="checklist-entry list-group-item px-0">{{ $t("todolist.no_other_payment") }}</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="else" v-else>
      <div class="container text-center">
        <div class="text mt-3">
          <img class="image" src="@/assets/img/tranches.png" alt="" />
          <h5 class="mt-3">{{ $t("todolist.no_payment") }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "../components/Spinner.vue";
import api_config from "@/config";

export default {
  data() {
    return {
      loading: false,
      activeTab: 'scolarite', // Onglet par défaut
      activeIndex: null,
      currency_code: api_config.CURRENCY_CODE,
    };
  },
  components: {
    Spinner
  },
  methods: {
    toggleDetails(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    }
  },
  props: {
    totalDue: Number,
    students: Array,
  }
};
</script>

<style scoped>
.btn.active {
  background-color: #75acff;
  color: white;
}
.btn.inactive {
  background-color: transparent;
  color: white;
  border: 1px solid rgba(250, 250, 250, 0.3);
}
</style>
