<template>


  <div class="row position-relative">
    <div class="button">
      <div class="mb-4 card">
        <div @mouseover="showInfo" @mouseleave="hideInfo" class="p-3 card-body position-relative">
          <div class="card info text-light position-absolute pt-1 pb-1 p-2" style="
              width: 350px;
              z-index: 50;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
              background: rgba(3, 6, 20, 0.9);
            " v-if="isInfoVisible && !classroomid_list.includes(classroom_id)">
            <p style="text-align: justify">{{ $t("classroom.alert") }}</p>
          </div>

          <div class="d-flex " :class="directionReverse ? reverseDirection : ''">
            <div>
              <div class="text-center icon icon-shape position-relative" style="margin-right: 15px" :class="`${iconBackground} ${this.$store.state.isRTL
                ? 'border-radius-md'
                : 'border-radius-2xl'
                } `">
                <div class="payment position-absolute bg-gradient-danger" v-if="payment"></div>
                <div class="payment config position-absolute">
                  <div class="dic" v-if="loading">
                    <Spinner></Spinner>
                  </div>
                  <i v-else-if="!classroomid_list.includes(classroom_id)" class="bi bi-exclamation-triangle-fill" style="
                      font-size: 28px;
                      top: -15px;
                      cursor: pointer;
                      color: #f5365c;
                    "></i>
                </div>

                <h5 class="font-weight-bolder" aria-hidden="true" style="line-height: 2.5; color: #ffffff">
                  {{ student_count }}
                </h5>
              </div>
            </div>
            <router-link class=" w-90" :to="{
              name: 'class-details',
              params: { classId: classroom_id },
            }">
              <div :class="contentClass">

                <div class="numbers" style="transform: translateY(15px)">
                  <h3 class="mb-0 text-sm text-capitalize font-weight-bold" :class="titleColor">
                    <div class="d-flex">
                      <span style="opacity: 0.8">{{ grade }} </span>
                      <div
                        v-if="speciality_code && speciality_code !== 'Aucune Specialité' && speciality_code !== 'No speciality'">
                        <span class="mx-1">/</span>
                        <span style="opacity: 0.4"> {{ speciality_code }} </span>
                      </div>

                    </div>


                  </h3>


                </div>

              </div>

            </router-link>
          </div>
          <div class="d-flex  " id="info-class" style="transform: translateY(8px);">
            <div class="pending mt-2" v-if="pending_amount !== 0 || overdue !== 0">
              <span :title='$t("classroom.title")' id="price-pending">{{ currency_code }}

                {{ Number(pending_amount).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                }) }}
              </span> <span class="mx-2">|
              </span>
              <span id="over" class="bg-gradient-danger"> {{ overdue }} </span>
            </div>
            <div class="no-pending" v-else>
              <i v-if="classroomid_list.includes(classroom_id)"
                style="color: #2ecebf; font-size: 20px; margin-top: -4px" class="bi bi-check2-circle"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getclassroomsconfig } from "@/utils/services";

//import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import api_config from "@/config";

export default {
  name: "ClassesCard",
  data() {
    return {
      reverseDirection: "flex-row-reverse justify-content-between",
      payment: false,
      config: true,
      isInfoVisible: false,
      classroomid_list: [],

      loading: false,
      isDropdownVisible: false,

      isOpen: false,
      selectedClassroomId: null,
      activeSubMenu: null,
      currency_code: api_config.CURRENCY_CODE,
    };
  },
  components: {},
  methods: {
    selectOption() {
      // traiter l'option

      this.isOpen = false;
    },
    openMenu() {
      this.isOpen = true;
    },

    showInfo() {
      this.isInfoVisible = true;
    },
    hideInfo() {
      this.isInfoVisible = false;
    },
    async fetchConfig() {
      try {
        this.loading = true;
        const result = await getclassroomsconfig();

        if (result && result.length > 0) {
          this.classroomid_list = result.map((config) => config.classroom_id);
        }

        // ...
      } catch (error) {
        console.error(
          "Erreur inattendue lors de la récupération des configurations appliquées :",
          error
        );
      } finally {
        this.loading = false;
      }
    },

    openDeleteModal(classroomId) {
      this.selectedClassroomId = classroomId;
    },
  },

  props: {
    directionReverse: Boolean,
    configApplied: Boolean,
    grade: {
      type: String,
      required: true,
    },
    payment_pedding: {
      type: String,
      required: true,
    },
    classroom_id: {
      type: String,
      required: true,
    },
    detail: {
      type: String,
    },
    titleColor: {
      type: String,
    },
    value: {
      type: String,
    },
    student_count: {
      type: String,
    },
    speciality_code: {
      type: String,
    },
    pending_payment: {
      type: String,
    },
    valueColor: {
      type: String,
    },
    percentage: String,
    iconClass: {
      type: String,
      required: true,
    },
    percentageColor: {
      type: String,
      default: "text-success",
    },
    iconBackground: {
      type: String,
      default: "bg-white",
    },
    badgeBackground: {
      type: String,
      default: "bg-gradient-danger",
    },
    contentClass: {
      type: String,
    },
    overdue: {
      type: Number,
      required: true,
    },
    pending_amount: {
      type: Number,
      required: true,
    }




  },
  mounted() {
    this.fetchConfig();
    document.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        this.isOpen = false;
      }
    });
  },
};
</script>

<style scoped>
#price-pending {
  color: white;
  padding: 4px 10px;
  border-radius: 5px;
  border: 1px solid #fb8840;
  font-size: 12px;
}

#over {

  color: white;
  padding: 4px 10px;
  font-size: 12px;
  border-radius: 5px;
}

.fa-ellipsis-h {
  cursor: pointer;
}

.menu {
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 30;
}

.card .card-body .payment {
  top: -10px;
  right: -20px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.card .card-body .payment p {
  font-size: 14px;
  transform: translateY(3px);
  font-weight: bold;
}

.card {

  min-height: 115px;
}

.card .info {
  top: -145px;
  right: -45px;
  text-align: center;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-toggle::after {
  display: none;
  /* Masque l'icône du toggle */
}

.dropdown-toggle::before {
  content: "\f141";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 5px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

#info-class {
  display: none;
}
</style>
