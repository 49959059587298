<template>
  <NetworkStatus v-if="isOffline" />
  <div v-else>

    <div class="main">
      <!--==================   Period modal   ==================-->

      <div class="modal fade" id="dateFilterModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="card w-60 mx-auto">
              <div class="modal-header" style="border-bottom:1px solid rgba(255, 255, 255, 0.4);">
                <h6 class="modal-title" id="exampleModalLabel">{{ $t("transaction.modal_title") }}</h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <form @submit.prevent="applyDateFilter">
                <div class="modal-body">
                  <div class="mb-3">
                    <label for="startDate" class="form-label">{{ $t("transaction.start_date") }}<em
                        style="color: #f60000">*</em></label>
                    <input style="background: transparent; color:white; border: 1px solid rgba(250,250, 250, 0.4);"
                      type="date" v-model="filterStartDate" class="form-control" id="startDate" required>
                  </div>
                  <div class="mb-3">
                    <label for="endDate" class="form-label">{{ $t("transaction.end_date") }} <em
                        style="color: #f60000">*</em></label>
                    <input style="background: transparent; color:white; border: 1px solid rgba(250,250, 250, 0.4);"
                      type="date" v-model="filterEndDate" class="form-control" id="endDate" required>
                  </div>
                </div>
                <div class="modal-footer" style="border-top:1px solid rgba(255, 255, 255, 0.4);">
                  <button style="border:1px solid rgba(255, 255, 255, 0.4); color:white;" type="button"
                    class="btn bg-transparent" data-bs-dismiss="modal"> {{ $t("button.close") }} </button>
                  <button data-bs-dismiss="modal" type="submit" class="btn btn-success">{{
                    $t("transaction.filter") }}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!--================== Modal montant pas reçu   ==================-->

    
      <div class="modal fade" id="pasreçu" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="card">
              <div class="modal-header" style="border-bottom: 1px solid rgba(250,250, 250, 0.4);">
                <h6 class="modal-title fs-5" id="staticBackdropLabel">{{$t("transfer.late_transfer_title") }} </h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div v-if="selectedTransaction" class="time text-center">
                  <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
                  <template v-if="!isMoreThan72Hours(selectedTransaction.collected_until)">

                    <p class="px-4">
                      {{$t("transfer.late_transfer_p1")}}
                       {{ formatDateWithoutTime(selectedTransaction.collected_from) }}.
                       {{$t("transfer.late_transfer_p2")}} {{
                        calculateDatePlus72Hours(selectedTransaction.collected_from) }}.
                      {{$t("transfer.late_transfer_p3")}}
                    </p>


                  </template>
                  <template v-else>
                    <p class="px-4 w-60 mx-auto text-start">
                      {{$t("transfer.late_transfer_p4")}}
                       
                       
                    </p>
                    <ul class=" text-start w-60 mx-auto mt-3">
                      <li> <strong>ID operation </strong>  <span class="ms-5">{{ selectedTransaction.operation_id }}</span>  </li>
                      <li> <strong>{{ $t("transfer.amount") }} </strong> <span class="ms-6"> {{currency_code }} {{ Number(selectedTransaction.amount).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</span> </li>
                      <li> <strong>{{ $t("transfer.start_date") }}  </strong>  <span class="ms-4">{{ formatDateWithoutTime(selectedTransaction.collected_from) }}</span> </li>
                      <li> <strong>{{ $t("transfer.end_date") }} </strong> <span class="ms-5">{{ formatDateWithoutTime(selectedTransaction.collected_until) }}</span> </li>
                       
                    </ul>


                  </template>
                </div>
              </div>
              <div class="modal-footer" style="border-top: 1px solid rgba(250,250, 250, 0.4);">
                <argon-button type="button" class="btn" data-bs-dismiss="modal"
                  style="width: 150px; height: 50px; padding-top: 15px" color="danger" variant="gradient">
                  {{ $t("button.cancel") }}
                </argon-button>
                <argon-button :loading="loading" :active="buttonActive || showConfirmation" color="success"
                  variant="gradient" type="button" class="mt-1" style="width: 150px; height: 50px; padding-top: 15px">
                  {{ $t("button.confirm") }}
                </argon-button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!--================== Modal montant contesté   ==================-->

      <!-- Modal -->
      <div class="modal fade" id="conteste" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="card">
              <div class="modal-header" style="border-bottom: 1px solid rgba(250,250, 250, 0.4);">
                <h6 class="modal-title fs-5" id="staticBackdropLabel">{{ $t("transfer.contest_amount_title") }} </h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div v-if="selectedTransaction" class="time text-center">

                  <div v-if="!showForm">
                    <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
                    <p class="px-4 text-start w-60 mx-auto">
                      {{ $t("transfer.contest_amount_p1") }}
                    </p>
                    <ul class=" text-start w-60 mx-auto mt-3">
                      <li> <strong>ID operation </strong>  <span class="ms-5">{{ selectedTransaction.operation_id }}</span>  </li>
                      <li> <strong>{{ $t("transfer.amount") }} </strong> <span class="ms-6"> {{currency_code }} {{ Number(selectedTransaction.amount).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0}) }}</span> </li>
                      <li> <strong>{{ $t("transfer.start_date") }}  </strong>  <span class="ms-4">{{ formatDateWithoutTime(selectedTransaction.collected_from) }}</span> </li>
                      <li> <strong>{{ $t("transfer.end_date") }} </strong> <span class="ms-5">{{ formatDateWithoutTime(selectedTransaction.collected_until) }}</span> </li>
                       
                    </ul>
                    <p class="px-4 text-start w-60 mx-auto " >
                      {{ $t("transfer.contest_amount_p2") }}
                    </p>

                     
                  </div>

                  <div v-if="showForm" class="form  text-start w-60">
                    <form @submit.prevent="submitContestation">
                      <div class="mb-3">
                        <label for="montantActuel" class="form-label">{{ $t("transfer.contest_current_amount") }}</label>
                        <input type="number" class="form-control" id="montantActuel" :value="selectedTransaction.amount"
                          disabled
                          style="background: transparent; color:white; border: 1px solid rgba(250,250, 250, 0.4);">
                      </div>
                      <div class="mb-3">
                        <label for="montantAttendu" class="form-label">{{ $t("transfer.contest_pending_amount") }}<em
                            style="color: #f60000">*</em></label>
                        <input type="number" :placeholder='$t("transfer.contest_placeholder")' class="form-control"
                          id="montantAttendu" v-model="montantAttendu" required
                          style="background: transparent; color:white; border: 1px solid rgba(250,250, 250, 0.4);">
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="modal-footer" style="border-top: 1px solid rgba(250,250, 250, 0.4);">
                <argon-button type="button" class="btn" data-bs-dismiss="modal"
                  style="width: 150px; height: 50px; padding-top: 15px" color="danger" variant="gradient">
                  {{ $t("button.cancel") }}
                </argon-button>

                <argon-button v-if="!showForm" :loading="loading" :active="buttonActive || showConfirmation"
                  color="success" variant="gradient" type="button" class="mt-1"
                  style="width: 150px; height: 50px; padding-top: 15px" @click="showForm = true">
                  {{ $t("button.continue") }}
                </argon-button>

                <argon-button v-if="showForm" :loading="loading" :active="buttonActive || showConfirmation"
                  color="success" variant="gradient" type="button" class="mt-1"
                  style="width: 150px; height: 50px; padding-top: 15px" @click="submitContestation">
                  {{ $t("button.confirm") }}
                </argon-button>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="main-transaction">

        <div class="container-fluid">
          <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
            <div>
              <span class="mt-4">
                <Spinner></Spinner>
              </span>
            </div>
          </spinner>

          

          <!--==================  Bank information  ==================-->

          <div class="card-api mt-4 mb-3 px-3 pb-2 "
            style="border: 1px solid rgba(250, 250, 250, 0.5); border-radius: 5px; background: #111c44;"
            v-else-if="status_code && !status_code <= 104">
            <div class="card-api-content d-flex justify-content-between">
              <div class="d-flex mt-3 pt-1">
                <div class="key pe-2 me-3">
                  <img style="height: 80px;" class="ms-2 me-1 rounded-circle" :src="this.bank_logo" alt="">
                </div>
                <div class="text mx-3">
                  <h6 style="font-size: 14px;"> <span class="info_bank" v-if="detail && detail.data"> {{
                    this.bank_slug }} </span> <span class="ms-1"> : {{ formatAccountNumber(transaction_number)
                      }}</span> </h6>
                  <p style="font-size: 14px;"> {{ $t("transfer.info_p") }} {{currency_code }}
                    {{ Number(this.transfer_min_amount).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    }) }} - <router-link to="/settings#payment-settings" @click="handleParemNavigation"
                      style="color:#60a5fa !important;">{{ $t("button.modify")
                      }}</router-link></p>
                  <h6 style="font-size: 14px; margin-top: -10px;"> {{ $t("transfer.info_p2") }} </h6>
                </div>
              </div>
            </div>
          </div>


          <!--================== Table ==================-->

          <div class="content-table" v-if="transfers.length > 0">
            <div style="margin-top: 100px;">
              <label for="" style="transform: translateY(-30px)"> {{ $t("transfer.filter") }}</label>
              <div class="dropdown" id="dropdown-status">
                <button class="btn dropdown-toggle text-light fw-bold ps-0" type="button" data-bs-toggle="dropdown"
                  style="transform: translateY(-30px)">
                  {{ selectedPeriodLabel }}
                </button>
                <ul class="dropdown-menu">
                  <li @click="setPeriodFilter('all', $t('transfer.all_periods'))">
                    {{ $t("transfer.all_periods") }}
                  </li>
                  <li @click="setPeriodFilter('today', $t('transfer.today'))">
                    {{ $t("transfer.today") }}
                  </li>
                  <li @click="setPeriodFilter('yesterday', $t('transfer.yesterday'))">
                    {{ $t("transfer.yesterday") }}
                  </li>
                  <li @click="setPeriodFilter('lastSevenDays', $t('transfer.since_the_seven_days'))">
                    {{ $t("transfer.since_the_seven_days") }}
                  </li>
                  <li @click="setPeriodFilter('lastTwentyEightDays', $t('transfer.last_28_days'))">
                    {{ $t("transfer.last_28_days") }}
                  </li>
                  <li data-bs-toggle="modal" data-bs-target="#dateFilterModal"
                    @click="setPeriodFilter('custom', $t('transaction.filter_date'))">
                    {{ $t("transaction.filter_date") }}
                  </li>
                </ul>
              </div>
            </div>


            <div class="card px-0 mt-2">
              <div class="card-body px-0">
                <div class="row">
                  <div class="col-md-6 px-0"></div>
                  <div class="col-md-6 text-end" style="padding-right: 30px">

                  </div>
                </div>

                <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
                  <div>
                    <span class="mt-4">
                      <Spinner></Spinner>
                    </span>
                  </div>
                </spinner>

                <table class="table px-0 mt-6 mb-6" v-else-if="transfers.length > 0">
                  <thead>
                    <tr style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)">

                      <th class="border-0">
                        ID operation
                      </th>
                      <th class=" border-0 text-start w-25 " style="text-transform: none;">
                        {{ $t("transfer.start_date") }}
                      </th>

                      <th class=" border-0 text-start w-25" style="text-transform: none;">
                        {{ $t("transfer.end_date") }}
                      </th>

                      <th class=" border-0 w-25 text-start px-0">
                        {{ $t("transaction.status") }}
                      </th>

                      <th class=" border-0 w-25 text-end" style="text-decoration: none">
                        {{ $t("transfer.amount") }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="list in transfers" :key="list.id" style="
                             position: relative;
                             border-bottom: 1px solid rgba(255, 255, 255, 0.1);

                 " class="transfer-row">



                      <td class="px-4 border-0 position-relative">
                        <span v-if="list.operation_id == null">
                          -----------
                        </span>
                        <span v-else>
                          {{ list.operation_id }}
                        </span>

                      </td>



                      <td class=" ps-4 border-0 position-relative">

                        {{ formatDate(list.collected_from) }}

                      </td>

                      <td class=" ps-4  border-0 position-relative">

                        {{ formatDate(list.collected_until) }}


                      </td>

                      <td class=" ps-0 pe-7 border-0 position-relative d-flex" v-if="list.status == 303">
                        <span class="status-code bg-gradient-success mx-1"> </span> {{ $t("transfer.executed") }}
                      </td>

                      <td class=" ps-0 pe-7 border-0 position-relative d-flex" v-if="list.status == 302">
                        <span class="status-code bg-warning mx-1"> </span> {{ $t("transfer.processing") }}
                      </td>

                      <td class=" ps-0 pe-7 border-0 position-relative d-flex text-filter" v-if="list.status == 301">
                        <span class="status-code  mx-1"> </span> {{ $t("transfer.ready") }}
                      </td>

                      <td class=" text-end  px-4 border-0 position-relative ">
                        {{currency_code }} 
                        <span class="text-filter">
                          {{ Number(list.amount).toLocaleString(this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          }) }}

                        </span>

                        <div class="btn-group" id="btn-group" v-if="!isWithinOneWeek(list.collected_until)">
                          <button type="button" class="btn  dropdown-toggle dropdown-table" id="dropdown-table"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            {{ $t("transfer.reclamation") }}
                          </button>

                          <ul class="dropdown-menu py-0" id="dropdown-menu">
                            <li data-bs-toggle="modal" style="border-bottom:1px solid rgba(250, 250, 250, 0.5);"
                              data-bs-target="#pasreçu" @click="openReclamationModal('pasrecu', list)"
                              v-if="list.status == 301">
                              <a class="dropdown-item py-2" href="#" @click.prevent>{{$t("transfer.late_transfer_title") }} </a>
                            </li>
                            <li data-bs-toggle="modal" data-bs-target="#conteste"
                              @click="openReclamationModal('conteste', list)">
                              <a class="dropdown-item py-2" href="#" @click.prevent>{{ $t("transfer.contest_amount_title") }} </a>
                            </li>
                          </ul>
                        </div>

                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>

                <!--================== NO transaction ==================-->
                <div class="else text-center pb-5" v-else>
                  <div class="container">
                    <div class="text mt-3">
                      <img class="image" src="@/assets/img/transaction.png" alt="" />
                      <h5 class="mt-4"> {{ $t("transfer.no_transfer") }} </h5>

                      <!---
                                <p class="mt-4 px-4">
                                    Utque proeliorum periti rectores primo catervas densas opponunt et fortes,
                                    deinde leves armaturas, post iaculatores
                                    ultimasque subsidiales acies, si fors adegerit, iuvaturas, ita praepositis urbanae.
                                </p>
                        -->

                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <!--================== Loading  ==================-->
          <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
            <div>
              <span class="mt-4">
                <Spinner></Spinner>
              </span>
            </div>
          </spinner>

          <!--==================Upload Bank informations  ==================-->
          <Nobankdoc class="mt-5" v-else-if="status_code && status_code <= 104" />


        </div>
      </div>
    </div>

  </div>

</template>

<script>
import Spinner from "../components/Spinner.vue";
import ArgonButton from "@/components/ArgonButton";
import { getTransfers, getxpconnection, xpayconnection } from "@/utils/services";
import Nobankdoc from "@/views/components/Nobankdocs.vue";
import NetworkStatus from "@/views/components/NetworkStatus.vue";
import api_config from "@/config";




function getFormattedDate() {
  const today = new Date();


  const start_date = "2020-01-01 00:00:00";

  // Current date with full precision
  const endYear = today.getFullYear();
  const endMonth = String(today.getMonth() + 1).padStart(2, '0');
  const endDay = String(today.getDate()).padStart(2, '0');
  const end_date = `${endYear}-${endMonth}-${endDay} 23:59:59`;

  return { start_date, end_date };
}


/**
function getFormattedDate() {
  const start_date = "2024-08-03 00:00:00";
  const end_date = "2024-09-03 00:00:00";
  return { start_date, end_date };
}
*/


export default {
  name: "historique-Transaction",
  components: { Spinner, Nobankdoc, NetworkStatus, ArgonButton },
  data() {
    return {
      loading: false,
      transactionSummary: {
        total: { count: 0, amount: 0 },
        successful: { count: 0, amount: 0 },
        running: { count: 0, amount: 0 },
        failed: { count: 0, amount: 0 },
      },
      schoolId: '',
      xpay_connection_id: '',
      bank_slug: '',
      transaction_number: '',
      transfer_min_amount: '',
      bank_logo: '',
      transfers: [],
      detail: [],
      filterStartDate: '',
      filterEndDate: '',
      selectedPeriodLabel: this.$t("transfer.all_periods"),
      selectedPeriod: null,
      status_code: '',
      isOffline: false,
      selectedTransaction: null,
      showForm: false,
      montantAttendu: null,
      currency_code: api_config.CURRENCY_CODE,
      buttonActive: false,
      showConfirmation: false,



    };
  },





  methods: {

    isWithinOneWeek(endDate) {
      const end = new Date(endDate);
      const now = new Date();
      const diffInDays = (now - end) / (1000 * 60 * 60 * 24);
      return diffInDays <= 7;
    },


    formatDateWithoutTime(dateString) {
      if (!dateString) return "N/A";
      try {
        const date = new Date(dateString);
        if (isNaN(date)) return "Invalid Date";

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
      } catch (error) {
        console.error("Error formatting date:", error);
        return "Invalid Date";
      }
    },

    calculateDatePlus72Hours(dateString) {
      if (!dateString) return "N/A";
      try {
        const date = new Date(dateString);
        if (isNaN(date)) return "Invalid Date";

        // Ajouter 72 heures
        const datePlus72 = new Date(date.getTime() + (72 * 60 * 60 * 1000));

        const year = datePlus72.getFullYear();
        const month = String(datePlus72.getMonth() + 1).padStart(2, '0');
        const day = String(datePlus72.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
      } catch (error) {
        console.error("Error calculating date:", error);
        return "Invalid Date";
      }
    },

    submitContestation() {
      // Ici vous pouvez ajouter la logique pour soumettre la contestation
      console.log('Contestation soumise:', {
        transactionId: this.selectedTransaction.operation_id,
        montantActuel: this.selectedTransaction.amount,
        montantAttendu: this.montantAttendu
      });
      // Réinitialiser le formulaire
      this.showForm = false;
      this.montantAttendu = null;
      // Fermer le modal (vous devrez peut-être adapter ceci selon votre implémentation)

    },

    openReclamationModal(type, transaction) {
      this.selectedTransaction = transaction;
      this.showForm = false; // Réinitialiser l'état du formulaire
      this.montantAttendu = null;
    },

    isMoreThan72Hours(endDate) {
      const end = new Date(endDate);
      const now = new Date();
      const diffInHours = (now - end) / (1000 * 60 * 60);
      return diffInHours >= 72;
    },


    formatNumber(number) {
      return Number(number).toLocaleString(
        this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US',
        {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }
      );
    },

    async updateOnlineStatus() {
      // Vérifier si le navigateur est en ligne
      if (navigator.onLine) {
        try {
          // Vérifier si les API sont accessibles
          await this.checkConnection();
          this.isOffline = false; // Tout fonctionne, on est en ligne
        } catch (error) {
          console.error("Error checking connection:", error);
          this.isOffline = true; // Les API ne répondent pas, on est hors ligne
        }
      } else {
        // Pas de connexion internet détectée
        this.isOffline = true;
      }
    },

    async checkConnection(sf_school_id) {
      try {
        // Essayer les deux appels API
        await Promise.all([
          xpayconnection(sf_school_id)

        ]);
        this.apiError = false; // Les API répondent correctement
      } catch (error) {
        console.error("API Error:", error);
        this.apiError = true; // Les API ne répondent pas
      }
    },

    handleParemNavigation() {
      this.$router.push({
        path: '/settings',
        hash: '#payment-settings'
      }).then(() => {
        this.$nextTick(() => {
          const tryScroll = (attempts = 0) => {
            const element = document.querySelector('#payment-settings');
            if (element) {
              element.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
              });
            } else if (attempts < 5) { // Essayer jusqu'à 5 fois
              setTimeout(() => tryScroll(attempts + 1), 100);
            }
          };
          tryScroll();
        });
      }).catch(err => {
        console.error('Erreur de navigation:', err);
      });
    },
    getFormattedDate,
    setPeriodFilter(period, label) {
      this.selectedPeriod = period;
      this.selectedPeriodLabel = label;

      // Call the corresponding filter method based on the selected period
      switch (period) {
        case 'all':
          this.fetchTransfers();
          break;
        case 'today':
          this.setFilterToday();
          break;
        case 'yesterday':
          this.setFilterYesterday();
          break;
        case 'lastSevenDays':
          this.setFilterLastSevenDays();
          break;
        case 'lastTwentyEightDays':
          this.setFilterLastTwentyEightDays();
          break;
        case 'custom':
          // For custom date, the modal will handle the filter
          break;
      }
    },



    setFilterToday() {
      const today = new Date();
      this.filterStartDate = this.formatDateToSQL(today);
      this.filterEndDate = this.formatDateToSQL(today);
      this.applyDateFilter();
    },

    setFilterYesterday() {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      this.filterStartDate = this.formatDateToSQL(yesterday);
      this.filterEndDate = this.formatDateToSQL(yesterday);
      this.applyDateFilter();
    },

    setFilterLastSevenDays() {
      const today = new Date();
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);
      this.filterStartDate = this.formatDateToSQL(sevenDaysAgo);
      this.filterEndDate = this.formatDateToSQL(today);
      this.applyDateFilter();
    },

    setFilterLastTwentyEightDays() {
      const today = new Date();
      const twentyEightDaysAgo = new Date(today);
      twentyEightDaysAgo.setDate(today.getDate() - 28);
      this.filterStartDate = this.formatDateToSQL(twentyEightDaysAgo);
      this.filterEndDate = this.formatDateToSQL(today);
      this.applyDateFilter();
    },



    // Formater la date
    formatDate(dateString) {
      if (!dateString) return "N/A";
      try {
        const date = new Date(dateString);
        if (isNaN(date)) return "Invalid Date";

        // Get date components
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        // Get time components
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        // Return formatted date with time in parentheses
        return `${year}-${month}-${day} ${hours}:${minutes}`;
      } catch (error) {
        console.error("Error formatting date:", error);
        return "Invalid Date";
      }
    },


    formatAccountNumber(number) {
      if (!number) return '';
      return number.replace(/(\d{5})(\d{5})(\d{11})(\d{2})/, '$1-$2-$3-$4');
    },





    async fetchxpayconnection() {
      this.loading = true;
      const sf_school_id = this.schoolId;


      try {
        this.loading = true;
        let response = await xpayconnection(sf_school_id);


        if (response && response.length > 0) {
          const connection = response[0];
          this.xpay_connection_id = connection.id;
          this.bank_slug = connection.bank_name;
          this.transfer_min_amount = connection.transfer_min_amount;
          this.transaction_number = connection.school_account_number;

          this.bank_logo = connection.bank_logo
          this.status_code = connection.status_code;

          localStorage.setItem('xpay_connection_id', this.xpay_connection_id);

          if (this.xpay_connection_id) {
            await this.fetchTransfers();
            await this.fetchXpconnection();
          }

        } else {
          console.error("Invalid response data or empty array:", response);
        }
      } catch (error) {
        console.error("Error during getconnection call:", error);
      } finally {
        this.loading = false;
      }
    },






    async fetchTransfers() {
      const xpay_connection_id = this.xpay_connection_id;
      const { start_date, end_date } = this.getFormattedDate();
      this.loading = true;

      try {
        let response = await getTransfers(xpay_connection_id, start_date, end_date);
        this.transfers = response || [];
        this.transactionSummary = response.summary || {
          total: { count: 0, amount: 0 },
          successful: { count: 0, amount: 0 },
          running: { count: 0, amount: 0 },
          failed: { count: 0, amount: 0 },
        };
      } catch (error) {
        console.error("Error fetching transfers:", error);
      } finally {
        this.loading = false;
      }
    },


    async fetchXpconnection() {

      const connection_id = this.xpay_connection_id;


      try {
        let response = await getxpconnection(connection_id);
        this.detail = response || [];

      } catch (error) {
        console.error("Error fetching xpconnection detail:", error);
      } finally {
        this.loading = false;
      }
    },



    // Fonction pour formater la date dans le format "YYYY-MM-DD 00:00:00"
    formatDateToSQL(date) {
      if (!date) return '';
      const selectedDate = new Date(date);
      const year = selectedDate.getFullYear();
      const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Ajout du 0 devant le mois si nécessaire
      const day = String(selectedDate.getDate()).padStart(2, '0'); // Ajout du 0 devant le jour si nécessaire
      return `${year}-${month}-${day} 00:00:00`; // Format final
    },

    // Méthode pour appliquer le filtre de date
    async applyDateFilter() {
      if (!this.filterStartDate || !this.filterEndDate) {
        alert("Veuillez sélectionner les deux dates");
        return;
      }

      // Formater les dates pour les envoyer dans le bon format
      const start_date = this.formatDateToSQL(this.filterStartDate);
      const end_date = this.formatDateToSQL(this.filterEndDate);

      // Appel à l'API pour récupérer les transferts filtrés
      try {
        const response = await getTransfers(this.xpay_connection_id, start_date, end_date);
        this.transfers = response || [];

      } catch (error) {
        console.error("Erreur lors de la récupération des transferts :", error);
      }
    },

  },


  async mounted() {
    // Initialiser l'état de connexion au montage
    await this.updateOnlineStatus();
    this.schoolId = localStorage.getItem("schoolId");
    this.fetchxpayconnection();
    this.fetchTransfers();

    // Ajouter les event listeners pour les changements de réseau
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  
    // Charger les données initiales
    if (!this.isOffline) {

      await this.fetchxpayconnection();
    }
  },




  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },




};
</script>

<style>
.info_bank img {
  height: 30px;
  width: 30px;

}

#form {
  background: none;
  border: 1px solid rgba(250, 250, 250, 0.6);
  height: 45px;
}



#card .card1 {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  color: white;
  border-radius: 10px;
}

#card .card1 span {
  font-size: 18px;
}

#card .card1 .price {
  font-size: 25px;
  font-weight: bold;
}

.table tbody tr td {
  padding-top: 13px;
  padding-bottom: 13px;
}

#span-table {
  width: 150px;
}



.transfer-row {
  position: relative;
  transition: 3s;
}


#dropdown-table {
  background: #4f61c4;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;

}

#btn-group {
  border: none;
  position: absolute;
  right: 170px;
  top: 12px;
  display: none;

}

.transfer-row:hover {
  background: rgba(255, 255, 255, 0.1);
}


.transfer-row:hover #btn-group {
  display: block;

}

.status-code {

  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #60a5fa;
  content: "";
  display: block;
  transform: translateY(5px);
}

#dropdown-menu {
  background: #111c44;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


#dropdown-menu .dropdown-item {
  color: white;
  border-bottom: 1px solid rgba(250, 250, 250, 250, 0.4);
}

#dropdown-menu .dropdown-item:hover {
  background: rgba(250, 250, 250, 250, 0.1);
  color: black;
}
</style>