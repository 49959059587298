<template>
    <div class="card  px-0 pt-4" style="min-height: 365px;">
        <div class="card-body px-0 pb-0">
            <div class="row">
                <div class="col-md-7 " style="padding-left: 35px; transform:translateY(-27px)">
                    <h6>{{$t("synchronisation.resync")}} </h6>
                </div>
                <div class="col-md-5 text-end" style="padding-right: 30px;transform:translateY(-27px)"
                    v-if="filteredRequests && filteredRequests.length > 0">
                    <RouterLink to="/teachers-syncing-request">
                        <argon-button color="success" size="sm" variant="outline">
                            {{ $t("button.See-more") }}
                        </argon-button>
                    </RouterLink>
                </div>
            </div>

            <Spinner class="text-center mt-2 mb-8" v-if="loading" style="height: 50px">
                <div>
                    <span class="">
                        <Spinner></Spinner>
                    </span>
                </div>
            </Spinner>
            <table class="table table-borderless mt-4" v-else-if="filteredRequests && filteredRequests.length > 0">
                <thead id="thead">
                    <tr id="thead">
                        <th class="w-40">{{$t("synchronisation.students")}}</th>
                        <th class="text-center" style="padding-left: 4%;">{{$t("synchronisation.class")}}</th>
                        <th class="text-end">{{$t("synchronisation.date")}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr id="tr" v-for="request in filteredRequests" :key="request.id">
                        <td class="ps-4">
                            <div class="row">
                                <div class="col-4">
                                    <img :src="request.photo_url || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBld3h7GuDkpTKb35HgCIgr-N7UXvSDJqbRO_RnDM&s'"
                                        class="avatar avatar-sm me-3" :alt="request.teacher_name"
                                        style="width: 60px; height: 60px; border-radius: 50%;" />
                                </div>
                                <div class="col-6">
                                    <span style="font-size: 14px;">
                                        {{ request.teacher_name }} <br>
                                        {{ request.phone_number }}
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td class="text-center" style="padding-left: 4%; font-size: 14px;">
                            {{ request.classroom_name }}
                        </td>
                        <td class="text-end pe-3" id="last_td">
                            <span style="font-size: 14px;">{{ formatDate(request.created_at) }}</span>
                            <div class="hover-icons" v-if="request.status === 'pending'">
                                <i data-bs-toggle="modal" @click="openConfirmModal(request)"
                                    data-bs-target="#confirmModal" :title='$t("synchronisation.title_sync")'
                                    class="fas fa-check text-success hover-icon"></i>
                                <i data-bs-toggle="modal" @click="openRejectModal(request)"
                                    data-bs-target="#rejectModal" :title='$t("synchronisation.title_reject")'
                                    class="fas fa-times text-danger hover-icon"></i>
                            </div>
                             
                             
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="else text-center pb-5" v-else>
                <div class="container">
                    <div class="text mt-3">
                        <img class="image1 w-20" src="@/assets/img/synchro.png" alt="" />

                        <p class="mt-4 mb-4">{{$t("synchronisation.no_sync_pending")}}</p>
                        <RouterLink to="/teachers-syncing-request">
                            <argon-button color="success" variant="outline">
                                {{$t("button.see_all")}}
                            </argon-button>
                        </RouterLink>


                    </div>
                </div>
            </div>

        </div>
    </div>




    <!-- Confirm Modal -->
    <div class="modal fade" id="confirmModal" tabindex="-1" ref="confirmModal" aria-labelledby="confirmModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="card">
                    <div class="modal-header" style="border-bottom:1px solid rgba(250, 250,250, 0.4) ;">
                        <h6 class="modal-title" id="confirmModalLabel"> {{$t("synchronisation.confirm_sync")}}</h6>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="sync-details" v-if="selectedRequest">
                            <div class="modal-body text-center pt-4 pb-4">
                                <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
                                <h6 class="mb-4 text-center">{{$t("synchronisation.confirm_sync_message")}}</h6>
                                <p class="mb-4"> {{$t("synchronisation.confirm_sync_p")}} </p>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.students")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.subject")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.class")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col"> {{$t("synchronisation.date")}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ; " class="ps-4">{{
                                            selectedRequest.teacher_name }}</td>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="ps-4">{{
                                            selectedRequest.matiere }}</td>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="ps-4">{{
                                            selectedRequest.classroom_name }}
                                        </td>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="pe-4 text-end">{{
                                            formatDate(selectedRequest.created_at) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="form-group mb-3 px-3" v-if="availableClassrooms.length > 0">
                            <label class="form-label">{{$t("synchronisation.select_class")}}</label>
                            <div class="checkbox-group mt-2 d-flex flex-wrap gap-3">
                                <div v-for="classroom in availableClassrooms" :key="classroom.id"
                                    class="form-check d-inline-flex align-items-center me-3">
                                    <input type="radio" :id="'classroom-' + classroom.id" :value="classroom.id"
                                        v-model="selectedClassroomId" class="form-check-input me-2">
                                    <label :for="'classroom-' + classroom.id" class="form-check-label"
                                        style="transform: translateY(5px);">
                                        {{ selectedRequest.classroom_name }} {{ classroom.number }}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div v-else class="alert alert-warning">
                            {{$t("synchronisation.no_class_available")}}
                        </div>


                    </div>
                    <div class="modal-footer" style="border-top:1px solid rgba(250, 250,250, 0.4) ;">
                        <button type="button" style="border: 1px solid rgba(250, 250,250, 0.4);"
                            class="btn  br-transparent" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" class="btn btn-success me-3" @click="handleConfirmSync">
                            <span v-if="isConfirmLoading" class="spinner-border spinner-border-sm me-2"
                                role="status"></span>
                                {{$t("synchronisation.confirm")}}
                        </button>
                    </div>


                </div>
            </div>
        </div>
    </div>

    <!-- Reject Modal -->
    <div class="modal fade" id="rejectModal" tabindex="-1" aria-labelledby="rejectModalLabel" ref="confirmModal"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="card">
                    <div class="modal-header" style="border-bottom:1px solid rgba(250, 250,250, 0.4) ;">
                        <h5 class="modal-title" id="rejectModalLabel"> {{$t("synchronisation.reject_sync")}}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="sync-details" v-if="selectedRequest">
                            <div class="modal-body text-center pt-4 pb-4">
                                <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
                                <h6 class="mb-4 text-center">{{$t("synchronisation.reject_sync_message")}}</h6>
                                <p class="mb-4"> {{$t("synchronisation.reject_sync_p")}}  </p>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.students")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.subject")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.class")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col"> {{$t("synchronisation.date")}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ; " class="ps-4">{{
                                            selectedRequest.teacher_name }}</td>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="ps-4">{{
                                            selectedRequest.matiere }}</td>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="ps-4">{{
                                            selectedRequest.classroom_name }}</td>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="pe-4 text-end">{{
                                            formatDate(selectedRequest.created_at) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div class="modal-footer" style="border-top:1px solid rgba(250, 250,250, 0.4) ;">
                        <button type="button" style="border: 1px solid rgba(250, 250,250, 0.4);"
                            class="btn  br-transparent" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" class="btn btn-danger me-3" @click="handleRejectSync">
                            <span v-if="isRejectLoading" class="spinner-border spinner-border-sm me-2"
                                role="status"></span>
                              {{$t("synchronisation.reject")}}
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>


    
</template>

<script>
 
import { getClassroomSyncRequests, SyncRequests, decline_syncRequests, getGradeCode } from "@/utils/services";
import Spinner from "../components/Spinner.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import ArgonButton from "@/components/ArgonButton.vue";
export default {
    name: 'ClassroomSyncRequests',
    data() {
        return {

            syncRequests: [],
            selectedRequest: null,
            rejectReason: '',
            confirmModal: null,
            rejectModal: null,
            currentPage: 1,
            itemsPerPage: 5,
            searchQuery: '',
            selectedSubject: null,
            selectedClass: null,
            selectedStatus: null,
            uniqueSubjects: [],
            uniqueClasses: [],
            gradesList: [],
            availableClassrooms: [],
            selectedClassroomId: [],
            loading: false,
            isConfirmLoading: false,
            isRejectLoading: false,
            isDesyncLoading: false,
            isResyncLoading: false,
        }

    },

    components: {
        Spinner,
        ArgonButton
    },

    computed: {

        filteredRequests() {
            return this.syncRequests
                .filter(request => request.status === 'pending')
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .slice(0, 2);
        }
    },
    methods: {
        async getGradesData() {
            try {
                const grades = await getGradeCode();
                this.gradesList = grades.data;
            } catch (error) {
                console.error('Error fetching grades:', error);
            }
        },

        updateAvailableClassrooms(gradeCode) {
            const grade = this.gradesList.find(g => g.code === gradeCode);
            this.availableClassrooms = grade ? grade.classrooms : [];
            this.selectedClassroomId = null; // Réinitialiser la sélection
        },


        async fetchSyncRequests() {
            this.loading = true;
            try {
                const response = await getClassroomSyncRequests();

                // Vérifier si nous avons une réponse avec des données
                if (response && response.data) {
                    this.syncRequests = response.data.map(request => ({
                        id: request.id,
                        teacher_name: request.teacher_name,
                        phone_number: request.teacher_phone,
                        classroom_name: request.grade_code,
                        created_at: request.created_on,
                        status: this.getStatusFromCode(request.status),
                        photo_url: request.teacher_photo,
                        matiere: request.subject_code,
                        eska_classroom_id: request.eska_classroom_id,
                        classroom_number: request.classroom_number
                    }));

                    // Mettre à jour les filtres uniquement si nous avons des données
                    if (this.syncRequests.length > 0) {
                        this.uniqueSubjects = [...new Set(this.syncRequests.map(r => r.matiere))];
                        this.uniqueClasses = [...new Set(this.syncRequests.map(r => r.classroom_name))];
                    }

                    
                } else {
                    console.error('Pas de données dans la réponse');
                    this.syncRequests = [];
                }

            } catch (error) {
                console.error('Error fetching sync requests:', error);
                toast.error(this.$t('synchronisation.message_error_get'), {
                    autoClose: 3000,
                });
                this.syncRequests = [];
            } finally {
                this.loading = false;
            }
        },


        async handleConfirmSync() {
            this.isConfirmLoading = true;
            try {
                if (!this.selectedRequest || !this.selectedClassroomId) {
                    toast.error(this.$t('synchronisation.message_no_class'), {
                        autoClose: 3000,
                    });
                    return;
                }

                // Vérifier si une synchronisation identique existe déjà
                const existingSynchronization = this.syncRequests.find(request =>
                    request.status === 'confirmed' &&
                    request.matiere === this.selectedRequest.matiere &&
                    request.classroom_name === this.selectedRequest.classroom_name &&
                    request.classroom_number === this.selectedClassroomId &&
                    request.teacher_name === this.selectedRequest.teacher_name &&
                    request.phone_number === this.selectedRequest.phone_number
                );

                if (existingSynchronization) {
                    toast.error(this.$t('synchronisation.message_syns_id'), {
                        autoClose: 3000,
                    });
                    return;
                }


                const syncData = {
                    status: 2,
                    classroom_id: this.selectedClassroomId
                };

                const response = await SyncRequests(syncData, `${this.selectedRequest.id}`);

                if (response.status === 200) {
                     this.modal = false;
                    toast.success(this.$t('synchronisation.message_sync_success'), {
                        autoClose: 3000,
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                    this.selectedRequest = null;
                    this.selectedClassroomId = null;
                } else {
                    throw new Error('Erreur lors de la synchronisation');
                }
            } catch (error) {
                console.error('Erreur lors de la confirmation de la synchronisation:', error);
                toast.error(this.$t('synchronisation.message_sync_error'), {
                    autoClose: 3000,
                });
            } finally {
                this.isConfirmLoading = false;
            }
        },

        async handleRejectSync() {
            this.isRejectLoading = true;
            try {
                const syncData = {
                    status: 1,
                };

                const response = await decline_syncRequests(syncData, `${this.selectedRequest.id}`);

                if (response.status === 200) {
                     
                   
                    this.modal = false;

                   
                    toast.success(this.$t('synchronisation.message_sync_rejectted'), {
                        autoClose: 3000,
                    });

                    
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                     
                    this.selectedRequest = null;
                } else {
                    throw new Error('Erreur lors du refus de la synchronisation');
                }
            } catch (error) {
                console.error('Erreur lors du refus de la synchronisation:', error);
                toast.error(this.$t('synchronisation.message_sync_error_reject'), {
                    autoClose: 3000,
                });
            } finally {
                this.isRejectLoading = false;
                 
            }
        },



        async openConfirmModal(request) {
            this.selectedRequest = request;
            await this.updateAvailableClassrooms(request.classroom_name);
        },
        openRejectModal(request) {
            this.selectedRequest = request;
        },

        formatDate(date) {
            return new Date(date).toLocaleDateString()
        },
        getStatusFromCode(code) {
            switch (code) {
                case 1: return 'pending';
                case 2: return 'confirmed';
                case 3: return 'rejected';
                default: return 'pending';
            }
        },
        selectSubject(subject) {
            this.selectedSubject = subject;
        },
        selectClass(classe) {
            this.selectedClass = classe;
        },
        selectStatus(status) {
            this.selectedStatus = status;
        }
    },
    created() {
        this.fetchSyncRequests();
        this.getGradesData();
    }
}
</script>

<style scoped>
.detail-item {
    padding: 8px 0;
}

.modal-body {
    padding: 20px;
}

.sync-details,
.reject-details {
    padding: 15px;
    border-radius: 8px;
}

.form-control {
    background-color: rgba(250, 250, 250, 0.1);
    border: 1px solid rgba(250, 250, 250, 0.2);
    color: inherit;
}

.form-control:focus {
    background-color: rgba(250, 250, 250, 0.15);
    border-color: rgba(250, 250, 250, 0.3);
    color: inherit;
    box-shadow: none;
}

.badge {
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 13px;
}

.table td {
    vertical-align: middle;
}

.btn-group .btn {
    margin: 0 2px;
}

#last_td {
    position: relative;
}

#last_td .hover-icons {
    width: max-content;
    position: absolute;
    top: 30px;
    right: 20px;
    display: none;
}

#last_td .hover-icons .hover-icon {
    font-size: 23px;
    font-weight: bold;
}

#tr:hover {
    background: rgba(0, 0, 0, 0.6);
}

#tr:hover .hover-icons {
    display: block;
}

#tr:hover #last_td span {
    filter: blur(3px);
}

#tr {
    border-bottom: 1px solid rgba(250, 250, 250, 0.1) !important;
}

.hover-icon {
    cursor: pointer;
    margin: 0 8px;
    font-size: 16px;
}

.hover-icon:hover {
    transform: scale(1.2);
    transition: transform 0.2s ease;
}

#thead {
    border-bottom: 1px solid rgba(250, 250, 250, 0.1);
}

.custom-dropdown {
    background-color: transparent;
    border: 1px solid rgba(250, 250, 250, 0.2);
    color: inherit;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    position: relative;
}

.custom-dropdown::after {
    content: '\25BC';
    /* Chevron down icon */
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: rgba(250, 250, 250, 0.5);
}

.custom-dropdown:focus {
    border-color: rgba(250, 250, 250, 0.3);
    box-shadow: none;
    outline: none;
}

.dropdown-menu {
    background-color: transparent;
}

.custom-dropdown {
    padding-right: 40px !important;
    /* Add more space on the right for the chevron */
}

.custom-dropdown::after {
    right: 20px;
    /* Adjust chevron position */
    margin-left: 8px;
    /* Add space between text and chevron */
}

.dropdown-menu li {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2);
}

.dropdown-menu li:last-child {
    border-bottom: none;
}

.dropdown-menu .dropdown-item {
    padding: 8px 16px;
    color: white;

}

.dropdown-menu .dropdown-item:hover {
    color: black;
}

img {
    margin-right: 10px;
}
</style>
