<template>
  
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="card">
        <div class="modal-header mb-4" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
          <h6 class="modal-title" style="margin-left: 6%;"> {{ $t("diciplinary.title") }}</h6>
          <button type="button" class="btn-close" @click="resetForm" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body border-0">
          <div class="tab-content mb-4">
            <div class="container">
              <form @submit.prevent="sendCouncilDisciplinary" ref="form" class="needs-validation" novalidate>
                <div class="container">
                  <div class="mb-3 text-start">
                    <label for="matieres" class="form-label">
                      {{ $t("diciplinary.label1") }}<em style="color: #f60000">*</em>
                       
                      
                    </label>

                    <div class="row mt-3 ps-3">
                      <div v-for="matiere in motifsList" :key="matiere.id" class="form-check col-3 mt-2">
                        <input class="form-check-input" type="checkbox" :id="`matiere-${matiere.id}`"
                          :value="matiere.name" v-model="selectedMatieres"
                          :class="{ 'is-invalid': formSubmitted && selectedMatieres.length === 0 }" required>
                        <label class="form-check-label" :for="`matiere-${matiere.id}`">
                          {{ matiere.name }}
                        </label>
                      </div>
                      <div class="invalid-feedback"
                        :style="{ display: formSubmitted && selectedMatieres.length === 0 ? 'block' : 'none' }">
                        {{ $t("diciplinary.error1") }}
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <label class="form-label">{{ $t("diciplinary.label2") }}<em style="color: #f60000">*</em></label>
                      <input id="input" :class="{ 'is-invalid': formSubmitted && !councilDate }" v-model="councilDate"
                        style="height: 50px;" type="date" class="form-control" required>
                      <div class="invalid-feedback"
                        :style="{ display: formSubmitted && !councilDate ? 'block' : 'none' }">
                        {{ $t("diciplinary.error2") }}
                      </div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label class="form-label">{{ $t("diciplinary.label3") }}<em style="color: #f60000">*</em></label>
                      <input id="input" v-model="councilTime" :class="{ 'is-invalid': formSubmitted && !councilTime }"
                        style="height: 50px;" type="time" class="form-control" required>
                      <div class="invalid-feedback"
                        :style="{ display: formSubmitted && !councilTime ? 'block' : 'none' }">
                        {{ $t("diciplinary.error3") }}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
          <argon-button type="button" class="btn" title="Fermer" data-bs-dismiss="modal"
            style="width: 200px; height: 50px; padding-top: 15px; background: none; border: 1px solid rgba(255, 255, 255, 0.4);">
            <span>{{ $t("button.cancel") }}</span>
          </argon-button>
          <argon-button @click="sendCouncilDisciplinary" type="button" class="btn me-5" color="success"
            variant="gradient" style="width: 200px; height: 50px; padding-top: 15px" :loading="loading"
            :active="buttonActive || showConfirmation" :clickEvent="button">
            <span v-if="!loading">{{ $t("button.send") }}</span>
          </argon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";
import { postNoteAvailable } from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: 'Modal_Dicipline',
  components: {
    ArgonButton
  },
  props: {

    registrationNumber: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      buttonActive: false,
      showConfirmation: false,
      selectedMatieres: [],
      formSubmitted: false,
      councilDate: '',
      councilTime: '',
      showValidation: false,
      school_id: '',
      errors: {
        motifs: false
      },

    }
  },
  mounted() {
    this.school_id = localStorage.getItem("schoolId");

  },

  computed: {
    motifsList() {
      return [
        { id: 1, name: this.$t('diciplinary.violence') },
        { id: 2, name: this.$t('diciplinary.harassment') },
        { id: 3, name: this.$t('diciplinary.vandalism') },
        { id: 4, name: this.$t('diciplinary.substances') },
        { id: 5, name: this.$t('diciplinary.insubordination') },
        { id: 6, name: this.$t('diciplinary.onlineBehavior') },
        { id: 7, name: this.$t('diciplinary.absences') }
      ]
    }
  },

  methods: {


    resetForm() {
      this.selectedMatieres = [];
      this.councilDate = '';
      this.councilTime = '';
      this.showValidation = false;
      this.errors.motifs = false;
      this.formSubmitted = false;
      this.$refs.form.classList.remove('was-validated');
    },



    validateForm() {
      let isValid = true;
      this.formSubmitted = true;

      this.errors = {
        motifs: false
      };

      if (this.selectedMatieres.length === 0) {
        this.errors.motifs = true;
        isValid = false;
      }

      if (!this.councilDate || !this.councilTime) {
        isValid = false;
      }

      return isValid;
    },

    async sendCouncilDisciplinary() {
      this.showValidation = true;
      if (!this.validateForm()) {
        this.$refs.form.classList.add('was-validated');
        return;
      }

      if (this.loading) return;

      this.loading = true;
      this.button = "disabled";
      this.buttonActive = true;

      try {
        const data = {
          school_id: this.school_id,
          code: 4105,
          registration_number_list: [this.registrationNumber],
          council_date: this.councilDate,
          council_time: this.councilTime,
          reason_list: this.selectedMatieres
        };

        
        

        const response = await postNoteAvailable(data);

        if (response.status === 200) {
          if (!this.showConfirmation) {
            this.showConfirmation = true;
            const message = this.$t("diciplinary.success_message");
            toast.success(message, {
              autoClose: 3000,
            });
            
            this.resetForm();
          }
        }
      } catch (error) {
        toast.error("Erreur lors de l'envoi de la notification", {
          autoClose: 3000,
        });
      } finally {
        this.loading = false;
        this.button = "";
        this.buttonActive = false;
        this.showConfirmation = false;
      }
    }
  }
}
</script>

<style scoped>
.modal.show {
  display: block;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid rgba(250, 250, 250, 0.4);
  border-radius: 5px;
  color: white;
}

.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #75acff;
  border: 1px solid #75acff;
}

.modal-lg {
  max-width: 800px;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.invalid-feedback {
  color: #dc3545;
  font-size: 0.875em;
  margin-top: 0.25rem;
}

.was-validated .form-control:invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid {
  border-color: #dc3545;
}

.is-invalid {
  border-color: #dc3545 !important;
  border-width: 1px;
}
</style>
