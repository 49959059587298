import { createApp } from 'vue';
import App from '@/App.vue';
import store from '@/store';
import 'bootstrap-icons/font/bootstrap-icons.css'; 

import router from '@/router';
import '@/assets/css/nucleo-icons.css';
import '@/assets/css/nucleo-svg.css';
import ArgonDashboard from './dashboard';
import '@/axios';
import i18n from '@/i18n';  
import '@fortawesome/fontawesome-free/css/all.css';


// Get browser lang or lang saved in localstorage
function getBrowserLocale() {
  const savedLocale = localStorage.getItem('locale');  // Verify of lang was saved
  if (savedLocale) {
    return savedLocale;
  }

  const language = navigator.language || navigator.userLanguage;  // Get browser lang
  const supportedLocales = ['fr', 'en', 'es']; // Supported language
  const locale = language.split('-')[0]; // keep main (eg : 'en' for 'en-US')

  // Get supported language else keep fr
  return supportedLocales.includes(locale) ? locale : 'fr';
}

// On initialise Vue i18n en modifiant la configuration existante avec la langue par défaut
i18n.global.locale = getBrowserLocale(); // Définit la langue basée sur celle du navigateur ou locale

// Créer et configurer l'instance de l'application Vue
const appInstance = createApp(App);

// Utilisation des plugins dans l'application
appInstance.use(i18n);               // Utilisation de i18n pour la gestion des langues
appInstance.use(router);             // Utilisation du routeur
appInstance.use(store);              // Utilisation du store (vuex)
appInstance.use(ArgonDashboard);     // Utilisation du tableau de bord

// Mount app in div with attr id='app'
appInstance.mount('#app');
