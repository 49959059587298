<template>
  <NetworkStatus v-if="isOffline" />
  <div v-else>
    <div class="p-5 container-fluid">
      <div class="description">
        <div class="student-form-title">
          <h5> {{ $t("new_student.title") }}</h5>
        </div>
        <div class="student-form">
          <form class="form col-xs-7 col-md-6 col-lg-7">

            <div class="mb-3">

              <label style="transform:translateY(7px)">{{ $t("new_student.last_name") }} <em
                  style="color: #f60000">*</em></label>
              <input type="text" class="form-control" style="text-transform: capitalize; height: 50px;" :class="{
                'required': showError.first_name,
                'required-thick': showError.first_name,
              }" :placeholder="$t('new_student.first_name')" autocomplete="off" v-model="first_name"
                @input="validateField('first_name')">
              <small class="text-danger" v-if="showError.first_name">
                {{ $t("new_student.required_field") }}
              </small>
            </div>

            <!-- Last Name Input -->
            <div class="mb-3">
              <label style="transform:translateY(7px)">{{ $t("new_student.first_name") }}<em
                  style="color: #f60000">*</em></label>
              <input type="text" class="form-control" style="text-transform: capitalize; height: 50px;" :class="{
                'required': showError.last_name,
                'required-thick': showError.last_name,
              }" :placeholder="$t('new_student.last_name')" v-model="last_name" autocomplete="off"
                @input="validateField('last_name')">
              <small class="text-danger" v-if="showError.last_name">
                {{ $t("new_student.required_field") }}
              </small>
            </div>

            <!-- Gender Input -->
            <div class="mb-3">
              <div class="p-1">
                <label style="transform:translateY(7px)">{{ $t("new_student.gender") }} <em
                    style="color: #f60000">*</em></label>
              </div>
              <div class="mb-3 p-1" style="display: flex; flex-direction: row"
                :class="{ 'required': showError.gender }">
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="M" v-model="gender" id="flexRadioDefault1"
                    @change="validateField('gender')" />
                  <label class="form-check-label" for="flexRadioDefault1">
                    {{ $t("new_student.M") }}
                  </label>
                </div>
                <div class="form-check mx-5">
                  <input class="form-check-input" type="radio" value="F" v-model="gender" id="flexRadioDefault2"
                    @change="validateField('gender')" />
                  <label class="form-check-label" for="flexRadioDefault2">
                    {{ $t("new_student.F") }}
                  </label>
                </div>
              </div>
              <small class="text-danger" v-if="showError.gender">
                {{ $t("new_student.required_field") }}
              </small>
            </div>

            <div class="mb-3">
              <label style="transform:translateY(14px)">{{ $t("new_student.phone") }} <em
                  style="color: #f60000">*</em></label>
              <p v-if="phoneValidationMessage" style="transform:translateY(10px)"
                class="validation-message text-danger">
                {{ phoneValidationMessage }}
              </p>

              <p v-if="!phoneValidationMessage" class="mt-0 py-0 px-1"
                style="font-size: 12px; transform:translateY(7px);">
                {{ $t("new_student.phone_text") }}
              </p>

              <div class="tag-input" :class="{ 'required': showError.phone }">
                <div class="tags-container">
                  <div v-for="(tag, index) in tags" :key="index" class="tag-input__tag"
                    :title="tag.is_on_eska ? $t('messages.number_on_eska') : $t('messages.number_not_on_eska')" :style="{
                      backgroundColor: tag.is_on_eska ? '#2980b9' : '#2980b9',
                      opacity: tag.is_on_eska ? '1' : '0.8',
                    }">
                    <img v-if="tag.is_on_eska" src="@/assets/img/eska.png" class="eska-tag-logo me-1" alt="Eska">
                    <i v-else class="bi bi-question-circle-fill me-1"></i>
                    {{ tag.number }}
                    <span class="text-danger" @click="removeTag(index)"><i class="fa fa-close"></i></span>
                  </div>
                </div>
                <div class="input-container">
                  <input type="text" :placeholder="$t('new_student.placeholder_number')" class="tag-input__text"
                    @keydown="checkKey" @input="handlePhoneInput($event)" :disabled="isCheckingNumber" pattern="[0-9]*"
                    maxlength="12" autocomplete="off" />
                  <div v-if="isCheckingNumber" class="spinner-container">
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
              <small class="text-danger" v-if="showError.phone">
                {{ $t("new_student.required_field") }}
              </small>
            </div>



            <div class="mb-3">
              <label style="transform:translateY(7px)">{{ $t("new_student.place_birth") }}</label>
              <argon-input :placeholder="$t('new_student.place_birth')" class="p-1" v-model="pob" maxlength="50"
                autocomplete="off"></argon-input>
            </div>


            <div class="mb-3">
              <label style="transform:translateY(7px)">{{ $t("new_student.date") }}</label>
              <div class="d-flex">
                <select style="height: 50px" v-model="dobDay" class="form-select me-2">
                  <option style="height: 50;" value="">{{ $t("new_student.day") }}</option>
                  <option v-for="day in 31" :key="day" :value="day"> {{ day }}</option>
                </select>
                <select style="height: 50px" v-model="dobMonth" class="form-select me-2">
                  <option value="">{{ $t("new_student.month") }} </option>
                  <option style="text-transform: capitalize;" v-for="month in 12" :key="month" :value="month">
                    {{ getMonthName(month) }}
                  </option>
                </select>

                <select style="height: 50px" v-model="dobYear" class="form-select">
                  <option value="">{{ $t("new_student.year") }} </option>
                  <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                </select>
              </div>
            </div>
            <div class="mb-3">
              <label style="transform:translateY(14px)"> {{ $t("new_student.number") }}<em
                  style="color: #f60000">*</em></label>
              <p class="mt-0 py-0 px-1" style="font-size: 12px; transform:translateY(7px)">
                {{ $t("import_student.generate_text") }}
              </p>

              <div class="row">
                <div class="input-group mb-3">
                  <input type="text" :class="{
                    'form-control': true,
                    'text-uppercase': true,
                    required: requiredFields.registration_number,
                    'required-thick': requiredFields.registration_number,
                  }" v-model="registration_number" style="height: 50px" placeholder="Ex: 1011002457" />

                  <div class="d-flex align-items-center">
                    <div v-if="isGeneratingNumber" class="spinner-border spinner-border-sm ms-2" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <div class="dropdown" id="dropdownMenuButton1" title="Genérer le numéro le matricule">
                    <button class="btn bg-success text-light fw-bold dropdown-toggle" type="button"
                      id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                      :disabled="isGeneratingNumber">
                      {{ $t("new_student.gendered") }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li class="nav-link text-light fw-bold" style="border-bottom: 1px solid rgba(250, 250,250, 0.6)"
                        @click="generateMat(false)">
                        {{ $t("new_student.old_student") }}
                      </li>
                      <li class="nav-link text-light fw-bold" @click="generateMat(true)">
                        {{ $t("new_student.new_student") }}
                      </li>
                    </ul>

                  </div>

                </div>
                <small class="text-danger mt-n5" v-if="showError.registration_number">
                  {{ $t("new_student.required_field") }}
                </small>

              </div>
            </div>

            <div class="row p-1  pt-0 mt-n2 ">

              <div class="col-lg-6">
                <argon-button :loading="loading" :active="buttonActive || showConfirmation" size="lg" color="success"
                  full-width @click="newStudent" :clickEvent="button">
                  <span>{{ $t("button.save") }}</span>
                </argon-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from "@/components/ArgonInput";
import ArgonButton from "@/components/ArgonButton";
import { ref } from "vue";
import { createStudent, uploadFile, getregisternumber, getchecknumberEska } from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import NetworkStatus from "@/views/components/NetworkStatus.vue";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "student-form",
  components: { ArgonButton, ArgonInput, NetworkStatus },

  data() {
    return {
      showError: {
        first_name: false,
        last_name: false,
        gender: false,
        registration_number: false,
        phone: false
      },
      isGeneratingNumber: false,
      isCheckingNumber: false,
      button: "",
      required: "",
      successMessage: "",
      registration_number: "",
      phoneValidationMessage: '',
      buttonActive: false,
      showConfirmation: false,
      failedMessage: "",
      classroom_id: this.$route.params.classroomId,
      school_year: new Date().getFullYear(),
      first_name: "",
      last_name: "",
      paymentType: '',
      gender: "",
      phone_list: [],
      is_new_student: true,
      pob: "",
      dobDay: "",
      dobMonth: "",
      dobYear: "",
      currentYear: new Date().getFullYear(),
      isOffline: false,
      apiError: false,
      tags: [],
      loading: false,
      requiredFields: {
        first_name: false,
        registration_number: false,
      },
    };
  },

  setup() {
    let dropzoneFile = ref("");
    let file_path = ref("");
    const drop = async (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
      let res = await uploadFile(dropzoneFile.value);
      file_path = res["path"];
    };
    const selectedFile = async () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
      let res = await uploadFile(dropzoneFile.value);
      file_path = res["path"];
    };

    return {
      dropzoneFile,
      drop,
      selectedFile,

      file_path,
    };
  },


  async mounted() {
    // Initialiser l'état de connexion au montage
    await this.updateOnlineStatus();
    this.loadPaymentType();

    // Ajouter les event listeners pour les changements de réseau
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    this.intervalId = setInterval(this.checkInternetConnection, 1000);


    // Charger les données initiales
    if (!this.isOffline) {

      this.loadPaymentType();
    }
  },




  beforeUnmount() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

  computed: {
    dob() {
      if (this.dobDay && this.dobMonth && this.dobYear) {
        return `${this.dobYear}-${this.dobMonth.toString().padStart(2, '0')}-${this.dobDay.toString().padStart(2, '0')}`;
      }
      return null;
    },
    years() {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let year = currentYear; year >= 1900; year--) {
        years.push(year);
      }
      return years;
    },
    hasUnsavedPhone() {
      const input = document.querySelector('.tag-input__text');
      return input && input.value.length > 0;
    }
  },


  methods: {
    validateField(fieldName) {
      switch (fieldName) {
        case 'first_name':
          this.showError.first_name = !this.first_name.trim();
          break;
        case 'last_name':
          this.showError.last_name = !this.last_name.trim();
          break;
        case 'gender':
          this.showError.gender = !this.gender;
          break;
        case 'registration_number':
          this.showError.registration_number = !this.registration_number.trim();
          break;
        case 'phone':
          this.showError.phone = this.tags.length === 0;
          break;
      }
    },

    async updateOnlineStatus() {
      // Vérifier si le navigateur est en ligne
      if (navigator.onLine) {
        try {
          // Vérifier si les API sont accessibles
          await this.checkConnection();
          this.isOffline = false; // Tout fonctionne, on est en ligne
        } catch (error) {
          console.error("Error checking connection:", error);
          this.isOffline = true; // Les API ne répondent pas, on est hors ligne
        }
      } else {
        // Pas de connexion internet détectée
        this.isOffline = true;
      }
    },

    async checkConnection() {
      try {
        // Essayer les deux appels API
        this.loadPaymentType();
        this.apiError = false; // Les API répondent correctement
      } catch (error) {
        console.error("API Error:", error);
        this.apiError = true; // Les API ne répondent pas
      }
    },


    handlePhoneInput(event) {
      // Permet uniquement les chiffres
      event.target.value = event.target.value.replace(/\D/g, '');

      // Limite la longueur à 9 chiffres (sans compter le préfixe 237)
      if (event.target.value.length > 9) {
        event.target.value = event.target.value.slice(0, 9);
      }

      // Réinitialise l'erreur si des numéros sont déjà ajoutés
      if (this.tags.length > 0) {
        this.showError.phone = false;
        this.phoneValidationMessage = '';
      }
    },

    async checkKey(event) {
      if (event.key === 'Enter' || event.key === ' ' || event.key === ',' || event.key === ';') {
        event.preventDefault();
        let val = event.target.value.trim();

        // Vérifie si la valeur contient uniquement des chiffres
        if (!/^\d+$/.test(val)) {
          toast.error(this.$t("new_student.invalid_phone_number"), {
            autoClose: 3000,
          });
          return;
        }

        if (val.length > 0) {
          // Ajouter le préfixe 237 si nécessaire
          if (!val.startsWith('237')) {
            val = '237' + val;
          }

          // Vérifier si le numéro a la bonne longueur
          if (val.length !== 12) { // 237 + 9 chiffres
            toast.error(this.$t("new_student.invalid_phone_length"), {
              autoClose: 3000,
            });
            return;
          }

          // Vérifier si le numéro existe déjà
          const numberExists = this.tags.some(tag => tag.number === val);
          if (numberExists) {
            toast.error(this.$t("new_student.number_already_exists"), {
              autoClose: 3000,
            });
            event.target.value = "";
            return;
          }

          try {
            this.isCheckingNumber = true;
            const response = await getchecknumberEska({
              number_list: [val]
            });

            if (response.available?.includes(val)) {
              this.tags.push({
                number: val,
                is_on_eska: true
              });
              this.eska = false;
            } else {
              this.tags.push({
                number: val,
                is_on_eska: false
              });
              this.eska = true;
            }
            event.target.value = "";

            // Réinitialise l'erreur quand un numéro est ajouté
            this.showError.phone = false;
            this.phoneValidationMessage = '';

          } catch (error) {
            console.error("Error checking number:", error);
            toast.error(this.$t("new_student.error_checking_number"), {
              autoClose: 3000,
            });
          } finally {
            this.isCheckingNumber = false;
          }
        }
      }
    },

    removeTag(index) {
      this.tags.splice(index, 1);
      // Réactive la validation si tous les numéros sont supprimés
      if (this.tags.length === 0) {
        this.validateField('phone');
      }
    },

    getMonthName(monthNumber) {
      const date = new Date(2000, monthNumber - 1, 1);
      const currentLocale = this.$i18n.locale; // Get current locale from i18n
      return date.toLocaleString(currentLocale, { month: 'long' });
    },

    formattedLastName: {
      get() {
        return this.last_name.charAt(0).toUpperCase() + this.last_name.slice(1);
      },
      set(value) {
        this.last_name = value;
      }
    },
    formattedFirstName: {
      get() {
        return this.first_name.charAt(0).toUpperCase() + this.first_name.slice(1);
      },
      set(value) {
        this.first_name = value;
      }
    },
    loadPaymentType() {
      this.paymentType = localStorage.getItem('payment_type');
    },
    resetForm() {
      this.first_name = "";
      this.last_name = "";
      this.gender = false;
      this.pob = "";
      this.dob = "";
      this.tags = [];
    },


    async newStudent() {
      try {
        // Vérifier si déjà en cours de chargement
        if (this.loading) {
          return;
        }

        // Activer l'état de chargement
        this.loading = true;
        this.button = "disabled";

        // Valider tous les champs obligatoires
        this.validateField('first_name');
        this.validateField('last_name');
        this.validateField('gender');
        this.validateField('registration_number');
        this.validateField('phone');

        // Vérifier si des erreurs de validation sont présentes
        const hasValidationErrors = Object.values(this.showError).some(error => error);

        // Vérifier les champs obligatoires
        const requiredFieldsEmpty = !this.first_name || !this.last_name || !this.gender || !this.registration_number ||
          this.tags.length === 0;

        if (hasValidationErrors || requiredFieldsEmpty) {

          return;
        }

        // Vérifier le numéro de téléphone non sauvegardé
        if (this.hasUnsavedPhone) {
          this.phoneValidationMessage = this.$t("new_student.save_phone_number");
          document.querySelector('.tag-input').classList.add('required');
          return;
        }

        // Créer l'objet de données de l'étudiant
        const studentData = {
          classroom_id: this.classroom_id,
          school_year: this.school_year,
          registration_number: this.registration_number.toUpperCase(),
          first_name: this.first_name.trim(),
          last_name: this.last_name.trim(),
          gender: this.gender,
          phone_list: this.tags,
          pob: this.pob,
          dob: this.dob,
          photo_url: this.file_path,
          payment_type: this.paymentType,
        };

        // Appeler l'API pour créer l'étudiant
        const response = await createStudent(studentData);

        if (response.status === 201) {
          // Afficher le message de succès
          toast.success(this.$t("new_student.success_message"), {
            autoClose: 3000,
          });

          // Réinitialiser le formulaire et rediriger
          setTimeout(() => {
            this.resetForm();
            this.$router.push({
              name: "class-details",
              params: { classId: this.classroom_id }
            });


          }, 3000);
        } else {
          throw new Error('Failed to create student');
        }

      } catch (error) {
        console.error('Error creating student:', error);

        // Gérer les différents types d'erreurs
        if (error.response) {
          switch (error.response.status) {
            case 403:
              toast.error(this.$t("new_student.already_exists"), {
                autoClose: 3000,
              });
              break;
            case 400:
              toast.error(this.$t("new_student.invalid_data"), {
                autoClose: 3000,
              });
              break;
            default:
              toast.error(this.$t("message.error"), {
                autoClose: 3000,
              });
          }
        } else {
          toast.error(this.$t("message.error"), {
            autoClose: 3000,
          });
        }

      } finally {
        // Réinitialiser les états
        this.loading = false;
        this.button = "";
        this.buttonActive = false;
        this.showConfirmation = false;
      }
    },


    async generateMat(isNewStudent) {
      try {
        this.isGeneratingNumber = true; // Activez le spinner
        this.registration_number = '';

        let data = {
          registration_number: this.registration_number,
          is_new_student: isNewStudent,
        };

        let response = await getregisternumber(data);

        if (!response.exist) {
          this.registration_number = response.registration_number;
        } else {
          toast.error("Le numéro de matricule existe déjà", { autoClose: 3000 });
        }
      } catch (error) {
        console.error(error);
        toast.error("Une erreur s'est produite lors de la génération du numéro de matricule", { autoClose: 3000 });
      } finally {
        this.isGeneratingNumber = false; // Désactivez le spinner
      }
    },

    addTag(event) {
      let val = event.target.value.trim();
      if (val.length > 0) {
        let newTags = val.split(/[,;]/);
        newTags.forEach(tag => {
          tag = tag.trim();
          // Add 237 prefix if not already present
          if (!tag.startsWith('237')) {
            tag = '237' + tag;
          }
          if (tag.length > 0 && !this.tags.includes(tag)) {
            this.tags.push(tag);
          }
        });
        event.target.value = "";
      }
    },


    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.tags.length - 1);
      }
    },
  },
  created() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },

};
</script>

<style scoped lang="scss">
.required {
  border: 2px solid #f13939 !important;
  border-radius: 11px;
  animation: shake 0.5s;
}

.required-thick {
  border-width: 3px !important;
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  75% {
    transform: translateX(5px);
  }
}

.validation-message {
  font-size: 0.875rem;
  color: #f13939;
  font-weight: 500;
}



.student-form {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
}

.student-form-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eska-tag-logo {
  width: 16px;
  height: 16px;
  object-fit: contain;
  vertical-align: middle;
  border-radius: 50%;
}

.bi-question-circle-fill {
  font-size: 14px;
  color: white;
}

.tag-input {
  width: 100%;
  border: 1px solid #d9dfe7;
  background: #fff;
  border-radius: 10px;
  font-size: 0.9em;
  min-height: 45px;
  box-sizing: border-box;
  padding: 0 10px;
  margin-bottom: 10px;
}

.tag-input__tag {
  height: 24px;
  color: white;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  background-color: #2980b9;
  border-radius: 15px;
  margin-top: 10px;
  line-height: 24px;
  padding: 0 8px;
}

.tag-input__tag>span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}

.required {
  border: 2px solid #f13939 !important; // Modifiez cette ligne
  border-radius: 11px;
}


.required-thick {
  border-width: 3px !important;
}

#text {
  font-size: 12px;

}

#dropdownMenuButton1 {
  margin-top: 10px;
}

#dropdownMenuButton1 .btn {
  transform: translateY(-20px);
  height: 50px;
  border-radius: 0px 10px 10px 0px;
  width: 150px;
}

#dropdownMenuButton1 .dropdown-menu {
  padding: 0;
}

#dropdownMenuButton1 .dropdown-menu .nav-link {
  transition: 0.3s;
  height: 45px;
  padding-top: 13px;

}

#dropdownMenuButton1 .dropdown-menu .nav-link:hover {
  background: rgba(0, 0, 0, 0.3);
}

.tag-input.required {
  border: 2px solid #f13939;
  animation: shake 0.5s;
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  75% {
    transform: translateX(5px);
  }
}

.spinner-border {
  width: 1rem;
  height: 1rem;
  color: #2980b9;
}

.tag-input__text:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
}

.tag-input {
  width: 100%;
  border: 1px solid #d9dfe7;
  background: #fff;
  border-radius: 10px;
  font-size: 0.9em;
  min-height: 50px;
  box-sizing: border-box;
  padding: 9px 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;  
  align-items: flex-start;
  gap: 5px;  
   
}

.tag-input__tag {
  height: 24px;
  color: white;
  font-size: 14px;
  background-color: #2980b9;
  border-radius: 15px;
  margin: 2px;
  line-height: 24px;
  padding: 0 8px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 24px;
  background: none;
  flex: 1;
  min-width: 100px;  
  margin: 2px;
}
 
.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
}

 
.input-container {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 120px;  
}

 
.spinner-container {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

</style>
