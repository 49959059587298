<template>
  <div class="row ">
    <div class="col-md-10 ">
      <Studentssettledtable
        :Students="studentList"
        :configInstallments="configInstallments"
      />
    </div>
  </div>
</template>

<script>
import Studentssettledtable from "@/views/Student/Studentssettledtable.vue";

import {
  getStudentClassrooms,
  getcreateconfig,
  applyPaymentConfig,
  getInstallmentsForClassroom,
} from "@/utils/services";
import { ref, onMounted } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "Settled-Students",
  data() {
    return {
      configApplied: null,
      showModal1: false,
      showMenu: false,
      payment_config_id: "",
      studentList: [],
      configInstallments: [],
      showOverlay: true,
      classroom: "",
      classroom_id: this.$route.params.classId,
      school_year: new Date().getFullYear(),
      PendingPayment: {
        classIcon: "bi bi-wallet-fill",
        title: "Pending",
        desc: "Pending payment",
        price: "2000",
      },
      PaymentOverdue: {
        classIcon: "text-white bi bi-wallet-fill",
        title: "Overdue",
        desc: "Overdue Payment",
        price: "455.00",
      },
    };
  },
  async beforeMount() {
    try {
      this.loading = true; // Activez l'indicateur de chargement

      let params = {
        classroom_id: this.classroom_id,
        school_year: this.school_year,
      };

      let res = await getStudentClassrooms(params);
      this.studentList = res.data["students"];
      this.classroom = res.data["classroom"];
      
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false; // Désactivez l'indicateur de chargement une fois la requête terminée
    }
  },

  components: {
    Studentssettledtable,
  },

  setup() {
    const config = ref([]);
    const loading = ref(false);

    const fetchconfig = async () => {
      try {
        loading.value = true;
        const result = await getcreateconfig();

        if (result.status === 200) {
          config.value = result.data;
        } else {
          console.error(
            "Erreur lors de la récupération des membres :",
            result.error
          );
        }
      } catch (error) {
        console.error(
          "Erreur inattendue lors de la récupération des membres :",
          error
        );
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchconfig();
    });

    return {
      config,
      loading,
    };
  },

  computed: {
    numberOfSettledStudents() {
      // Utilisez la nouvelle méthode pour obtenir la liste des étudiants "settled" et compter leur nombre
      return this.getSettledStudents().length;
    },
  },

  methods: {
    getSettledStudents() {
      // Utilisez la méthode filter pour obtenir la liste des étudiants avec le statut "settled"
      return this.Students.filter(
        (student) => student.payment_status === "Settled"
      );
    },
    hideModal() {
      this.showModal = false;
      setTimeout(() => {
        window.location.reload(true);
        this.configApplied = true;
      });
    },

    async applyPaymentConfig() {
      try {
        this.loading = true;
        this.button = "disabled";
        let config = this.config;

        let data = {
          payment_config_id: this.payment_config_id,
          classroom_id: this.classroom_id,
        };

       

        if (!this.payment_config_id || !this.classroom_id) {
          const message = this.$t("message.requiredfile");
          toast.info(message, {
            autoClose: 3000,
          });

          return;
        }

        if (
          this.classroom_id === "" ||
          this.classroom_id === null ||
          this.payment_config_id === "" ||
          this.payment_config_id === null
        ) {
          this.required = "required";
          this.button = "";
        } else {
          let res = await applyPaymentConfig(data);

          if (!navigator.onLine) {
            const message = this.$t("message.notinternet");
            toast.error(message, {
              autoClose: 3000,
            });
          } else if (res.status === 404) {
            const message = this.$t("message.user404");
            toast.error(message, {
              autoClose: 3000,
            });
          } else if (res.status === 201) {
            this.showModal1 = true;

            this.configInstallments =
              config.find((c) => c.id === this.payment_config_id)
                ?.installment || [];

            this.showOverlay = false;
            localStorage.setItem("paymentConfigCreated", "true");
            setTimeout(() => {
              this.successMessage = "";
            }, 3000);
          }
        }
      } catch (error) {
        console.error(error);

        if (error.response && error.response.status === 404) {
          // Afficher un message spécifique pour l'erreur 404
          toast.error("User not found. Please check the provided information", {
            autoClose: 3000,
          });
        } else {
          // Afficher un message d'erreur générique pour d'autres erreurs
          toast.error("An error occurred while creating the member", {
            autoClose: 3000,
          });
        }
      } finally {
        this.loading = false;
        this.button = "";
      }
    },
    async loadInstallments() {
      this.loading = true;

      try {
        // Utilisez la fonction pour récupérer les versements
        const configDetails = await getInstallmentsForClassroom(
          this.classroom_id
        );

        if (configDetails.length > 0) {
          // Mettez à jour la propriété configInstallments avec les versements récupérés
          this.configInstallments = configDetails || [];
        } else {
          console.warn("Aucun détail de configuration trouvé.");
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des détails de la configuration:",
          error
        );
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.configId = this.$route.classroom_id;
    this.loadInstallments();
  },
};
</script>

<style lang="scss">
.main1 {
  position: relative;
}

#cl-student-import {
  .dropdown-item:hover {
    span {
      color: #1a2035;
    }
  }
}

.nbrset {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding-top: 5px;
  margin-left: 7%;
}

.information {
  display: none;
  position: relative;

  .overlay {
    position: absolute;
    height: 100%;
    top: 0;
    left: 2.3%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.95);
    border-radius: 10px;
    width: 97.5%;
    text-align: center;

    .select {
      width: 30%;
      height: 50px;
      margin-left: 30%;
      font-size: 17px;
      border: 1px solid #eb4d4b;
      color: white;
      background-color: transparent;
      option {
        color: black;
      }
    }
  }
}

.menu-students {
  li:hover {
    h6 {
      span {
        color: black;
      }
    }
  }
}

.modal1 {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  width: 100%;
  height: 120%;
  z-index: 100;
  left: 0;
  top: -100px;
  display: none;
}

.modal-active1 {
  display: block;
  /* Styles spécifiques pour afficher la modale */
}

.modal1 .modal-content {
  width: 70%;
  margin-left: 13%;
  margin-right: auto;
  text-align: center;
  margin-top: 10%;
  background: none;
}
.modal1 .modal-content .card {
  padding-bottom: 40px;
  margin-top: 10%;
}
.modal1 .modal-content .card .card-body p {
  margin-top: 10%;
  font-size: 20px;
}
</style>
