<template>

<NetworkStatus v-if="isOffline" />
<div v-else>  
  <!--========================= modal add group =================================== -->

  <div
    class="modal fade"
    id="staticBackdrop23"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div
            class="modal-header"
            style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)"
          >
            <h5 class="modal-title" id="staticBackdropLabel3"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-center pt-4 pb-4">
            <div class="text">
              <i
                style="color: #f1c40f; font-size: 70px"
                class="bi bi-check2-circle"
              ></i>

              <h6 class="text-xl ps-4 mb-3 font-weight-bolder">
                {{ $t("newpayment.modal_title") }}
              </h6>

              <span class="nav-link-text"> {{ $t("newpayment.modal_p") }}</span>
            </div>

            <div class="row mt-4">
              <div class="col-md-6 text-end">
                <argon-button
                  color="success"
                  size="xl"
                  variant="outline"
                  @click="redirectinstallment"
                  style="width: 200px; height: 50px; padding-top: 15px"
                  ><i class="fas fa-plus me-2"></i>
                  {{ $t("newpayment.modal_button_installment") }}
                </argon-button>
              </div>

              <div class="col-md-6 text-start">
                <argon-button
                  color="success"
                  @click="hideModal"
                  :disabled="loading"
                  style="width: 200px; height: 50px; padding-top: 15px"
                >
                  <span v-if="!loading"
                    ><i class="fas fa-plus me-2"></i>
                    {{ $t("newpayment.modal_button_group") }}</span
                  >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </argon-button>
              </div>
            </div>
          </div>
          <div
            class="modal-footer mt-6"
            style="border-top: 1px solid rgba(255, 255, 255, 0.4)"
          >
           <router-link  to="/payment-config">
            <argon-button
              type="button"
               class="btn bg-transparent border border-info"
             
              style="width: 150px; height: 50px; padding-top: 15px"           
            >
              {{ $t("button.cancel") }}
            </argon-button>
          </router-link>
          
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-----------------------------fin modal -------------------->

  <div class="p-5 container-fluid" style="margin-bottom: 120px">
    <div class="description">
      <div class="student-form-title">
        <p class="text-center" v-html="$t('newpayment.title')"></p>
      </div>
      <div class="student-form">
        <form class="form col-xs-7 col-md-6 col-lg-7">
          <div class="mb-4" v-if="GRADE_CODE.length">
            <p style="transform: translateY(10px)" class="small">
              {{ $t("newpayment.grade") }}
            </p>
            <select
              style="height: 50px"
              class="form-control form-select"
              v-model="grade_code"
            >
              <option
                v-for="item in GRADE_CODE"
                :key="item.code"
                :value="item.name"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="mb-4" v-if="SPECIALITY_CODE.length">
            <p style="transform: translateY(10px)" class="small">
              {{ $t("newpayment.speciality") }}
            </p>
            <select
              class="form-control form-select"
              style="height: 50px"
              v-model="speciality_code"
            >
              <option
                v-for="item in SPECIALITY_CODE"
                :key="item.code"
                :value="item.short_name"
              >
                {{ item.short_name }} - {{ item.title }}
              </option>
              <option> {{$t("updatepayment.no_speciality")}}</option>
            </select>
          </div>

          <div class="row p-1 mt-5 g-3">
             
            <div class="col-lg-6">
              <argon-button
                size="lg"
                color="success"
                full-width
                @click="createconfig"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop23"
                :clickEvent="button"
                :disabled="loading"
              >
                <span>{{ $t("button.save") }}</span>
              </argon-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";
import { createconfig, getGradeCode, getSpeciality } from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { ref } from "vue";
import NetworkStatus from "@/views/components/NetworkStatus.vue";

export default {
  name: "Config-Form",
  components: { ArgonButton,NetworkStatus  },
  data() {
    return {
      speciality_code: "",
      grade_code: "",
      loading: false,
      button: "",
      successMessage: "",
      GRADE_CODE: [],
      SPECIALITY_CODE: [],
      showModal: false,
      createdConfigId: null,
      buttonActive: false,
      showConfirmation: false,
      isOffline: false,  
      apiError: false,
    };
  },

  setup() {
    // Créez une référence à la modal
    const successModal = ref(false);

    return {
      successModal,
      // ... autres propriétés ou méthodes ...
    };
  },

  async mounted() {
    // Initialiser l'état de connexion au montage
    await this.updateOnlineStatus();
    await this.getData();

    // Ajouter les event listeners pour les changements de réseau
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    this.intervalId = setInterval(this.checkInternetConnection, 1000);
    

    // Charger les données initiales
    if (!this.isOffline) {
      
      await this.getData();
    }
  },

  

 
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

  methods: {
    async updateOnlineStatus() {
    // Vérifier si le navigateur est en ligne
    if (navigator.onLine) {
      try {
        // Vérifier si les API sont accessibles
        await this.checkConnection();
        this.isOffline = false; // Tout fonctionne, on est en ligne
      } catch (error) {
        console.error("Error checking connection:", error);
        this.isOffline = true; // Les API ne répondent pas, on est hors ligne
      }
    } else {
      // Pas de connexion internet détectée
      this.isOffline = true;
    }
  },

  async checkConnection() {
    try {
      // Essayer les deux appels API
      await Promise.all([
      getGradeCode()
         
      ]);
      this.apiError = false; // Les API répondent correctement
    } catch (error) {
      console.error("API Error:", error);
      this.apiError = true; // Les API ne répondent pas
    }
  },

    Cancel() {
      this.$router.push({
        name: "payment-config",
      });
    },

    hideModal() {
      this.showModal = false;
      setTimeout(() => {
        window.location.reload(true);
        this.configApplied = true;
      });
    },

    resetForm() {
      this.grade_code = "";
      this.speciality_code = "";
    },

    // Replace the getData method with this updated version
async getData() {
  try {
    // Get grades
    let grades = await getGradeCode();
    if (grades.data && grades.data.length > 0) {
      this.GRADE_CODE = grades.data;
      this.grade_code = grades.data[0].name;
      this.grade_name = grades.data[0].name;
    } else {
      this.GRADE_CODE = [];
      this.grade_code = "";
      this.grade_name = "";
    }

    // Get specialities
    let specialities = await getSpeciality();
    if (specialities.data && specialities.data.length > 0) {
      this.SPECIALITY_CODE = specialities.data;
      this.speciality_code = specialities.data[0].short_name;
    } else {
      this.SPECIALITY_CODE = [];
      this.speciality_code = "";
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    this.GRADE_CODE = [];
    this.SPECIALITY_CODE = [];
    this.grade_code = "";
    this.speciality_code = "";
  }
},

    redirectinstallment() {
      this.$router.push({
        name: "detail-config",
        params: { id: this.createdConfigId },
      });
    },

    async createconfig() {
      try {
        if (this.loading) {
          // Si la soumission est déjà en cours, ne rien faire
          return;
        }
        this.loading = true;
        this.button = "disabled";

        let data = {
          grade_code: this.grade_code,
          speciality_code: this.speciality_code,
        };

        if (!this.grade_code) {
          if (!this.showConfirmation) {
            this.showConfirmation = true;
            const message = this.$t("message.requiredfile");
            toast.error(message, {
              autoClose: 3000,
            });
            setTimeout(() => {
              this.showConfirmation = false;
            }, 4000);
          }
          return;
        }

        let res = await createconfig(data);

        if (!navigator.onLine) {
          const message = this.$t("message.notinternet");
          toast.error(message, {
            autoClose: 3000,
          });
        } else if (res.status === 404) {
          const message = this.$t("message.user404");
          toast.error(message, {
            autoClose: 3000,
          });
        } else if (res.status === 201) {
          this.createdConfigId = res.data.id;

          document.body.classList.remove("modal-open");
          const modalBackdrops =
            document.getElementsByClassName("modal-backdrop");
          for (const backdrop of modalBackdrops) {
            backdrop.parentNode.removeChild(backdrop);
          }

          if (!this.showConfirmation) {
            this.showConfirmation = true;
            setTimeout(() => {
              this.showConfirmation = false;
            });
          }
        }
      } catch (error) {
        console.error(error);

        if (error.response && error.response.status === 404) {
          toast.error("User not found. Please check the provided information", {
            autoClose: 3000,
          });
        } else {
          const message = this.$t("message.duplicateConfig");
          toast.error(message, {
            autoClose: 3000,
          });
        }
      } finally {
        this.loading = false;
        this.button = "";
      }
    },
  },
  beforeMount() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.student-form {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
}

.student-form-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info {
  color: #dc3545;
  font-size: 14px;
  margin-left: 5px;
}

.required {
  border-color: #f60000 !important;
}
</style>
