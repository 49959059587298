<template>
  <NetworkStatus v-if="isOffline" />
  <div v-else>
    <div class="py-4 container">
      <div class="row mt-3 mb-4 text-center">
        <h5 class="mb-5">{{ $t("new_class.title") }}</h5>

        <div class="row mt-3">
          <div class="col-md-6 col-xs-6 col-lg-7 content text-start">
            <div class="p-3 card w-">
              <div class="card-header pb-0"></div>
              <div class="card-body px-0 pt-0 pb-2">
                <div class="mb-4" v-if="GRADE_CODE.length">
                  <p style="transform: translateY(13px)" class="small">
                    {{ $t("new_class.grade") }}
                  </p>
                  <select class="form-control form-select" style="height: 50px" v-model="grade_code">
                    <option v-for="item in GRADE_CODE" :key="item.code" :value="item.code">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div class="mb-4" v-if="SPECIALITY_CODE.length">
                  <p style="transform: translateY(13px)" class="small">
                    {{ $t("new_class.speciality") }}
                  </p>
                  <select class="form-control form-select" style="height: 50px" v-model="speciality">
                    <option v-for="item in SPECIALITY_CODE" :key="item.code" :value="item.short_name">
                      {{ item.short_name }} - {{ item.title }}
                    </option>
                    <option>{{ $t("new_class.no_speciality") }}</option>
                  </select>
                </div>
                <div class="mb-4">
                  <p style="transform: translateY(13px)" class="small">
                    {{ $t("new_class.number") }} <em style="color: #f60000">*</em>
                  </p>
                  <input type="text" class="form-control" style="height: 50px;" :class="{
                    'required': showError.number,
                    'required-thick': showError.number
                  }" :placeholder='$t("new_class.pleceholder")' v-model="number" @input="handleNumberInput"
                    maxlength="2" pattern="[a-zA-Z0-9]*">
                  <small class="text-danger" v-if="showError.number">
                    {{ showError.numberMessage || $t("new_student.required_field") }}
                  </small>
                </div>
                <div class="">
                  <p style="transform: translateY(15px)" class="small">
                    {{ $t("new_class.student_count") }} <em style="color: #f60000">*</em>
                  </p>
                  <span style="font-size: 12px; opacity: 0.8;">{{ $t("new_class.description") }}</span>
                  <argon-input
                    :class="{ 'required': showError.student_count, 'required-thick': showError.student_count }" required
                    type="number" v-model="student_count" @input="validateField('student_count')"></argon-input>
                  <small class="text-danger" style="transform: translateY(-45px);" v-if="showError.student_count">
                    {{ $t("new_student.required_field") }}
                  </small>

                </div>

                <div class="mt-5">
                  <div class="row">

                    <div class="col-md-6">
                      <argon-button :loading="loading" :active="buttonActive || showConfirmation" fullWidth
                        color="success" variant="gradient" class="my-4 mb-2" :clickEvent="button" :disabled="loading"
                        size="lg" @click="createClassroom">
                        {{ $t("button.save") }}
                      </argon-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";
import ArgonInput from "@/components/ArgonInput";
import NetworkStatus from "@/views/components/NetworkStatus.vue";


import { createClassroom, getGradeCode, getSpeciality } from "@/utils/services";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "NewClass",
  components: { ArgonButton, ArgonInput, NetworkStatus },
  data() {
    return {
      GRADE_CODE: [],
      SPECIALITY_CODE: [],
      grade_code: "",
      speciality: "",
      grade_name: "",
      number: "",
      student_count: 0,
      button: "",
      successMessage: "",
      failedMessage: "",
      loading: false,
      buttonActive: false,
      showConfirmation: false,
      isOffline: false,
      apiError: false,
      showError: {
        number: false,
        numberMessage: '',
        student_count: false
      },

    };
  },

  async mounted() {
    // Initialiser l'état de connexion au montage
    await this.updateOnlineStatus();
    this.getData();

    // Ajouter les event listeners pour les changements de réseau
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    this.intervalId = setInterval(this.checkInternetConnection, 1000);


    // Charger les données initiales
    if (!this.isOffline) {

      await this.getData();
    }
  },




  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },


  methods: {

    handleNumberInput(event) {
      // Convert the input to uppercase
      this.number = event.target.value.toUpperCase();
      // Call the validation method
      this.validateField('number');
    },


    validateField(fieldName) {
      const alphanumericRegex = /^[a-zA-Z0-9]*$/; // Déplacé en dehors du bloc case

      switch (fieldName) {
        case 'number':
          if (!this.number.trim()) {
            this.showError.number = true;
            this.showError.numberMessage = this.$t("new_student.required_field");
          } else if (!alphanumericRegex.test(this.number)) {
            this.showError.number = true;
            this.showError.numberMessage = "Only alphanumeric characters are allowed";
          } else if (this.number.length > 2) {
            this.number = this.number.slice(0, 2); // Limite à 2 caractères
          } else {
            this.showError.number = false;
            this.showError.numberMessage = '';
          }
          break;
        case 'student_count':
          this.showError.student_count = !this.student_count || this.student_count <= 0;
          break;
      }
    },

    async updateOnlineStatus() {
      // Vérifier si le navigateur est en ligne
      if (navigator.onLine) {
        try {
          // Vérifier si les API sont accessibles
          await this.checkConnection();
          this.isOffline = false; // Tout fonctionne, on est en ligne
        } catch (error) {
          console.error("Error checking connection:", error);
          this.isOffline = true; // Les API ne répondent pas, on est hors ligne
        }
      } else {
        // Pas de connexion internet détectée
        this.isOffline = true;
      }
    },

    async checkConnection() {
      try {
        // Essayer les deux appels API
        await Promise.all([
          getGradeCode()

        ]);
        this.apiError = false; // Les API répondent correctement
      } catch (error) {
        console.error("API Error:", error);
        this.apiError = true; // Les API ne répondent pas
      }
    },
    async getData() {
      let grades = await getGradeCode();
      this.GRADE_CODE = grades.data;
      this.grade_code = grades.data[0].code;  // Utiliser code au lieu de name
      this.grade_name = grades.data[0].name;  // Garder grade_name pour l'affichage si nécessaire
      let specialities = await getSpeciality();
      this.SPECIALITY_CODE = specialities.data;

      if (specialities.data.length > 0 && specialities.data[0].short_name) {
        this.speciality = specialities.data[0].short_name;
      } else {
        this.speciality = null;
      }
    },
    async createClassroom() {
      // Valider le champ avant la soumission
      this.validateField('number');
      this.validateField('student_count');

      // Si le champ est vide, arrêter la soumission
      if (this.showError.number || this.showError.student_count) {
        return;
      }

      if (this.loading) {
        return;
      }

      this.loading = true;
      this.buttonActive = true;

      if (!navigator.onLine) {
        const message = this.$t("message.notinternet");
        toast.error(message, {
          autoClose: 3000,
        });
        this.buttonActive = false;
        this.loading = false;
        return;
      }

      try {
        let data = {
          number: this.number,
          speciality_code: this.speciality,
          grade_code: this.grade_code,
          student_count: parseInt(this.student_count),
        };

        let response = await createClassroom(data);

        if (response.status === 404) {
          toast.error(this.$t("message.user404"), {
            autoClose: 3000,
          });
        } else if (response.status === 403) {
          toast.error(this.$t("new_class.impossible_add_class"), {
            autoClose: 3000,
          });
        } else if (response.status === 201) {
          if (!this.showConfirmation) {
            this.showConfirmation = true;
            toast.success(this.$t("new_class.success_message"), {
              autoClose: 3000,
            });
            setTimeout(() => {
              this.showConfirmation = false;
              this.$router.push("/classrooms");
            }, 4000);
          }
        }
      } catch (error) {
        console.error("Erreur lors de la création de la salle de classe :", error);
      } finally {
        this.loading = false;
        this.buttonActive = false;
      }
    },
  },
  beforeMount() {
    this.getData();
  },
};
</script>

<style scoped>
.content {
  margin-left: auto;
  margin-right: auto;
}

.input-error {
  border: 1px solid red;
  border-radius: 10px;
}
</style>
