<template>
    <div class="container page-header mt-5">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="card" style="margin-top: 100px;">
                    <div class="card-header text-center">
                        <h5 style="transform: translateY(30px)">Modifier Votre mot de passe</h5>
                    </div>
                    <div class="card-body text-center">
                        <p> Raptim igitur properantes ut motus sui rumores celeritate nimia
                            praevenirent, vigore corporum ac levitate confisi per
                            flexuosas semitas ad summitates collium tardius evadebant.
                        </p>
                        <div v-if="errorMessage" class="alert alert-danger" role="alert">
                            <p  class="text-danger mt-2">{{ errorMessage }}</p>
                        </div>
                        <form @submit.prevent="changePassword" class="text-start mt-5">
                            <div class="form-group mt-2 position-relative">
                                <label for="newPassword">Ancien mot de passe <em style="color: #f60000">*</em></label>
                                <input :type="passwordFieldType" style="height: 50px;" class="form-control"
                                    id="newPassword" v-model="oldPassword" required>
                                <span class="password-toggle" @click="togglePasswordVisibility">
                                    <i :class="passwordFieldType === 'password' ? 'bi bi-eye' : 'bi bi-eye-slash'"></i>
                                </span>
                            </div>

                            <div class="form-group mt-2 position-relative">
                                <label for="newPassword">Nouveau mot de passe <em style="color: #f60000">*</em></label>
                                <input :type="passwordFieldType" style="height: 50px;" class="form-control"
                                    id="newPassword" v-model="newPassword" required>
                                <span class="password-toggle" @click="togglePasswordVisibility">
                                    <i :class="passwordFieldType === 'password' ? 'bi bi-eye' : 'bi bi-eye-slash'"></i>
                                </span>
                            </div>
                            <div class="form-group mt-3 position-relative">
                                <label for="confirmPassword">Confirmer le nouveau mot de passe <em
                                        style="color: #f60000">*</em></label>
                                <input :type="passwordFieldType" style="height: 50px;" class="form-control"
                                    id="confirmPassword" v-model="confirmPassword" required>
                                <span class="password-toggle" @click="togglePasswordVisibility">
                                    <i style="color:black"
                                        :class="passwordFieldType === 'password' ? 'bi bi-eye' : 'bi bi-eye-slash'"></i>
                                </span>
                            </div>
                        
                            <button style="height: 50px" type="submit"
                                class="btn btn-primary mt-4 w-100">Enregistrer</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Change_Password",
    data() {
        return {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            passwordFieldType: 'password',
            errorMessage: ''
        };
    },
    methods: {
        togglePasswordVisibility() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
        },
        changePassword() {
            this.errorMessage = '';
             
            if (this.newPassword !== this.confirmPassword) {
                this.errorMessage = "Les nouveaux mots de passe ne correspondent pas.";
                return;
            }
            
            alert("Mot de passe modifié avec succès !");
        }
    },

  async created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    document.body.classList.remove("bg-gray-100"); // Corrigé
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    document.body.classList.add("bg-gray-100"); // Corrigé
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
}
</script>

<style scoped>
.container {
    margin-top: 50px;
}

.card {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.card-header {
    background-color: #007bff;
    color: white;
    font-size: 1.5rem;
}

.password-toggle {
    position: absolute;
    top: 52%;
    right: 10px;
    cursor: pointer;
    color: black;
    font-size: 18px;

}

.btn-primary {
    background-color: #25b8ab;
    border-color: #2dcec1;
}

.btn-primary:hover {
    background-color: #17726a;
    border-color: #2dcec1;
}

.text-danger {
    color: #dc3545;
}
</style>