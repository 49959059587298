<template>
    <NetworkStatus v-if="isOffline" />
    <div v-else>
        <div class="main-transaction">


            <!-- La modale pour sélectionner les dates -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="card w-60 mx-auto">
                            <div class="modal-header" style="border-bottom:1px solid rgba(255, 255, 255, 0.4);">
                                <h6 class="modal-title" id="exampleModalLabel">{{ $t("transaction.modal_title") }}</h6>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <form @submit.prevent="applyDateFilter">
                                <div class="modal-body">
                                    <div class="mb-3">
                                        <label for="startDate" class="form-label">{{ $t("transaction.start_date")
                                        }} <em style="color: #f60000">*</em></label>
                                        <input
                                            style="background: transparent; color:white; border: 1px solid rgba(250,250, 250, 0.4);"
                                            type="date" v-model="filterStartDate" class="form-control" id="startDate"
                                            required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="endDate" class="form-label">{{ $t("transaction.end_date") }} <em
                                                style="color: #f60000">*</em></label>
                                        <input
                                            style="background: transparent; color:white; border: 1px solid rgba(250,250, 250, 0.4);"
                                            type="date" v-model="filterEndDate" class="form-control" id="endDate"
                                            required>
                                    </div>
                                </div>
                                <div class="modal-footer" style="border-top:1px solid rgba(255, 255, 255, 0.4);">
                                    <button style="border:1px solid rgba(255, 255, 255, 0.4); color:white;"
                                        type="button" class="btn bg-transparent" data-bs-dismiss="modal"> {{
                                            $t("button.close") }}
                                    </button>
                                    <button data-bs-dismiss="modal" type="submit" class="btn btn-success">{{
                                        $t("transaction.filter") }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container-fluid">
                <div class="d-flex" style="margin-top: 0px;">
                    <div style="margin-top: 30px;">
                        <label for="" style="transform: translateY(-30px)">{{ $t("transfer.filter") }}</label>
                        <div class="dropdown" id="dropdown-status">
                            <button class="btn dropdown-toggle text-light fw-bold ps-0" type="button"
                                data-bs-toggle="dropdown" style="transform: translateY(-30px)">
                                {{ selectedPeriod || $t("transfer.all_periods") }}
                            </button>

                            <ul class="dropdown-menu">
                                <li v-for="period in periods" :key="period.value" @click="handlePeriodSelect(period)"
                                    :class="{ active: selectedPeriod === period.label }">
                                    {{ period.label }}
                                </li>
                                <li data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    {{ $t("transaction.filter_date") }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="mx-4 " style="margin-top: 30px;">
                        <label for="" style="transform: translateY(-30px)"> {{ $t("transaction.operator") }} </label>
                        <div class="dropdown" id="dropdown-status">
                            <button class="btn dropdown-toggle ps-0 text-light fw-bold" type="button"
                                data-bs-toggle="dropdown" style="transform: translateY(-30px)">
                                <input class="bg-transparent text-light border-0 fw-bold ps-2" type="text"
                                    v-model="selectedOperatorDisplay" readonly>
                            </button>
                            <ul class="dropdown-menu">
                                <li v-for="operator in operators" :key="operator.value"
                                    @click="handleOperatorSelect(operator)">
                                    {{ operator.label }}
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div class="" style="margin-top: 30px;">
                        <label for="" style="transform: translateY(-30px)"> {{ $t("transaction.status") }} </label>
                        <div class="dropdown" id="dropdown-status">
                            <button class="btn dropdown-toggle text-light fw-bold ps-0" type="button"
                                data-bs-toggle="dropdown" style="transform: translateY(-30px)">
                                <input class="bg-transparent text-light border-0 fw-bold ps-2" type="text"
                                    v-model="selectedStatusDisplay" readonly>
                            </button>
                            <ul class="dropdown-menu">
                                <li v-for="statusItem in statuses" :key="statusItem.value"
                                    @click="handleStatusSelect(statusItem)">
                                    {{ statusItem.label }}
                                </li>
                            </ul>
                        </div>

                    </div>



                </div>
            </div>

            <!--Card -box -->
            <div class="container-fluid mt-1">
                <div class="row " id="card">
                    <div class="col-md-3">
                        <div class="card1 bg-gradient-success">
                            <div class="top">
                                <span> {{ $t("transaction.successful") }} : {{ transactionSummary.successful.count
                                }}</span>
                            </div>
                            <div class="botoom text-end mt-3">
                                <spinner class="text-center " v-if="loading">
                                    <div>
                                        <span class="mt-4">
                                            <Spinner></Spinner>
                                        </span>
                                    </div>
                                </spinner>

                                <span class="price" v-else-if="transactionSummary"> <span
                                        style="font-size:16px;opacity:0.8;">XAF </span>
                                    {{ Number(transactionSummary.successful.amount).toLocaleString(this.$i18n.locale ===
                                        'fr' ? 'fr-FR' : 'en-US', {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    }) }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="card1 bg-gradient-warning">
                            <div class="top">
                                <span> {{ $t("transaction.running") }}: {{ transactionSummary.running.count }}</span>
                            </div>
                            <div class="botoom text-end mt-3">
                                <spinner class="text-center " v-if="loading">
                                    <div>
                                        <span class="mt-4">
                                            <Spinner></Spinner>
                                        </span>
                                    </div>
                                </spinner>

                                <span class="price" v-else-if="transactionSummary"> <span
                                        style="font-size:16px;opacity:0.8;">XAF </span>
                                    {{ Number(transactionSummary.running.amount).toLocaleString(this.$i18n.locale ===
                                        'fr' ? 'fr-FR' : 'en-US', {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    }) }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="card1 bg-gradient-danger">
                            <div class="top">
                                <span> {{ $t("transaction.failed") }}: {{ transactionSummary.failed.count }}</span>
                            </div>
                            <div class="botoom text-end mt-3">
                                <spinner class="text-center " v-if="loading">
                                    <div>
                                        <span class="mt-4">
                                            <Spinner></Spinner>
                                        </span>
                                    </div>
                                </spinner>

                                <span class="price" v-else-if="transactionSummary"> <span
                                        style="font-size:16px;opacity:0.8;">XAF </span>

                                    {{ Number(transactionSummary.failed.amount).toLocaleString(this.$i18n.locale ===
                                        'fr' ? 'fr-FR' : 'en-US', {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    }) }}
                                </span>
                            </div>
                        </div>
                    </div>


                </div>
            </div>




            <div class="container-fluid">
                <div class="card px-0 mt-5">
                    <div class="card-body px-0">

                        <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
                            <div>
                                <span class="mt-4">
                                    <Spinner></Spinner>
                                </span>
                            </div>
                        </spinner>

                        <div class="table-responsive" v-else-if="filteredTable.length > 0">
                            <table class="table px-0 mt-6 mb-6">
                                <thead>
                                    <tr style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)">
                                        <th class="border-0 text-start px-4">
                                            {{ $t("transaction.operator") }}
                                        </th>
                                        <th class="border-0 text-end pe-6" style="text-decoration: none">
                                            {{ $t("transaction.amount") }} (XAF)
                                        </th>
                                        <th class="border-0 text-end pe-6" style="text-decoration: none">
                                            ID Transaction
                                        </th>
                                        <th class="border-0 text-end pe-6">
                                            ID compte
                                        </th>
                                        <th class="border-0 text-end pe-6">
                                            {{ $t("transaction.date") }}
                                        </th>
                                        <th class="text-end border-0">
                                            {{ $t("transaction.status") }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="list in paginatedfilteredTable" :key="list.id"
                                        style="position: relative; border-bottom: 1px solid rgba(255, 255, 255, 0.1);">
                                        <td scope="row" class="px-4 border-0 text-start">
                                            <span v-if="list.wallet == 'mtn-momo'">
                                                <span style="display:none">{{ list.wallet }} </span>
                                                <img style="width:40px; border-radius:50%; height:40px" class="image"
                                                    src="@/assets/img/mtn1.jpeg" alt="" />
                                            </span>
                                            <span v-if="list.wallet == 'orange-money'">
                                                <img style="width:40px; border-radius:50%; height:40px" class="image"
                                                    src="@/assets/img/orange1.jpeg" alt="" />
                                            </span>
                                            <span v-if="list.wallet == 'bicec'">
                                                <img style="width:40px; border-radius:50%; height:40px" class="image"
                                                    src="@/assets/img/bicec.png" alt="" />
                                            </span>
                                            <span v-if="list.wallet == 'sara'">
                                                <img style="width:40px; border-radius:50%; height:40px" class="image"
                                                    src="@/assets/img/afriland.png" alt="" />
                                            </span>
                                            <span v-if="list.wallet == 'express-union'">
                                                <img style="width:40px; border-radius:50%; height:40px" class="image"
                                                    src="@/assets/img/express.png" alt="" />
                                            </span>
                                            <span v-if="list.wallet == 'me2u'">
                                                <img style="width:40px; border-radius:50%; height:40px" class="image"
                                                    src="@/assets/img/me2u.png" alt="" />
                                            </span>
                                        </td>

                                        <td class="border-0 pe-6 pt-4 text-end">
                                            <span>
                                                {{ Number(list.amount - list.fees).toLocaleString(this.$i18n.locale ===
                                                    'fr' ? 'fr-FR' : 'en-US', {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                }) }}
                                            </span>
                                        </td>

                                        <td class="border-0 text-end pe-6 pt-4">
                                            <span class=""> {{ list.processor_tx_id }}</span>
                                        </td>

                                        <td class="text-end border-0 pt-4 position-relative pe-6">
                                            {{ list.account_id }}
                                        </td>
                                        <td class="ps-0 text-end pt-4 border-0 pe-6 position-relative">
                                            {{ formatDate(list.processed_on) }}
                                        </td>
                                        <td class="text-end border-0 position-relative pe-4 pt-4">
                                            <div v-if="list.status === 'FAILED'" class="text-end" style="width: 130px">
                                                {{ $t("transaction.failed") }}
                                            </div>
                                            <div v-if="list.status === 'APIError'" title="Erreur d'API" class="text-end"
                                                style="width: 130px">
                                                Erreur
                                            </div>
                                            <div v-if="list.status === 'Success'" class="text-end" style="width: 130px">
                                                {{ $t("transaction.successful") }}
                                            </div>
                                            <div v-if="list.status === 'Pending'" class="text-end" style="width: 130px">
                                                {{ $t("transaction.running") }}
                                            </div>
                                            <div v-if="list.status === 'Dropped'" class="text-end" style="width: 130px">
                                                Abandonnées
                                            </div>
                                            <div v-if="list.status === 'SimulatedFail'" class="text-end"
                                                style="width: 130px">
                                                Simulation d'echec
                                            </div>
                                            <div v-if="list.status === 'Declined'" class="text-end"
                                                style="width: 130px">
                                                Refusées
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="w-100 justify-content-center text-center" v-else
                            style="height: auto; padding: 70px 0;">
                            <div>
                                <img style="width:300px" class="image" src="@/assets/img/transaction.png" alt="" />
                                <h5 style="transform: translateX(40px)" class="mt-4">{{ $t("transaction.no_transaction")
                                    }}
                                </h5>
                            </div>
                            <p style="width:max-content" class="mx-auto text-center">
                                {{ $t("transaction.no_transaction_text") }} </p>
                        </div>

                        <div class="">
                            <nav aria-label="..." v-if="filteredTable.length > 0">
                                <ul class="pagination">
                                    <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                        <button class="page-link" @click="prevPage" :disabled="currentPage === 1"
                                            style="background: none; color: white">
                                            <i class="bi bi-chevron-left"></i>
                                        </button>
                                    </li>
                                    <li v-for="page in totalPages" :key="page" class="page-item"
                                        :class="{ active: currentPage === page }">
                                        <button class="page-link fw-bold" style="background: none"
                                            @click="() => setCurrentPage(page)">
                                            {{ page }}
                                        </button>
                                    </li>
                                    <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                        <button class="page-link" @click="nextPage"
                                            :disabled="currentPage === totalPages"
                                            style=" background: none;  color: white;">
                                            <i class="bi bi-chevron-right"></i>
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>








        </div>

    </div>
</template>

<script>
import { getTransactions, xpayconnection } from "@/utils/services";
import Spinner from "../components/Spinner.vue";
import NetworkStatus from "@/views/components/NetworkStatus.vue";





// Fonction pour formater les dates pour la requête des transactions

function getFormattedDate() {
    const today = new Date();

    // Soustraire 2 jours de la date actuelle
    const twoDaysAgo = new Date(today);
    twoDaysAgo.setDate(today.getDate() - 2);

    // Formater la date au format YYYY-MM-DD pour 'starting_on'
    const year = twoDaysAgo.getFullYear();
    const month = String(twoDaysAgo.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
    const day = String(twoDaysAgo.getDate()).padStart(2, '0');

    const starting_on = `${year}-${month}-${day}`;

    // Formater la date pour 'ending_on' (aujourd'hui)
    const endYear = today.getFullYear();
    const endMonth = String(today.getMonth() + 1).padStart(2, '0');
    const endDay = String(today.getDate()).padStart(2, '0');

    const ending_on = `${endYear}-${endMonth}-${endDay}`;

    return { starting_on, ending_on };
}



export default {
    name: "historique-Transaction",
    components: { Spinner, NetworkStatus },
    data() {
        return {
            isOffline: false,
            apiError: false,
            searchName: "",
            searchStatus: "",
            schoolId: '',
            xpay_connection_id: '',
            status: '',
            wallet: '',
            selectedOperator: '',
            selectedStatus: '',
            table: [],
            transactionSummary: {
                total: { count: 0, amount: 0 },
                successful: { count: 0, amount: 0 },
                running: { count: 0, amount: 0 },
                failed: { count: 0, amount: 0 },
            },
            loading: true,
            selectedOperatorDisplay: this.$t("transaction.all_operator"),
            selectedStatusDisplay: this.$t("transaction.all_status"),
            currentPage: 1,
            itemsPerPage: 50,
            maxDate: '',
            minDate: '',
            filterStartDate: '',
            filterEndDate: '',
            selectedPeriod: '',
            periods: [
                { value: 'all', label: this.$t("transfer.all_periods") },
                { value: 'today', label: this.$t("transfer.today") },
                { value: 'yesterday', label: this.$t("transfer.yesterday") },
                { value: 'last7days', label: this.$t("transfer.since_the_seven_days") },
                { value: 'last28days', label: this.$t("transfer.last_28_days") }
            ],
            operators: [
                { value: '', label: this.$t("transaction.all_operator") },
                { value: 'orange-money', label: 'Orange-money' },
                { value: 'mtn-momo', label: 'Mtn-momo' },
                { value: 'bicec', label: 'Bicec' },
                { value: 'sara', label: 'Sara' },
                { value: 'eumm', label: 'Express-union' },
                { value: 'me2u', label: 'Me2u' }
            ],
            statuses: [
                { value: '', label: this.$t("transaction.all_status") },
                { value: 'Success', label: this.$t("transaction.successful") },
                { value: 'Pending', label: this.$t("transaction.running") },
                { value: 'FAILED', label: this.$t("transaction.failed") }
            ],
            status_code: '',
        };
    },
    computed: {
        // Filtrer la table des transactions selon les critères de recherche
        filteredTable() {
            return this.table.filter((transaction) => {
                const matchesName = transaction.wallet.toLowerCase().includes(this.searchName.toLowerCase());
                const matchesStatus = this.searchStatus ? transaction.status === this.searchStatus : true;
                const matchesOperator = this.selectedOperator ? transaction.wallet === this.selectedOperator : true;
                return matchesName && matchesStatus && matchesOperator;
            });
        },

        // Calculer le montant total des transactions filtrées
        totalAmount() {
            return this.filteredTable.reduce(
                (total, item) => total + parseFloat(item.amount || 0),
                0
            );
        },

        // Calculer le nombre total de pages pour la pagination
        totalPages() {
            return Math.ceil(this.filteredTable.length / this.itemsPerPage);
        },

        // Pagination des transactions filtrées
        paginatedfilteredTable() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.filteredTable.slice(startIndex, endIndex);
        },
    },


    methods: {

        formatNumber(number) {
            return Number(number).toLocaleString(
                this.$i18n.locale === 'fr' ? 'fr-FR' : 'en-US',
                {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                }
            );
        },

        async updateOnlineStatus() {
            // Vérifier si le navigateur est en ligne
            if (navigator.onLine) {
                try {
                    // Vérifier si les API sont accessibles
                    await this.checkConnection();
                    this.isOffline = false; // Tout fonctionne, on est en ligne
                } catch (error) {
                    console.error("Error checking connection:", error);
                    this.isOffline = true; // Les API ne répondent pas, on est hors ligne
                }
            } else {
                // Pas de connexion internet détectée
                this.isOffline = true;
            }
        },

        async checkConnection(sf_school_id) {
            try {
                // Essayer les deux appels API
                await Promise.all([
                    xpayconnection(sf_school_id)

                ]);
                this.apiError = false; // Les API répondent correctement
            } catch (error) {
                console.error("API Error:", error);
                this.apiError = true; // Les API ne répondent pas
            }
        },

        async fetchxpayconnection() {

            const sf_school_id = this.schoolId;


            try {
                this.loading = true;
                let response = await xpayconnection(sf_school_id);


                if (response && response.length > 0) {
                    const connection = response[0];
                    this.status_code = connection.status_code;
                    this.xpay_connection_id = connection.id;

                } else {
                    console.error("Invalid response data or empty array:", response);
                }
            } catch (error) {
                console.error("Error during getconnection call:", error);
            } finally {
                this.loading = false;
            }
        },


        handleOperatorSelect(operator) {
            this.wallet = operator.value;
            this.selectedOperatorDisplay = operator.label;
            this.fetchTransactions();
        },

        handleStatusSelect(statusItem) {
            this.status = statusItem.value;
            this.selectedStatusDisplay = statusItem.label;
            this.fetchTransactions();
        },

        handlePeriodSelect(period) {
            this.selectedPeriod = period.label;

            switch (period.value) {
                case 'all':
                    this.filterStartDate = '';
                    this.filterEndDate = '';
                    this.fetchTransactions();
                    break;
                case 'today':
                    this.setFilterToday();
                    break;
                case 'yesterday':
                    this.setFilterYesterday();
                    break;
                case 'last7days':
                    this.setFilterLastSevenDays();
                    break;
                case 'last28days':
                    this.setFilterLastTwentyEightDays();
                    break;
            }
        },


        formatDateToSQL(date) {
            if (!date) return '';
            const selectedDate = new Date(date);
            const year = selectedDate.getFullYear();
            const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
            const day = String(selectedDate.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },


        setFilterToday() {
            const today = new Date();
            this.filterStartDate = this.formatDateToSQL(today);
            this.filterEndDate = this.formatDateToSQL(today);
            this.applyDateFilter();
        },

        setFilterYesterday() {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            this.filterStartDate = this.formatDateToSQL(yesterday);
            this.filterEndDate = this.formatDateToSQL(yesterday);
            this.applyDateFilter();
        },

        setFilterLastSevenDays() {
            const today = new Date();
            const sevenDaysAgo = new Date(today);
            sevenDaysAgo.setDate(today.getDate() - 7);
            this.filterStartDate = this.formatDateToSQL(sevenDaysAgo);
            this.filterEndDate = this.formatDateToSQL(today);
            this.applyDateFilter();
        },

        setFilterLastTwentyEightDays() {
            const today = new Date();
            const twentyEightDaysAgo = new Date(today);
            twentyEightDaysAgo.setDate(today.getDate() - 28);
            this.filterStartDate = this.formatDateToSQL(twentyEightDaysAgo);
            this.filterEndDate = this.formatDateToSQL(today);
            this.applyDateFilter();
        },





        // Utilisation de la fonction pour obtenir la date formatée
        getFormattedDate,

        // Gérer la pagination
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        setCurrentPage(page) {
            this.currentPage = page;
        },

        // Récupérer les transactions
        async fetchTransactions() {
            const xpay_connection_id = this.xpay_connection_id;
            const ending_on = new Date().toISOString().split('T')[0];
            const starting_on = "2020-01-01"
            const wallet = this.wallet;
            const status = this.status;

            this.loading = true;
            try {
                let response = await getTransactions(xpay_connection_id, starting_on, ending_on, wallet, status);
                if (response) {
                    this.table = response.transactions || [];
                    this.transactionSummary = response.summary || {
                        total: { count: 0, amount: 0 },
                        successful: { count: 0, amount: 0 },
                        running: { count: 0, amount: 0 },
                        failed: { count: 0, amount: 0 },
                    };
                } else {
                    console.error("La réponse est vide ou non définie.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des transactions :", error);
                this.failedMessage = "Une erreur s'est produite lors de la récupération des informations.";
            } finally {
                this.loading = false;
            }
        },

        async applyDateFilter() {
            if (!this.filterStartDate || !this.filterEndDate) {
                alert("Veuillez sélectionner les deux dates");
                return;
            }

            const xpay_connection_id = this.xpay_connection_id; // Utiliser la variable réelle
            const wallet = this.wallet;
            const starting_on = this.filterStartDate.trim();
            const ending_on = this.filterEndDate.trim();
            const status = this.status;

            this.loading = true;
            try {
                const response = await getTransactions(xpay_connection_id, starting_on, ending_on, wallet, status);
                if (response) {
                    this.table = response.transactions || [];
                    this.transactionSummary = response.summary || {
                        total: { count: 0, amount: 0 },
                        successful: { count: 0, amount: 0 },
                        running: { count: 0, amount: 0 },
                        failed: { count: 0, amount: 0 },
                    };
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des transferts :", error);
            } finally {
                this.loading = false;
            }
        },

        formatDate(dateString) {
            if (!dateString) return "N/A";
            try {
                const date = new Date(dateString);
                if (isNaN(date)) return "Invalid Date";

                // Get date components
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');

                // Get time components
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');

                // Return formatted date with time in parentheses
                return `${year}-${month}-${day} ${hours}:${minutes}`;
            } catch (error) {
                console.error("Error formatting date:", error);
                return "Invalid Date";
            }
        }

    },
    async mounted() {
        this.schoolId = localStorage.getItem("schoolId");
        await this.updateOnlineStatus();
        // Wait for connection to be fetched first
        await this.fetchxpayconnection();
        // Then fetch transactions with the obtained xpay_connection_id
        await this.fetchTransactions();

        const today = new Date();
        this.maxDate = today.toISOString().split('T')[0];
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(today.getMonth() - 3);
        this.minDate = threeMonthsAgo.toISOString().split('T')[0];

        window.addEventListener('offline', () => {
            this.isOffline = true;
        });
        window.addEventListener('online', () => {
            this.isOffline = false;
        });
        window.addEventListener("online", this.updateOnlineStatus);
        window.addEventListener("offline", this.updateOnlineStatus);
        this.intervalId = setInterval(this.checkInternetConnection, 1000);


        // Charger les données initiales
        if (!this.isOffline) {

            await this.fetchxpayconnection();
        }
    },


    beforeUnmount() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);

        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },


};
</script>

<style>

/* Styles pour rendre le tableau responsive */
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1rem;
}

@media (max-width: 1366px) {
  .table th, .table td {
    white-space: nowrap;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  
  #card .card1 {
    height: auto;
    min-height: 140px;
  }
  
  #card .card1 .price {
    font-size: 20px;
  }
  
  #dropdown-status .btn {
    width: auto !important;
    min-width: 150px;
  }
  
  .pagination {
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .pagination .page-item {
    margin-bottom: 5px;
  }
}

/* Amélioration de la responsivité pour les filtres */
@media (max-width: 1200px) {
  .d-flex {
    flex-wrap: wrap;
  }
  
  .dropdown {
    margin-bottom: 15px;
    margin-right: 10px;
  }
}

#form {
    background: none;
    border: 1px solid rgba(250, 250, 250, 0.6);
    height: 45px;
}

#dropdown2 .btn {
    height: 45px;
    border: 1px solid rgba(250, 250, 250, 0.6);
    width: 200px;

}

#dropdown2 .dropdown-menu li {
    padding: 7px 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    transition: 0.3s;
    color: white;
}

#dropdown2 .dropdown-menu li:hover {
    background: rgba(255, 255, 255, 0.1);
}

#dropdown-status .btn {
    height: 45px;
    border: 1px solid rgba(250, 250, 250, 0.6);
    width: 200px;
    background: none;
}

#dropdown-status .dropdown-menu {
    background-color: #293357;
}

#dropdown-status .dropdown-menu li {
    padding: 7px 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    transition: 0.3s;
    color: white;
    cursor: pointer;
}

#dropdown-status .dropdown-menu li:hover {
    background: rgba(255, 255, 255, 0.1);
}

#card .card1 {
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    color: white;
    border-radius: 10px;
    height: 140px;
}

#card .card1 span {
    font-size: 18px;
}

#card .card1 .price {
    font-size: 25px;
    font-weight: bold;
}

.table tbody tr td {
    padding-top: 13px;
    padding-bottom: 13px;
}

#span-table {
    width: 150px;
}

#option1 {
    background-color: transparent;
    color: rgba(250, 250, 250, 0.6);
    border: 1px solid rgba(250, 250, 250, 0.6);
    height: 45px;

}

#option1[value="Declined"] {
    background-color: transparent;
    color: rgba(250, 250, 250, 0.6);
    border: 1px solid rgba(250, 250, 250, 0.6);
    height: 45px;
}

.pagination {
    transform: translateY(-50px);
    display: flex;
}

.pagination button {
    margin: 0 5px;
    cursor: pointer;
    background-color: #081739;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    margin-left: 20px;
}

.pagination button:hover {
    background-color: #0056b3;
}

.pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

#dropdown-status input {
    border: none;
    background: transparent;
    color: white;
    width: 100%;
    outline: none;
}

#dropdown-status input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}
</style>
