<template>

<NetworkStatus v-if="isOffline" />
  <div v-else>  
  


  <div class="pt-0 pb-4 container-fluid">
    
    <div class="row">

      <spinner class="text-center  mt-8 mb-8" v-if="loading" style="height: 200px">
        <div>
          <span class="mt-4">
            <Spinner></Spinner>
          </span>
        </div>
      </spinner>


      <div class="col-lg-12" v-else-if="!hasAdmin">

        <NoAdminUser />
      </div>
      <div class="col-lg-12" v-else>
         
        <projects-table />
      </div>
    </div>
  </div>
</div>   
</template>

<script>
import ProjectsTable from "@/views/components/ProjectsTable";
import NoAdminUser from "../components/NoAdminUser.vue";
import Spinner from "@/views/components/Spinner.vue";
import { getMembers } from "@/utils/services";
import { ref, onMounted } from "vue";
import NetworkStatus from "@/views/components/NetworkStatus.vue";

export default {
  name: "accounts-page",
  data() {
    return {
      username: "",
      isOffline: false,  
      apiError: false, 
    }

  },


  setup() {
    const members = ref([]);
    const loading = ref(false);

    const fetchMembers = async () => {
      try {
        loading.value = true;
        const result = await getMembers();
        if (!navigator.onLine) {
          this.internet = false; // Pas de connexion Internet
          this.no_internet = true; // Afficher le contenu pour pas de connexion Internet
          alert(
            "Vous n'êtes pas connecté à Internet. Veuillez vérifier votre connexion et réessayer."
          );
          return;
        }

        if (result.status === 200) {
          members.value = result.data;
          localStorage.setItem("members", JSON.stringify(result.data));
      
          this.internet = true;
          this.no_internet = false;
        } else {
          console.error(
            "Erreur lors de la récupération des membres :",
            result.error
          );
        }
      } catch (error) {
        console.error(
          "Erreur inattendue lors de la récupération des membres :",
          error
        );
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchMembers();
    });



    return {
      members,
      loading


    };


  },


  async mounted() {
    // Initialiser l'état de connexion au montage
    await this.updateOnlineStatus();
    this.fetchMembers;

    // Ajouter les event listeners pour les changements de réseau
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    this.intervalId = setInterval(this.checkInternetConnection, 1000);
    

    // Charger les données initiales
    if (!this.isOffline) {
      
     this.fetchMembers;
    }
  },

  
  
 
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },


  computed: {
    hasAdmin() {
      return this.members.some(member => member.is_staff);
    }
  },




  components: {
    ProjectsTable,
    NoAdminUser,
    Spinner,
    NetworkStatus 
  },

  
  methods: {

    

    async updateOnlineStatus() {
    // Vérifier si le navigateur est en ligne
    if (navigator.onLine) {
      try {
        // Vérifier si les API sont accessibles
        await this.checkConnection();
        this.isOffline = false; // Tout fonctionne, on est en ligne
      } catch (error) {
        console.error("Error checking connection:", error);
        this.isOffline = true; // Les API ne répondent pas, on est hors ligne
      }
    } else {
      // Pas de connexion internet détectée
      this.isOffline = true;
    }
  },

  async checkConnection() {
    try {
      // Essayer les deux appels API
      await Promise.all([
      getMembers()
         
      ]);
      this.apiError = false; // Les API répondent correctement
    } catch (error) {
      console.error("API Error:", error);
      this.apiError = true; // Les API ne répondent pas
    }
  },

  },
}
</script>

<style scoped></style>