<template>

  
  <div
    v-show="this.$store.state.layout !== 'landing'"
    class="card card-plain shadow-none"
    id="sidenavCard"
  >
    <div class="p-3 card-body text-center w-100 pt-5">
      
      <h6 v-if="this.$store.state.isRTL" class="mb-0 text-dark up">
        تحتاج مساعدة ؟
      </h6>
      <h6 v-else class="mb-0 text-dark up"> <a href="#"> {{ $t("sidebar.help") }} </a> </h6>
      <p v-if="this.$store.state.isRTL" class="text-xs font-weight-bold">
        يرجى التحقق من مستنداتنا
      </p>
      
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 " style="padding: 0;">
           
        </div>
  </div>

  <div class="row">
    
    <div class="mb-4 col-lg-12 " style="padding: 0;">
          <div
            class="text-sm text-center copyright text-muted"
            :class="this.$store.state.isRTL ? 'text-sm-center' : 'text-sm-center'"
          >
            ©
             {{currentYear}},  
            <a
              href="https://www.ikwen.com/"
              class="font-weight-bold " 
              style="font-size: 13px;"
              target="_blank"
              >ikwen Ltd / Scolarfleet</a
            >
           
          </div>
        </div>

  </div>

   
</template>
<script>
import img from '@/assets/img/logo-ct.png'

export default {
  name: 'sidenav-card',
  props: ['textPrimary', 'textSecondary', 'iconClass'],
  data () {
    return {
      currentYear: new Date().getFullYear(),
      img
    }
  }
}
</script>

 
