<template>
  <NetworkStatus v-if="isOffline" />
  <div v-else>  
<div class="card text-center w-60 mx-auto">
    <!-- Section Photo de profil avec le nouveau composant -->
    <div class="text-center mt-4">
        <ZoomableAvatar :photoUrl="user.photo" />
        <h5 class="mt-3">{{ user.firstname }} {{ user.lastname }}</h5>
        <p class="text-muted">{{ user.username }}</p>
    </div>

    <!-- Section Informations -->
    <div class="info-section w-80 mx-auto mt-4 mb-6">
        <div class="info-grid">
            <div class="info-item">
                <label>{{ $t("profil.username") }}</label>
                <p class="info-value mx-1">{{ user.username }}</p>
                <hr class="mt-2 horizontal" />
            </div>

            <div class="info-item">
                <label>Email</label>
                <p class="info-value mx-1">{{ user.email }}</p>
                <hr class="mt-2 horizontal" />
            </div>

            <div class="info-item">
                <label>{{ $t("profil.first_name") }}</label>
                <p v-if="user.firstname" class="info-value mx-1">{{ user.firstname }}</p>
                <p v-else class="info-value mx-1">---------</p>
                <hr class="mt-2 horizontal" />
            </div>

            <div class="info-item">
                <label>{{ $t("profil.last_name") }}</label>
                <p v-if="user.lastname" class="info-value mx-1">{{ user.lastname }}</p>
                <p v-else class="info-value mx-1">----------</p>
                <hr class="mt-2 horizontal" />
            </div>

            <div class="info-item">
                <label>{{ $t("profil.phone") }}</label>
                <p class="info-value mx-1">{{ user.phone }}</p>
                <hr class="mt-2 horizontal" />
            </div>
        </div>
    </div>
</div>
</div>
</template>

<style scoped>
.info-section {
text-align: left;
padding: 20px;
}
.horizontal {
background-image: linear-gradient(to right,
    rgba(255, 255, 255, 0),
    white,
    rgba(255, 255, 255, 0)) !important;
transform: translateX(-40px);
}

.info-grid {
display: grid;
grid-template-columns: repeat(2, 1fr);
gap: 20px;
}

.info-item {
padding: 10px;
border-radius: 8px;
}

.info-item label {
display: block;
color: rgba(250, 250, 250, 0.6);
font-size: 0.9rem;
margin-bottom: 5px;
}

.info-value {
margin: 0;
font-size: 1rem;
color: white;
padding: 5px 0;
}

.card {
background: rgba(0, 0, 0, 0.2);
}
</style>

<script>
import { getinfoMembers } from "@/utils/services";
import NetworkStatus from "@/views/components/NetworkStatus.vue";
import ZoomableAvatar from "@/views/components/ZoomableAvatar.vue";

export default {
name: "Info-personnelle",
data() {
    return {
        user: {
            username: '',
            lastname: '',
            firstname: '',
            email: '',
            phone: '',
            photo: ''  
        },
        isOffline: false,  
        apiError: false,
    };
},
components: {
    NetworkStatus,
    ZoomableAvatar
}, 

async created() {
    await this.loadUserData();
},

async mounted() {
  // Initialiser l'état de connexion au montage
  await this.updateOnlineStatus();
  await this.loadUserData();

  // Ajouter les event listeners pour les changements de réseau
  window.addEventListener("online", this.updateOnlineStatus);
  window.addEventListener("offline", this.updateOnlineStatus);
  this.intervalId = setInterval(this.checkInternetConnection, 1000);
  

  // Charger les données initiales
  if (!this.isOffline) {
    
    await this.loadUserData();
  }
},

beforeUnmount() {
  window.removeEventListener('online', this.updateOnlineStatus);
  window.removeEventListener('offline', this.updateOnlineStatus);

  if (this.intervalId) {
    clearInterval(this.intervalId);
  }
},

methods: {
  async updateOnlineStatus() {
  // Vérifier si le navigateur est en ligne
  if (navigator.onLine) {
    try {
      // Vérifier si les API sont accessibles
      await this.checkConnection();
      this.isOffline = false; // Tout fonctionne, on est en ligne
    } catch (error) {
      console.error("Error checking connection:", error);
      this.isOffline = true; // Les API ne répondent pas, on est hors ligne
    }
  } else {
    // Pas de connexion internet détectée
    this.isOffline = true;
  }
},

async checkConnection(username) {
  try {
    // Essayer les deux appels API
    await Promise.all([
    getinfoMembers(username)
       
    ]);
    this.apiError = false; // Les API répondent correctement
  } catch (error) {
    console.error("API Error:", error);
    this.apiError = true; // Les API ne répondent pas
  }
},

    async loadUserData() {
        try {
            const username = localStorage.getItem('username');
            if (username) {
                const userData = await getinfoMembers(username);
                if (userData) {
                    this.user = {
                        username:userData.username,
                        lastname:userData.last_name,
                        firstname:userData.first_name,
                        email:userData.email,
                        phone:userData.phone,
                        photo:userData.photo  
                    };
                    
                } else {
                    console.error("Aucune donnée utilisateur reçue");
                }
            } else {
                console.error("Aucun nom d'utilisateur trouvé dans le localStorage");
            }
        } catch (error) {
            console.error("Erreur lors du chargement des informations de l'utilisateur:", error);
        }
    }
}
};
</script>
