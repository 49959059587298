const env = process.env.NODE_ENV;

// Production host  eg: cm.sf-vogt.eskalearning.com
let prod_host_name = window.location.host;
let service_name = "SandDUM";
let apiBaseUrl = "https://cm.sfbe.eskalearning.com/";
if (env !== "production") {
  apiBaseUrl = "https://sandbox.scolarfleet.eskalearning.com";
  // apiBaseUrl = 'http://127.0.0.1:8001'
} else {
  const service = prod_host_name.split(".")[1];
  service_name = service.split("-")[1];
  apiBaseUrl = apiBaseUrl + service_name;
}

const api_config = {
  API_ENDPOINT: apiBaseUrl,
  SERVICE_NAME: service_name,
  TOKEN_ESKA: "5846c84975f0bff1895aafdb20da7315ae0570eb",
  TOKEN_XSCHOOLPAY: "87885139996d2bc9a1fb0d23f0284a8a0515b872",
  TOKEN_ESKA_APP: "000450b4b87d7b65ecec884c771b48ce23d42425",
  TOKEN_SFBE: "09cc6902a4e4426886d637586047c775a21bd2b4",
  CURRENCY_CODE: "XAF",
  SETTLED: "Settled",
  ACCEPTED: "Accepted",
  OVERDUE: "Overdue",
  PENDING: "Pending",
  PAID: "Paid",
  PAYMENT_TYPE:"SchoolFees",
  MIN_TRANSFER_AMOUNT: "MIN_TRANSFER_AMOUNT",
  ALLOW_SPLIT_PAYMENT: "ALLOW_SPLIT_PAYMENT",
  FEES_ON_PAYER: "FEES_ON_PAYER",
  UPDATE_BANK_INFORMATION: "UPDATE_BANK_INFO",
  COUNTRY_CODE:"CM",
  LINK_TO_SHARE:"https://www.eskalearning.com/download?src=sk"
};
export default api_config;