<template>
    <div class="modal-dialog">
        <div class="card">
            <div class="modal-content">
                <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
                    <h6 class="modal-title" style="margin-left: 10%;" id="staticBackdropLabel10">
                        {{ $t("date_sequence.title") }} {{ currentStep }}/{{ totalSteps }}
                    </h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        @click="fermer"></button>
                </div>

                <div class="modal-body text-center pt-4 pb-4">
                    <!-- Step Progress Indicator -->
                    <div class="step-indicator mb-4">
                        <div class="d-flex justify-content-center">
                            <div v-for="step in totalSteps" :key="step"
                                :class="['step-dot mx-2', { active: step === currentStep }]">
                                {{ step }}
                            </div>
                        </div>
                    </div>

                    <div class="container mt-3">
                        <form class="mb-4">
                            <div class="mx-auto" style="width: 84%;">

                                <!-- Step 1: Sequences and Niveaux -->
                                <div v-show="currentStep === 1">
                                    <div class="mb-3 text-start">
                                        <label for="sequences" style="font-size:14px" class="form-label">
                                            {{ $t("date_sequence.label1") }} <em style="color: #f60000">*</em>
                                        </label>

                                        <div class="row mt-3 ps-3">
                                            <div v-for="sequence in sequences" :key="sequence.id"
                                                class="form-check col-3 mt-2">

                                                <input class="form-check-input" type="radio" name="sequence"
                                                    :id="`sequence-${sequence.id}`" :value="sequence.name"
                                                    v-model="selectedSequence"
                                                    :class="{ 'is-invalid': formSubmitted && !selectedSequence }">
                                                <label class="form-check-label" :for="`sequence-${sequence.id}`">
                                                    {{ sequence.name }}
                                                </label>
                                            </div>
                                            <div class="invalid-feedback d-block"
                                                v-if="formSubmitted && !selectedSequence">
                                                {{ $t("date_sequence.error1") }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mb-3 text-start">
                                        <label class="form-label">
                                            {{ $t("date_sequence.label2") }} <em style="color: #f60000">*</em>
                                        </label>

                                        <div class="row mt-3 ps-3">
                                            <div v-for="item in GRADE_CODE" :key="item.code"
                                                class="form-check col-3 mt-2">
                                                <input class="form-check-input" type="checkbox"
                                                    :id="`matiere-${item.code}`" :value="item.name"
                                                    v-model="selectedlevel"
                                                    :class="{ 'is-invalid': formSubmitted && selectedlevel.length === 0 }">
                                                <label class="form-check-label" :for="`matiere-${item.code}`">
                                                    {{ item.name }}
                                                </label>
                                            </div>

                                            <div class="invalid-feedback d-block"
                                                v-if="formSubmitted && selectedlevel.length === 0">
                                                {{ $t("date_sequence.error2") }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Step 2: Matières -->
                                <div v-show="currentStep === 2">
                                    <div class="mb-3 text-start">
                                        <label for="matieres" style="font-size:14px" class="form-label">
                                            {{ $t("date_sequence.label3") }}
                                            <em style="color: #f60000">*</em>
                                        </label>
                                        <div class="row mt-3 ps-3">
                                            <div v-for="matiere in matieres" :key="matiere.id"
                                            class="form-check col-3 mt-2">
                                            <input class="form-check-input" type="checkbox"
                                                :id="`matiere-${matiere.id}`" :value="matiere.name"
                                                v-model="selectedMatieres" required
                                                :class="{ 'is-invalid': formSubmitted && selectedMatieres.length === 0 }">
                                            <label class="form-check-label" :for="`matiere-${matiere.id}`"
                                                :title="matiere.name">
                                                {{ matiere.name.length > 12 ? matiere.name.substring(0, 12) + '...' :
                                                matiere.name }}
                                            </label>
                                        </div>
                                        </div>
                                        <div class="invalid-feedback d-block"
                                            v-if="formSubmitted && selectedMatieres.length === 0">
                                            {{ $t("date_sequence.error3") }}
                                        </div>
                                    </div>
                                </div>

                                <!-- Step 3: Dates -->
                                <div v-show="currentStep === 3">
                                    <div class="row mt-5">
                                        <div class="mb-3 col-6 text-start">
                                            <label for="startDate" class="form-label">
                                                {{ $t("date_sequence.label4") }} <em style="color: #f60000">*</em>
                                            </label>
                                            <input style="height:50px;" type="date" class="form-control" id="input"
                                                v-model="startDate"
                                                :class="{ 'is-invalid': formSubmitted && !startDate }">
                                            <div class="invalid-feedback d-block" v-if="formSubmitted && !startDate">
                                                {{ $t("date_sequence.error4") }}
                                            </div>
                                            <div class="invalid-feedback d-block" v-if="!isDateValid">
                                                {{ $t("temporary_suspension.error") }}
                                            </div>
                                        </div>
                                        <div class="mb-3 col-6 text-start">
                                            <label for="endDate" class="form-label">
                                                {{ $t("date_sequence.label5") }} <em style="color: #f60000">*</em>
                                            </label>
                                            <input style="height:50px;" type="date" class="form-control" id="input"
                                                v-model="endDate" :class="{ 'is-invalid': formSubmitted && !endDate }">
                                            <div class="invalid-feedback d-block" v-if="formSubmitted && !endDate">
                                                {{ $t("date_sequence.error5") }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
                    <argon-button v-if="currentStep > 1" @click="previousStep" type="button" class="btn"
                        style="width: 200px; height: 50px; padding-top: 15px; background: none; border: 1px solid rgba(255, 255, 255, 0.4);">
                        {{ $t("button.previous") }}
                    </argon-button>

                    <argon-button v-if="currentStep < totalSteps" @click="nextStep" type="button" class="btn me-7"
                        color="success" variant="gradient" style="width: 200px; height: 50px; padding-top: 15px"
                        :disabled="!canProceedToNextStep || loading">
                        <span v-if="loading && currentStep === 1">Chargement...</span>
                        <span v-else>{{ $t("button.next") }}</span>
                    </argon-button>

                    <argon-button v-if="currentStep === totalSteps" @click="sendAlert" type="button" class="btn me-7"
                        color="success" variant="gradient" style="width: 200px; height: 50px; padding-top: 15px"
                        :loading="loading" :active="buttonActive || showConfirmation" :clickEvent="button">
                        <span v-if="!loading">{{ $t("button.send") }}</span>
                    </argon-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";
import { getGradeCode, getSubjects, postNoteAvailable, getAlertSubscribers } from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
    name: 'Sequence-Modal',
    components: {
        ArgonButton,
    },
    data() {
        return {
            currentStep: 1,
            totalSteps: 3,
            selectedSequence: '',
            startDate: '',
            endDate: '',
            sequences: [
                { id: 1, name: this.$t('grade_available.seq1') },
                { id: 2, name: this.$t('grade_available.seq2') },
                { id: 3, name: this.$t('grade_available.seq3') },
                { id: 4, name: this.$t('grade_available.seq4') },
                { id: 5, name: this.$t('grade_available.seq5') },
                { id: 6, name: this.$t('grade_available.seq6') }
            ],
            matieres: [],
            selectedMatieres: [],
            selectedlevel: [],
            selectedNiveau: [],
            loading: false,
            classroomId: null,
            showAlert: false,
            grade_code: '',
            grade_name: '',
            GRADE_CODE: [],
            isChoiceMade: false,
            formSubmitted: false,
            buttonActive: false,
            showConfirmation: false,
            registrationNumber: [],
            school_id: '',
        };
    },

    mounted() {
        this.school_id = localStorage.getItem("schoolId");

    },
    computed: {
        isDateValid() {
            if (this.start_date && this.end_date) {
                return new Date(this.start_date) < new Date(this.end_date);
            }
            return true;
        }
    },


    methods: {
        fermer() {
            this.selectedSequence = '';
            this.selectedMatieres = [];
            this.selectedlevel = [];
            this.startDate = '';
            this.endDate = '';
            this.currentStep = 1;
            this.formSubmitted = false;
        },
        async getData() {
            this.loading = true;
            let grades = await getGradeCode();
            this.GRADE_CODE = grades.data;
            this.grade_code = grades.data[0].name;
            this.grade_name = grades.data[0].name;
            this.loading = false;
        },




        async fetchSubjects() {
            const country_code = "CM";
            const education_grade = localStorage.getItem("education_grade");
            const training_type = localStorage.getItem("training_type");
            const education_system =
                localStorage.getItem("education_system") === "Bilingual"
                    ? ""
                    : localStorage.getItem("education_system");

            if (!education_grade || !training_type) {
                this.error = "Certains paramètres sont manquants.";
                return;
            }

            try {
                const subjects = await getSubjects(
                    country_code,
                    education_grade,
                    training_type,
                    education_system
                );

                // Use Map to keep track of unique names
                const uniqueSubjects = Array.from(
                    new Map(subjects.map(item => [item.name, item])).values()
                );

                // Transform the filtered results
                this.matieres = uniqueSubjects.map((subject) => ({
                    id: subject.id,
                    name: subject.name,
                }));

               
            } catch (err) {
                console.error("Erreur lors de la récupération des sujets :", err);
                this.error = "Erreur lors de la récupération des sujets.";
            }
        },



        

        async nextStep() {
            this.formSubmitted = true;
            if (this.currentStep === 1) {
                if (this.selectedlevel.length === 0 || this.selectedSequence === '') {
                    return;
                }
                this.loading = true;
                try {
                    const response = await getAlertSubscribers(this.selectedlevel.join(','), '', 'ESKAALERT');
                    if (response.status === 200) {
                        this.registrationNumber = response.data;
                        this.currentStep = 2;
                    } else {
                        toast.error("Erreur lors de la récupération des matricules des élèves", {
                            autoClose: 3000,
                        });
                    }
                } catch (error) {
                    toast.error("Erreur lors de la récupération des matricules des élèves", {
                        autoClose: 3000,
                    });
                } finally {
                    this.loading = false;
                }
            } else if (this.currentStep === 2) {
                if (this.selectedMatieres.length === 0) {
                    return;
                }
                this.currentStep = 3;
            }

            this.formSubmitted = false;
        },
        previousStep() {
            if (this.currentStep === 3) {
                this.currentStep = 2;
            } else if (this.currentStep === 2) {
                this.currentStep = 1;
            }
        },
        async sendAlert() {
            this.formSubmitted = true;
            if (this.loading) {
                return
            }

            if (!this.startDate || !this.endDate) {
                return;
            }

            this.loading = true;

            try {
                const data = {
                    school_id: this.school_id,
                    code: 4102,
                    registration_number_list: this.registrationNumber,
                    sequence: this.selectedSequence,
                    subject_list: this.selectedMatieres,
                    start_date: this.startDate,
                    end_date: this.endDate
                };

                const response = await postNoteAvailable(data);

                if (response.status === 200) {
                    const message = this.$t("date_sequence.success_message");
                    toast.success(message, {
                        autoClose: 3000,
                    });

                    this.fermer();
                }
            } catch (error) {
                toast.error("Erreur lors de l'envoi de la notification", {
                    autoClose: 3000,
                });
            } finally {
                this.loading = false;
            }
        }
    },
    beforeMount() {
        this.getData();
        this.fetchSubjects();
    },
};
</script>

<style>
.step-indicator .step-dot {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.step-indicator .step-dot.active {
    background: #5e72e4;
}

#pending,
#settled {
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    border: 1px solid green;
}

#alert-modal {
    border: 1px solid #83454d;
    color: #83454d;
    background: rgba(0, 0, 0, 0.6)
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.is-invalid {
    border-color: #dc3545 !important;
    border-width: 1px;
}
</style>
