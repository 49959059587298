<template>
  <NetworkStatus v-if="isOffline" />
  <div v-else>  
    <div class="dic" v-if="loading">
      <Spinner></Spinner>
    </div>
    <div
      v-else-if="has_class"
      class="py-4 container-fluid"
      style="  margin-bottom: 200px"
    >
      <div v-for="item in schoolList" :key="item.name">
        <div class="row" v-if="item['classrooms'].length">
          <div class="col-lg-12">
            <div class="row ">
              <h6>{{ item.name }} </h6>
              <div
                v-for="classroom in item['classrooms']"
                :key="classroom.id"
                class="col-lg-3 col-md-6 col-12 mb-3 "
                
              >
                <ClassesCard2
                  :classroom_id="classroom.id"
                  :grade="item.name + ' ' + classroom.number"
                  :speciality_code="
                    classroom.speciality_code !== 'Pas de spécialité'
                      ? classroom.speciality_code
                      : ''
                  "
                  :student_count="classroom.student_count"
                  :pending_amount="classroom.pending_amount"
                  :pending_payment="0"
                  :iconClass="iconClass"
                  :iconBackground="iconBackground"
                  detail=""
                  directionReverse
                ></ClassesCard2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row " v-else>
      <div class="container">
        <div class="card">
          <div class="card-body" style="width: 70%">
            <div class="text">
              <i class="bi bi-exclamation-triangle-fill fs-1 text-warning"></i>
              <h5>{{ $t("classroom.alert_list_classroom") }}</h5>
              <p class="mb-4">
                {{ $t("classroom.p_list_classroom") }}
              </p>
  
              <router-link
                :to="{ name: 'new-class' }"
                class="btn text-center px-3 mb-2 bg-gradient-success"
                style="width: 200px"
              >
                <span class="fa fa-plus-circle m-2"></span
                >{{ $t("classroom.button_new_class") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import ClassesCard2 from "@/widget/Cards/ClassesCard2";
  import { getGradeCode } from "@/utils/services";
  import Spinner from "../components/Spinner.vue";
  import NetworkStatus from "@/views/components/NetworkStatus.vue";
  
  export default {
    name: "classes-list-card2",
    data() {
      return {
        iconBackground: "bg-gradient-primary",
        iconClass: "fa fa-ellipsis-vertical",
        schoolList: [],
        verify: null,
        has_class: false,
        loading: false,
        isOffline: false,  
        apiError: false, 
      };
    },
    components: {
      ClassesCard2,
      Spinner,
      NetworkStatus
    },
    

    async mounted() {
    // Initialiser l'état de connexion au montage
    await this.updateOnlineStatus();
    this.created();

    // Ajouter les event listeners pour les changements de réseau
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    this.intervalId = setInterval(this.checkInternetConnection, 1000);
    

    // Charger les données initiales
    if (!this.isOffline) {
      
      await this.created();
    }
  },

  

 
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },

    methods:{
      async updateOnlineStatus() {
    // Vérifier si le navigateur est en ligne
    if (navigator.onLine) {
      try {
        // Vérifier si les API sont accessibles
        await this.checkConnection();
        this.isOffline = false; // Tout fonctionne, on est en ligne
      } catch (error) {
        console.error("Error checking connection:", error);
        this.isOffline = true; // Les API ne répondent pas, on est hors ligne
      }
    } else {
      // Pas de connexion internet détectée
      this.isOffline = true;
    }
  },

  async checkConnection() {
    try {
      // Essayer les deux appels API
      await Promise.all([
      getGradeCode()
         
      ]);
      this.apiError = false; // Les API répondent correctement
    } catch (error) {
      console.error("API Error:", error);
      this.apiError = true; // Les API ne répondent pas
    }
  },

  async created() {
      try {
        this.loading = true;
  
        let classrooms = await getGradeCode();
        this.schoolList = classrooms.data;
        this.schoolList.forEach((classroom) => {
          if (classroom["classrooms"].length) {
            this.has_class = true;
            return;
          }
        });
  
        return this.schoolList;
      } catch (err) {
        // response.status = err.response.status
        console.error("Failed to get Grades with classrooms", err);
      } finally {
        this.loading = false; // Désactivez le spinner une fois la requête terminée
      }
    },

    }
  };
  </script>
  
  <style scoped>
  .card {
    width: 80%;
    text-align: center;
  }
  .card .card-body {
    margin-left: auto;
    margin-right: auto;
  }
  </style>
  