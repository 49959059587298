import {createRouter, createWebHistory} from 'vue-router'
import { canAccessNotes } from "@/utils/noteAccess"; 
import { canAccessMessages } from "@/utils/messageAccess"; 
 


import Dashboard from '../views/Dashboard.vue'
import VirtualReality from '../views/VirtualReality.vue'
import RTL from '../views/Rtl.vue'
import Profile from '../views/Student/StudentProfile.vue'
import Signup from '../views/Signup.vue'
import Signin from '../views/Signin.vue'
import Classes from '@/views/Classroom/Classes'
import ClassesDetails from '@/views/Classroom/ClassesDetails'
import StudentDetails from '@/views/Student/StudentDetails'
import DetailConfigVue from '@/views/Classroom/DetailConfig.vue'
import PaymentConfig from '@/views/PaymentConfig'
import NewClass from "@/views/Classroom/NewClass";
import Notfound from "@/views/Notfound";
import {getAuthToken} from "@/utils/auth";
import AppConfig from "@/views/AppConfig";
import Register from "@/views/Accesscontrol/Register";
import Accounts from "@/views/Accesscontrol/Accounts";
import NewAccount from "../views/Accesscontrol/NewAccount.vue";
import EditAccount from "../views/Accesscontrol/EditAccount.vue"
import Eska from "../views/Eska/Eska.vue"
import StartMessage from "../views/Eska/StartMessage.vue"
import ShoolProfil from "../views/Eska/Profil.vue"
import Note from "../views/Eska/Note.vue"
import Message from "../views/Eska/Message.vue"
import ListMessage from "../views/Eska/ListMessage.vue"
import Sponsoring from "../views/Eska/Sponsoring.vue"
import ImportStudent from "@/views/Student/ImportStudent";
import StudentForm from "@/views/Student/StudentForm";
import ListPayment from '@/views/ListPayment.vue'
import ConfigForm from '@/views/Classroom/ConfigForm.vue'
import formInstallment from "../views/Classroom/FormInstallment.vue"
import ConfigUpdate from "../views/Classroom/ConfigUpdate.vue"
import Logout from "../views/Logout.vue"
import Settledstudents from '@/views/Classroom/Settledstudents.vue'
import ClassesStudentssettle from '@/views/Classroom/ClassesStudentssettled.vue'
import ClassesStudentspending from '@/views/Classroom/ClassesStudentsPending.vue'
import ClassesStudentOverdue from '@/views/Classroom/ClassesStudentsOverdue.vue'
import UpdateClass from '@/views/Classroom/UpdateClass'
import Classroomreceipts from '@/views/Classroom/ClassroomReceipt'
import Pendingstudents from '@/views/Classroom/PendingList.vue'
import Overduestudents from '@/views/Classroom/OverdueList.vue'
import Otherpayment from '@/views/OtherPayment.vue'
import Notification from '@/views/Notification.vue'
import Ouroffers from '@/views/Eska/Offers.vue'
import ListTeachers from '@/views/Eska/ListTeachers.vue'
import HistoriqueTransaction from '@/views/Transactions.vue'
import Transfer from '@/views/Transfer.vue'
import OtherPaymentGovfess from '@/views/OtherPaymentGovfees.vue'
import OnlyPaymentForm from "../views/Otherpayment/OnlyPaymentForm.vue"
import UpdateOnlyPayment from "../views/Otherpayment/UpdateOnlyPayment.vue"
import RecurentPaymentForm  from "../views/Otherpayment/RecuentPaymentForm.vue"
import UpdateRecurentPayment  from "../views/Otherpayment/UpdateCurentPayment.vue"
import ChangePassword from '@/views/ChangePassword.vue';
import InformationsPpersonnelles from '@/views/Accesscontrol/Infoperso.vue'
import Synchronisationteacher from '@/views/PageSynchronisation.vue'

const routes = [
  {
    path: "/",
    name: "welcome",
    component: Dashboard,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  {
    path: "/dashboard-default",
    name: "dashboard-default",
    component: Dashboard,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
   

  {
    path: "/transaction",
    name: "historique-transaction",
    component:HistoriqueTransaction,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/transfers",
    name: "transfer",
    component:Transfer,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/account-information",
    name: "informations-personnelles",
    component:InformationsPpersonnelles,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },


  {
    path: "/other-payment-govfess",
    name: "other-payment-govfess",
    component:OtherPaymentGovfess,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/teachers-syncing-request",
    name: "/synchronisation-teacher",
    component:Synchronisationteacher,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/grades",
    name: "notes",
    component:Note,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
      requiresNoteAccess:false
    },
  },
  {
    path: "/shool-profil",
    name: "shool-profil",
    component:ShoolProfil,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  

  {
    path: "/other-payment-new",
    name: "only-payment-form",
    component: OnlyPaymentForm,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  {
    path: "/update-only-payment/:id",
    name: "update-only-payment",
    component: UpdateOnlyPayment,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  {
    path: "/start-message",
    name: "start-message",
    component: StartMessage,
    meta: {
      requiresAuth: true,
      
    },
  },
  {
    path: "/messaging",
    name: "message",
    component: Message,
    meta: {
      requiresAuth: true,
      requiresMessageAccess:false  
    },
  },
  {
    path: "/list-message",
    name: "list-message",
    component: ListMessage,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  {
    path: "/recurent-payment-form",
    name: "recurent-payment-form",
    component: RecurentPaymentForm,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/update-recurent-payment/:id",
    name: "update-recurent-payment",
    component: UpdateRecurentPayment,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/sendnote",
    name: "sendnote",
    component: Sponsoring,
    meta: {
      requiresAuth: false, // Add meta field to indicate protected route
    },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notification,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/teachers-list",
    name: "teachers-list",
    component: ListTeachers,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/list-payment",
    name: "list-payment",
    component: ListPayment,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/eska",
    name: "eska",
    component: Eska,
    meta: {
      requiresAuth: true, // Add meta field to indicate class-detailsprotected route
    },
  },
  {
    path: "/our-offers",
    name: "offers",
    component: Ouroffers,
    meta: {
      requiresAuth: true, // Add meta field to indicate class-detailsprotected route
    },
  },
  {
    path: "/classrooms",
    name: "classrooms",
    component: Classes,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/update-class/:classId",
    name: "update-class",
    component: UpdateClass,
    props: true,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/other-payment",
    name: "other-payment",
    component: Otherpayment,
    props: true,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  

  {
    path: "/classes-students-payment-settled",
    name: "classes-students-payment-settled",
    component: ClassesStudentssettle,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  {
    path: "/classes-students-payment-pending",
    name: "classes-students-payment-pending",
    component: ClassesStudentspending,

    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/classes-students-payment-overdue",
    name: "classes-students-payment-overdue",
    component: ClassesStudentOverdue,

    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  {
    path: "/create-payment-config/",
    name: "create-payment-config",
    component: ConfigForm,
    meta: {
      requiresAuth: true, // Ajoutez un champ meta pour indiquer une route protégée
    },
    
  },

  {
    path: "/create-installment/:configId",
    name: "create-installment",
    component: formInstallment,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  {
    path: "/new-classroom",
    name: "new-class",
    component: NewClass,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  {
    path: "/classroom/:classId",
    name: "class-details",
    component: ClassesDetails,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/settledstudents/:classId",
    name: "settled-students",
    component: Settledstudents,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  

  {
    path: "/pendingstudents/:classId",
    name: "pending-students",
    component: Pendingstudents,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/overduestudents/:classId",
    name: "overdue-students",
    component: Overduestudents,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/classroomreceipts/:classId",
    name: "classroom-receipts",
    component: Classroomreceipts,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  {
    path: "/student-detail/:classroom_id/:id", 
    name: "student-details",
    component: StudentDetails,
    meta: {
      requiresAuth: true,
    },
  },
  
  {
    path: "/config/:id",
    name: "detail-config",
    component: DetailConfigVue,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/update-config/:id",
    name: "update-config",
    component: ConfigUpdate,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  {
    path: "/import-students/:classroomId",
    name: "import-students",
    component: ImportStudent,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  {
    path: "/new-student/:classroomId",
    name: "new-student",
    component: StudentForm,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
   
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/payment-config",
    name: "payment-config",
    component: PaymentConfig,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  {
    path: "/profile",
    name: "student-profile",
    component: Profile,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  {
    path: "/accounts",
    name: "accounts",
    component: Accounts,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/edit-account",
    name: "edit-accounts",
    component: EditAccount,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/new-account",
    name: "new-account",
    component: NewAccount,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },

  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },

  {
    path: "/change-password",
    name: "change-password",
    component: ChangePassword,
  },

  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  
  {
    path: "/Settings",
    name: "Parem",
    component: AppConfig,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
  },
  {
    path: "/:notFound",
    name: "not-found",
    component: Notfound,
  },

  
  {
    path: "/logout",
    name: "logout",
    component:Logout,
    meta: {
      requiresAuth: true, // Add meta field to indicate protected route
    },
     
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active'
});


router.beforeEach((to, from, next) => {
    const token = getAuthToken();
    if (to.meta.requiresAuth) {
        if (token) {
          if (to.meta.requiresNoteAccess) {
            if (canAccessNotes.get()) {
                next();
            } else {
                next({ name: 'sendnote' });
            }
        }else if (to.meta.requiresMessageAccess) {
          if (canAccessMessages.get()) {
              next();
          } else {
              next({ name: 'start-message' });
          }
        }
        
        else {
            next();
        }
            next();
        } else {
            // User is not authenticated, redirect to login
            next('/signin');
        }
    } else {
        // Non-protected route, allow access
        next();
    }
});

export default router
