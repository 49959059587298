<template>
  <div
      @dragenter.prevent="toggleActive"
      @dragleave.prevent="toggleActive"
      @dragover.prevent
      @drop.prevent="toggleActive"
      :class="{'active-dropzone': active}"
      class="drop-zone">
    <span>{{ $t("transfer.dropzone_p1") }} </span>
    <span>{{ $t("transfer.dropzone_p2") }} </span>
    <label for="dropzoneFile" class=" text-center">
      <span class=" mx-auto">{{ $t("button.select_file") }} </span>
    </label>
    <input type="file" id="dropzoneFile" class="dropzoneFile text-center">
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "drop-zone",
  setup(){
    const active = ref(false);

    const toggleActive = () => {
      active.value = !active.value;
    }

    return { active, toggleActive }
  }
}
</script>

<style scoped lang="scss">
  .drop-zone{
    width: 100%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 16px;
    border: 2px dashed #41b883;
    background-color: #1a2035;
    transition: 0.3s ease all;

    label{
       
      padding-top: 14px;
      background-color: #41b883;
      transition: .3s ease all;
      cursor: pointer;
      border-radius: 10px;
      height: 50px;
      width: 250px;
      font-size: 15px;
    }
    input{
      display: none;
    }
  }
  .active-dropzone{
    color: #ffffff;
    border-color: #ffffff;
    background-color: #41b883;

    label{
      background-color: #41b883;
    }
  }

</style>