<template>
    <NetworkStatus v-if="isOffline" />
    <div v-else>

        <div class="py-4 container">
            <!-- Modal -->
            <div class="modal fade" id="exampleModal33" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="card">
                            <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
                                <h5 class="modal-title" id="exampleModalLabel"></h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body text-center pt-4 pb-4">
                                <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
                                <h6>{{ $t("single_payment.modal_modify_title") }}</h6>
                                <p class="text-justify px-6">
                                    {{ $t("single_payment.modal_modify_text") }}
                                </p>
                            </div>
                            <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
                                <argon-button type="button" class="btn me-4" color="danger" variant="gradient"
                                    data-bs-dismiss="modal" style="width: 150px; height: 50px; padding-top: 15px">
                                    {{ $t("button.close") }}
                                </argon-button>
                                <argon-button :loading="loading" :active="buttonActive || showConfirmation"
                                    type="button" class="btn me-4" color="success" variant="gradient"
                                    @click="confirmUpdate" style="width: 150px; height: 50px; padding-top: 15px"
                                    :clickEvent="button" :disabled="loading">
                                    {{ $t("button.confirm") }}
                                </argon-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>






            <div class="row mt-3 mb-4 text-center">
                <h5 class="mb-5">{{ $t("single_payment.page_modify") }}</h5>
                <div class="row mt-3">
                    <div class="col-md-6 col-xs-6 col-lg-7 content text-start">
                        <div class="p-3 card w-">
                            <div class="card-header pb-0"></div>
                            <div class="card-body px-0 pt-0 pb-2">
                                <div class="mb-4">
                                    <label> {{ $t("single_payment.description") }} <em
                                            style="color: #f60000">*</em></label>
                                    <argon-input placeholder="Désignation..." class="p-1"
                                        v-model="payment.description"></argon-input>
                                </div>

                                <div class="mb-4">
                                    <label> {{ $t("single_payment.amount") }}(XAF) <em
                                            style="color: #f60000">*</em></label>
                                    <argon-input type="number" placeholder="Montant..." class="p-1"
                                        v-model="payment.amount"></argon-input>
                                </div>

                                <div class="mb-4">
                                    <label> {{ $t("single_payment.due_date") }} <em
                                            style="color: #f60000">*</em></label>
                                    <argon-input class="p-1" v-model="payment.due_date" type="date"></argon-input>
                                </div>


                                <div class="mt-5">
                                    <div class="row">

                                        <div class="col-md-6">
                                            <argon-button :loading="loading" :active="buttonActive || showConfirmation"
                                                fullWidth color="success" variant="gradient" class="my-4 mb-2 pt-3"
                                                data-bs-toggle="modal" data-bs-target="#exampleModal33"
                                                style="height:50px;">
                                                {{ $t("button.save") }}
                                            </argon-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";
import ArgonInput from "@/components/ArgonInput";
import { updateotherpayment, getPayment } from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import NetworkStatus from "@/views/components/NetworkStatus.vue";
export default {
    name: "Only_Payment_Form",
    components: { ArgonButton, ArgonInput, NetworkStatus },
    data() {
        return {
            payment: {
                is_on_off: true,
                decription: "",
                amount: "",
                due_date: "",
                invoices: []
            },
            loading: false,
            buttonActive: false,
            showConfirmation: false,
            isOffline: false,
            apiError: false,
        };
    },

    async mounted() {
        // Initialiser l'état de connexion au montage
        await this.updateOnlineStatus();
        await this.fetchPayment();

        // Ajouter les event listeners pour les changements de réseau
        window.addEventListener("online", this.updateOnlineStatus);
        window.addEventListener("offline", this.updateOnlineStatus);
        this.intervalId = setInterval(this.checkInternetConnection, 1000);


        // Charger les données initiales
        if (!this.isOffline) {

            await this.fetchPayment();
        }
    },




    beforeUnmount() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);

        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },
    methods: {
        async updateOnlineStatus() {
            // Vérifier si le navigateur est en ligne
            if (navigator.onLine) {
                try {
                    // Vérifier si les API sont accessibles
                    await this.checkConnection();
                    this.isOffline = false; // Tout fonctionne, on est en ligne
                } catch (error) {
                    console.error("Error checking connection:", error);
                    this.isOffline = true; // Les API ne répondent pas, on est hors ligne
                }
            } else {
                // Pas de connexion internet détectée
                this.isOffline = true;
            }
        },

        async checkConnection(otherconfigId) {
            try {
                // Essayer les deux appels API
                await Promise.all([
                    getPayment(otherconfigId)

                ]);
                this.apiError = false; // Les API répondent correctement
            } catch (error) {
                console.error("API Error:", error);
                this.apiError = true; // Les API ne répondent pas
            }
        },

        async fetchPayment() {
            try {
                const otherconfigId = this.$route.params.id;
                const response = await getPayment(otherconfigId);

                if (response.status === 200) {
                    this.payment = response.data;
                    this.payment.due_date = this.payment.due_date ? new Date(this.payment.due_date).toISOString().split('T')[0] : "";
                } else {
                    this.handleError(response);
                }
            } catch (error) {
                this.handleError(error.response);
            }
        },

        async confirmUpdate() {
            this.loading = true;
            try {
                const otherconfigId = this.$route.params.id;

                this.payment.due_date = this.payment.due_date ? new Date(this.payment.due_date).toISOString().split('T')[0] : ""
                const data = {
                    is_on_off: this.payment.is_on_off,
                    description: this.payment.description,
                    amount: this.payment.amount,
                    due_date: this.payment.due_date,
                    invoices: this.payment.invoices
                };

                const response = await updateotherpayment(otherconfigId, data);
                if (response.status === 200) {

                    if (!this.showConfirmation) {
                        this.showConfirmation = true;
                        const message = this.$t("single_payment.message_modify_success");
                        toast.success(message, {
                            autoClose: 3000,
                        });

                        document.body.classList.remove("modal-open");
                        const modalBackdrops =
                            document.getElementsByClassName("modal-backdrop");
                        for (const backdrop of modalBackdrops) {
                            backdrop.parentNode.removeChild(backdrop);
                        }

                        setTimeout(() => {
                            this.showConfirmation = false;
                            this.$router.push({
                                path: "/other-payment",
                                query: { forceReload: true },
                            });
                        }, 4000);
                    }


                } else {

                    const message = this.$t("single_payment.message_modify_error");
                    toast.error(message, {
                        autoClose: 3000,
                    });
                }
            } finally {
                this.loading = false;
            }
        }
    },
    created() {
        this.fetchPayment(); // Fetch payment data when component is created
    }
}
</script>

<style scoped>
.content {
    margin-left: auto;
    margin-right: auto;
}
</style>
